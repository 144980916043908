import React from "react";
import AnimatedCursor from "react-animated-cursor";
import "./WhoV3.scss";
import Whatsapp from "../../components/whatsapp/whatsapp";
import Img1 from "../../assets/WhoV3/about.png";
import Img2 from "../../assets/WhoV3/nosotros.png";
import Img3 from "../../assets/WhoV3/Licence.png";
import Img4 from "../../assets/WhoV3/disclaimer.png";
import CheckIcon from "../../assets/icons/checkIcon";
import gsap from "gsap";
import Transicion from "components/Transicion/Transicion";
import { AnimationScroll, FooterTemplate } from "../../Containers";
import { LanguageContext } from "Context";
import Register from "./components/components/register/register";
import EnLang from "./lang/En";
import EsLang from "./lang/Es";
import ResponsiveWho from "./ResponsiveWho";
import ReactGA from "react-ga";
import Collapse from "./Accordion/Collapse";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { HeaderHelmet } from "components/HeaderHelmet/HeaderHelmet";

ReactGA.initialize("UA-236552941-1");
const options = [
  { src: Img2, type: "image" },
  { src: Img1, type: "image" },

  /* { src: animationData, type: "lottie" }, */
];

const WhoV3 = () => {
  const about = gsap.timeline();
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const aboutUs = [
    {
      title: langChoosed.section10?.text3,
      content: langChoosed.section10?.text4,
    },
    {
      title: langChoosed.section10?.text5,
      content: langChoosed.section10?.text6,
    },
    {
      title: langChoosed.section10?.text7,
      content: langChoosed.section10?.text8,
    },

    {
      title: langChoosed.section10?.text9,
      content: langChoosed.section10?.text10,
    },
  ];
  const aboutUsTwo = [
    {
      title: langChoosed.section10?.text11,
      content: langChoosed.section10?.text12,
    },
    {
      title: langChoosed.section10?.text13,
      content: langChoosed.section10?.text14,
    },
    {
      title: langChoosed.section10?.text15,
      content: langChoosed.section10?.text16,
    },
  ];

  window.scrollTo(0,0);
  return (
    <>
      <HeaderHelmet
        title="Wao payments | About us"
        description="Join the new generation of financial services"
        type="webapp"
        name="Wao payments | About us"
      />
      <ResponsiveWho />
      <div className="who-container">
        <Transicion timeline={about} />
        <AnimationScroll resource={options} className="dark-blue">
          <AnimationScroll.Row alignY="center">
            <div className="default-title dark">
              {langChoosed.section1?.text1}
            </div>

            <div className="default-subTitle dark">
              {langChoosed.section1?.text2}
            </div>
          </AnimationScroll.Row>
          <AnimationScroll.Row alignY="center">
            <div className="default-title dark">
              {langChoosed.section2?.text1}
              <span> {langChoosed.section2?.text2}</span>
            </div>
            <div className="default-paragraph dark">
              {langChoosed.section2?.text3}
            </div>
            <div className="default-paragraph dark">
              {langChoosed.section2?.text4}
            </div>
          </AnimationScroll.Row>
        </AnimationScroll>

        <div className="div-licencia">
          <div className="div-text-licencia">
            <p className="title"> {langChoosed.section8?.text1}</p>
            <p className="text-licencia">
              {langChoosed.section8?.text2} <br />
              <br />
              {langChoosed.section8?.text3}
            </p>
            <Link className="link" to="/license">
              {langChoosed.section8?.text4}
            </Link>
          </div>
          <div className="div-img-licencia">
            <img src={Img3} alt="Licencia-wao" />
          </div>
        </div>

        <div className="div-disclaimer">
          <div className="div-text-disclaimer">
            <p className="title-disclaimer"> {langChoosed.section9?.text1} </p>
            <p className="text-disclaimer">{langChoosed.section9?.text2} </p>

            <div className="item-disclaimer">
              <div>
                <CheckIcon />
              </div>
              <div> {langChoosed.section9?.text3}</div>
            </div>

            <div className="item-disclaimer">
              <div>
                <CheckIcon />
              </div>
              <div> {langChoosed.section9?.text4}</div>
            </div>
            <div className="item-disclaimer">
              <div>
                <CheckIcon />
              </div>
              <div> {langChoosed.section9?.text5}</div>
            </div>
            <div className="item-disclaimer">
              <div>
                <CheckIcon />
              </div>
              <div> {langChoosed.section9?.text6}</div>
            </div>

            <Link className="link" to="/disclaimer">
              {langChoosed.section9?.text7}
            </Link>
          </div>
          <div className="div-img-disclaimer">
            <img src={Img4} alt="Disclaimer-wao" />
          </div>
        </div>

        <div className="div-terms">
          <div className="div-text-terms">
            <div className="title">
              <p className="title-term-one">{langChoosed.section10?.text1}</p>
              <p className="title-term-two"> {langChoosed.section10?.text2}</p>
            </div>
            <div className="accordion">
              {aboutUs.map((item, index) => {
                return (
                  <Collapse key={index} title={item.title} collapsed>
                    <p>{item.content}</p>
                  </Collapse>
                );
              })}{" "}
            </div>
          </div>

          <div className="div-terms-two">
            <div className="terms-two">
              {aboutUsTwo.map((i, index) => {
                return (
                  <Collapse key={index} title={i.title} collapsed>
                    <p>{i.content}</p>
                  </Collapse>
                );
              })}
            </div>
          </div>
        </div>
        <Whatsapp text={langChoosed.whatsapp?.text1} />
        <CookieConsent
          buttonText="Accept "
          style={{ background: "#204694" }}
          buttonStyle={{ fontSize: "16px", color: "#121a2c" }}
        >
          {langChoosed.copy?.text1}
          <Link onClick={(event) => (window.location.href = "/cookie")}>
            {langChoosed.copy?.text2}
          </Link>
        </CookieConsent>
        <FooterTemplate style={{ minHeight: "700px" }}>
          <div className="sectionYellow">
            <div className="default-title dark" style={{ fontSize: 40 }}>
              <span>{langChoosed.section3?.text1}</span>
            </div>
          </div>
        </FooterTemplate>

        <Register></Register>
        <AnimatedCursor
          innerSize={16}
          outerSize={12}
          color="255, 198, 41"
          outerAlpha={0.5}
          innerScale={0.9}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      </div>
    </>
  );
};

export default WhoV3;
