const En = {
    Text: {
        text1: "Complete form",
        text2: "Thank you for starting the process of joining WaoBiz, your opinion is very important to us and for this reason we would like to know your impressions in this phase: "
    },
    Preguntas: {
        pregunta1: "Your company name",
        pregunta2: "By what means did you find out about WaoBiz?",
        pregunta3: "How would you rate the WaoBiz digital account application process on our Platform?",
        pregunta4: "Would you recommend to your friends, acquaintances and relatives to start the registration process on our Platform to obtain a WaoBiz digital account?",
        pregunta5: "Have you requested information or support in any of our communication channels?",
        pregunta6: "How would you rate the management of our communication channels?",
        pregunta7: "Do you want to give us a suggestion?"
    },
    Opciones: {
      opcion:"Select an option",
      opcion1P2: "Advertising",
      opcion2P2: "Instagram",
      opcion3P2: "Facebook",
      opcion4P2: "Friend recommendation",
      opcion5P2: "Email",
      opcion6P2: "Search for information on the internet",
      opcion1P3: "Simple",
      opcion2P3: "Flexible",
      opcion3P3: "Complex",
      opcion4P3: "I have had a problem",
      opcion5P3: "Specify which inconvenience",
      opcion1P4: "Yes",
      opcion2P4: "Not",
      opcion1P5: "WhatsApp",
      opcion2P5: "Social Media",
      opcion1P6: "Excellent",
      opcion2P6: "Good",
      opcion3P6: "It can improve",
      
      
    },
    Button: {
        button1: "Send survey"
    },
    Error: {
        error1: "The field must not be empty",
        error2: "You must select an option",
        error3: "Must contain a maximum of 255 letters or characters",


    }, 
    Message: {
        success: "Thank you so much for taking the time to complete our survey! 😃 ",
    } 

}

export default En;