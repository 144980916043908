import React from 'react'
import MetaTags from "react-meta-tags";

const Meta = () => {
  return (
    <div className="wrapper">
    <MetaTags>
    <meta name="facebook-domain-verification" content="kflxmefutyihsst4pmkldyz62a1n3q" />
    </MetaTags>
  </div>
  )
}

export default Meta