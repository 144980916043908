import React  from "react";
import PropTypes from "prop-types";
import Es from "../lang/Es.js";
import En from "../lang/En.js";
import Text from "../../../../components/Text/Text";
import { LanguageContext } from "Context";
const InputText = (props) => {
    const [lang] = React.useContext(LanguageContext);
    const [langChoosed, setLangChoosed] = React.useState({});
    React.useEffect(() => {
        if (lang === "En") {
          setLangChoosed(En);
        } else {
          setLangChoosed(Es);
        }
      }, [lang]);
  return (
    <>
      <label htmlFor="" className="label-input">
        {props.label}
      </label>
      <input
        name={props.name}
        id={props.id}
        onChange={props.onChange}
        type={props.type}
        className={`input-encuesta ${props.error ? "error-encuesta" : ""}`}
        required
      />
    <div className="error-encuesta-msg">
        {props.error && <Text category="error">{props.error}</Text>}
      </div>
    </>
  );
};

InputText.propTypes = {};

export default InputText;
