import React from "react";
import AnimatedCursor from "react-animated-cursor";
import CookieConsent from "react-cookie-consent";
import "./homev3.scss";
import Img1 from "../../assets/Homev3/seccion1Home.webp";
import Img2 from "../../assets/Homev3/seccion2Home.png";
import Img3 from "../../assets/Homev3/seccion3Home.png";
import Img4 from "../../assets/Homev3/seccion4Home.webp";
import Img5 from "../../assets/Homev3/seccion5Home.png";
import CheckIcon from "../../assets/icons/checkIcon";
import { ButtonBubble } from "../../components";
import gsap from "gsap";
import Transicion from "components/Transicion/Transicion";
import { AnimationScroll, FooterTemplate } from "../../Containers";
import ResponsiveHome from "./ResponsiveHome";
import { LanguageContext, UnavailableModalContext } from "Context";
import WhatCanYouDo from "pages/Benefits/WhatCanYouDo/WhatCanYouDo";

import { Link, animateScroll as scroll } from "react-scroll";
import Whatsapp from "components/whatsapp/whatsapp";
import EnLang from "./lang/En";
import EsLang from "./lang/Es";
import axios from "axios";
import ReactGA from "react-ga";
import { useHistory, withRouter } from "react-router-dom";
import UserIcon from "assets/icons/userIcon";
import DolarIcon from "assets/icons/dolarIcon";

import { HeaderHelmet } from "components/HeaderHelmet/HeaderHelmet";

ReactGA.initialize("UA-236552941-1");

const options = [
  { src: Img1, type: "image", loading:"lazy" },
  { src: Img2, type: "image", loading:"lazy" },
  { src: Img3, type: "image", loading:"lazy" },
  { src: Img4, type: "image", loading:"lazy" },
  { src: Img5, type: "image", loading:"lazy" },
];

const Homev3 = () => {
  const history = useHistory();
  const about = gsap.timeline();
  const [lang] = React.useContext(LanguageContext);

  const [uModalVisibility, setUModalVisibility] = React.useContext(
    UnavailableModalContext
  );
  const [langChoosed, setLangChoosed] = React.useState({});

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  //REDIRECCION CON MODALES
  const handleRedirectWithModal = (redirect) => {
    setUModalVisibility(true);
    history.push(redirect);
  };
  //REDIRECCION SIN MODALES

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        localStorage.setItem("pais", JSON.stringify(data.country_name));
        localStorage.setItem("ip", JSON.stringify(data.ip));

        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getGeoInfo();
  window.scrollTo(0,0);
  return (
    <>
      <HeaderHelmet
        title="Wao payments | World wide financial platform "
        description="Wao offers you a digital account in dollars under the laws and regulations of the United States"
        type="webapp"
        name="Wao payments | World wide financial platform "
      />
      <ResponsiveHome />
      <div className="home-container">
        <Transicion timeline={about} />
        <AnimationScroll resource={options}>
          <AnimationScroll.Row alignY="center">
            <div className="default-title">{langChoosed.section1?.text1}</div>
            <div className="default-subTitle">
              {langChoosed.section1?.text2}
            </div>
            <div className="default-list">
              <div className="item">
                <div className="image">
                  <DolarIcon />
                </div>
                <div className="text">{langChoosed.section1?.text3}</div>
              </div>
              <div className="item">
                <div className="image">
                  <UserIcon />
                </div>
                <div className="text">{langChoosed.section1?.text4}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section1?.text5}</div>
              </div>
            </div>
            <div className="buttons-home">
              <ButtonBubble
                className={" small"}
                onClick={() => handleRedirectWithModal("/register-business")}
              >
                {langChoosed.section1?.text8}
              </ButtonBubble>
              <ButtonBubble
                className={"light-business small"}
                onClick={() => handleRedirectWithModal("/register-person")}
              >
                <a className="text-business" href="/register-person">
                  {langChoosed.section1?.text7}
                </a>
              </ButtonBubble>
            </div>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="default-title">{langChoosed.section2?.text1}</div>
            <div className="default-subTitle ">
              {langChoosed.section2?.text2}
            </div>

            <div className="default-paragraph">
              {langChoosed.section2?.text3}
            </div>
            <div className="default-paragraph">
              {langChoosed.section2?.text4}
            </div>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="default-title">{langChoosed.section3?.text1}</div>
            <div className="default-subTitle ">
              {langChoosed.section3?.text2}
            </div>

            <div className="default-paragraph">
              {langChoosed.section3?.text3}
            </div>
            <div className="default-paragraph">
              {langChoosed.section3?.text4}
            </div>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="default-title">{langChoosed.section4?.text1}</div>
            <div className="default-subTitle ">
              {langChoosed.section4?.text2}
            </div>
            <div className="default-paragraph">
              {langChoosed.section4?.text3}
            </div>
            <div className="default-paragraph" style={{ marginBottom: 0 }}>
              {langChoosed.section4?.text4}
            </div>
            <div className="default-list">
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section4?.text5}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section4?.text6}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section4?.text7}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section4?.text8}</div>
              </div>
            </div>
            <div className="default-span">{langChoosed.section4?.text9}</div>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="default-title">{langChoosed.section5?.text1}</div>
            <div className="default-subTitle ">
              {langChoosed.section5?.text2}
            </div>
            <div className="default-paragraph">
              {langChoosed.section5?.text3}
            </div>
          </AnimationScroll.Row>
        </AnimationScroll>
        <Whatsapp text={langChoosed.whatsapp?.text1} />
        <FooterTemplate className="HomeForm">
          <WhatCanYouDo />
        </FooterTemplate>
        <FooterTemplate className="business fooTemp__container">
          <div className="foo__content">
            <div className="title-register">
              <div className="free">
                <span>{langChoosed.section8?.text2}</span>
              </div>
              <div className="register">
                <span>{langChoosed.section8?.text1}</span>
              </div>
            </div>
            <div className="parrafo-register">
              <div className="text-register">
                {langChoosed.section8?.text3 + langChoosed.section8?.text4}
              </div>
            </div>
            <div className="button-register">
              <ButtonBubble
                className={"light-register"}
                onClick={() => history.push("/register-business")}
              >
                <a className="text-register" href="/register-business">
                  {langChoosed.section8?.text5}
                </a>
              </ButtonBubble>
            </div>
          </div>
        </FooterTemplate>
        <CookieConsent
          buttonText="Accept "
          style={{ background: "#204694" }}
          buttonStyle={{ fontSize: "16px", color: "#121a2c" }}
        >
          {langChoosed.copy?.text1}
          <Link onClick={(event) => (window.location.href = "/cookie")}>
            {langChoosed.copy?.text2}
          </Link>
        </CookieConsent>

        <AnimatedCursor
          innerSize={16}
          outerSize={12}
          color="255, 198, 41"
          outerAlpha={0.5}
          innerScale={0.9}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      </div>
    </>
  );
};

export default withRouter(Homev3);

