import React from "react";
import { Footer2 } from "components";
import "../legal.scss";
import Privacy3 from "../../../assets/legal/disclaimer.webp";
import { LanguageContext } from "Context";
import EnLang from "../Lang/En";
import EsLang from "../Lang/Es";
import ReactGA from "react-ga";

ReactGA.initialize("UA-236552941-1");
const License = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  window.scrollTo(0,0);

  return (
    <>
      <div className="content-legal">
      <img src={Privacy3} className ="img-header" alt="privacy">
    </img>
          <div className="text-inimage">Legal Aspects</div>
          <div className="text-inimage2">{langChoosed?.disclaimer?.text2}</div>

   
        <p className="content-legal22" >
          <text>The purpose of the <strong> USA PATRIOT Act </strong>is to deter and
          punish terrorist acts in the United States and around the world, to
          enhance law enforcement investigatory tools, and other purposes, some
          of which include:</text>
        </p>

        <br></br>

        <ul className="content-legal22" >
          <li >
            <text className="content-legal23">To strengthen U.S. measures to prevent, detect and prosecute
            international money laundering and financing of terrorism;</text>
          </li>
          <br></br>
          <li >
          <text className="content-legal23">To subject to special scrutiny foreign jurisdictions, foreign
            financial institutions, and classes of international transactions or
            types of accounts that are susceptible to criminal abuse;</text>
          </li>
          <br></br>
          <li >
          <text className="content-legal23">To require all appropriate elements of the financial services
            industry to report potential money laundering;</text>
          </li>
          <br></br>
          <li >
          <text className="content-legal23">To strengthen measures to prevent use of the U.S. financial system
            for personal gain by corrupt foreign officials and facilitate
            repatriation of stolen assets to the citizens of countries to whom
            such assets belong.</text>
          </li>
        </ul>

        <br></br>
        <br></br>
        <p  className="content-legal22">
          To learn more, please visit{" "}
          <a
            href="https://www.justice.gov/archive/ll/highlights.htm"
            target="blank"
          >
            {" "}
            USA PATRIOT Act
          </a>{" "}
          website.
        </p>
        <br></br>
        <br></br>
        <div className="content-legal22">
        <strong>OTHER LEGAL DOCUMENTS</strong>
        </div >
        <a  className="content-legal22" href="/terms">Terms & Conditions</a>
      </div>

      <Footer2 position={"relative"} />
    </>
  );
};

export default License;
