import React from "react";
import "./WhatCanYouDo.scss";
import ach_blue from "../../../assets/images/WhatCanYouDo/Ach_logo.svg";
import zelle_blue from "../../../assets/images/WhatCanYouDo/Zelle_logo.svg";
import wire_blue from "../../../assets/images/WhatCanYouDo/Wire_logo.svg";
import master_blue from "../../../assets/images/WhatCanYouDo/Mastercard_logo.svg";
import wao from "../../../assets/images/WhatCanYouDo/Wao_logo.svg";
import visa_blue from "../../../assets/images/WhatCanYouDo/Visa_logo.svg";
import american_blue from "../../../assets/images/WhatCanYouDo/American_logo.svg";
import discovery_blue from "../../../assets/images/WhatCanYouDo/Disc_logo.svg";

import { Text } from "../../../components";
import CardLogo from "components/CardLogo/CardLogo";
import { LanguageContext } from "Context";

import EnLang from "../lang/En";
import EsLang from "../lang/Es";
import ReactGA from "react-ga";

ReactGA.initialize("UA-236552941-1");

const WhatCanYouDo = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="WhatCanYouDo">
      <h1 className="title">
        {" "}
        <Text  color="yellow"></Text>
        {langChoosed.section9?.text1}{" "}
      </h1>
      <div className="subtitle">
        <Text category="h2">
          {langChoosed.section9?.text2}
          {langChoosed.section9?.text3}
        </Text>
      </div>

      <div className="pasarelas">
        <CardLogo image={ach_blue} imageHover={ach_blue} />
        <CardLogo image={zelle_blue} imageHover={zelle_blue} />
        <CardLogo image={wire_blue} imageHover={wire_blue} />
        <CardLogo image={wao} imageHover={wao} />
      </div>
      <div className="pasarelas last">
        <CardLogo image={visa_blue} imageHover={visa_blue} />
        <CardLogo image={master_blue} imageHover={master_blue} />
        <CardLogo image={american_blue} imageHover={american_blue} />
        <CardLogo image={discovery_blue} imageHover={discovery_blue} />
      </div>
    </div>
  );
};

export default WhatCanYouDo;
