import React from "react";
import "./CardLogo.scss";

const CardLogo = ({ image }) => {
  return (
    <div className={"card__logo_container"}>
      <img className={"card__logo_img"} src={image} alt="" />
    </div>
  );
};

export default CardLogo;
