import React from "react";
import AnimatedCursor from "react-animated-cursor";
import "./People.scss";

import imagen1 from "../../assets/images/Products/section1People.webp";
import Imagen2 from "../../assets/images/Products/section2People.webp";
import Imagen3 from "../../assets/images/Products/section3People.webp";
import Imagen4 from "../../assets/images/Products/section4People.webp";
import Imagen5 from "../../assets/images/Products/section5People.webp";
import { ButtonBubble } from "../../components";

import waoLogoPeople from "../../assets/images/waoLogoPeople.svg";
import waoLogoApp from "../../assets/images/waoLogoApp.svg";
import waoLogoCard from "../../assets/images/waoLogoCard.svg";
import waoLogoLink from "../../assets/images/waoLogoLink.svg";
import CheckIcon from "../../assets/icons/checkIcon";

import LinkVideo from "../../assets/images/Products/link-video-person.svg";

import { LanguageContext, UnavailableModalContext } from "Context";
import gsap from "gsap";
import Transicion from "components/Transicion/Transicion";
import { Text, ButtonVideo } from "../../components";
import { AnimationScroll, FooterTemplate } from "../../Containers";

import Img7 from "../../assets/Homev3/HOME-7a.webp";
import Icon7a from "../../assets/icons/7a.svg";
import Icon7b from "../../assets/icons/7b.svg";
import Icon7c from "../../assets/icons/7c.svg";
import Icon7d from "../../assets/icons/7d.svg";

import EnLang from "./lang/En";
import EsLang from "./lang/Es";
import { Link, useHistory } from "react-router-dom";
import ResponsivePeople from "./ResponsivePeople";

import ReactGA from "react-ga";
import Whatsapp from "components/whatsapp/whatsapp";
import CookieConsent from "react-cookie-consent";
import { HeaderHelmet } from "components/HeaderHelmet/HeaderHelmet";

ReactGA.initialize("UA-236552941-1");

const options = [
  { src: imagen1, type: "image" },
  { src: Imagen2, type: "image" },
  { src: Imagen3, type: "image" },
  { src: Imagen4, type: "image" },
  { src: Imagen5, type: "image" },
];

const People = () => {
  const [, setUModalVisibility] = React.useContext(UnavailableModalContext);
  const about = gsap.timeline();
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  const history = useHistory();
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const handleRedirect = () => {
    setUModalVisibility(true);
    history.push("/register-person");
  };
  console.log("langChoosed", lang);
  window.scrollTo(0,0);
  return (
    <>
      <HeaderHelmet
        title="Wao personal payments | You can send or receive money from anywhere in the world"
        description="Wao offers you a digital account in dollars under the laws and regulations of the United States"
        type="webapp"
        name="Wao personal payments | You can send or receive money from anywhere in the world"
      />
      <ResponsivePeople />
      <div className="people-container">
        <Transicion timeline={about} />
        <AnimationScroll resource={options} className="bg__white">
          <AnimationScroll.Row alignY="center">
            <div className="default-title">{langChoosed.section1?.text1}</div>
            <div className="default-subTitle">
              {langChoosed.section1?.text2}
            </div>
            <div className="default-paragraph">
              {langChoosed.section1?.text3}
            </div>
            <div className="default-span">{langChoosed.section1?.text4}</div>
            <ButtonBubble className="small" onClick={handleRedirect}>
              {langChoosed.section1?.text5}
            </ButtonBubble>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="title-logo">
              <img src={waoLogoPeople} alt="waoLogoPeople" />
            </div>
            {/* <RenderText Texts={langChoosed.section2} /> */}
            <div className="default-paragraph">
              {langChoosed.section2?.text1}
            </div>
            <div className="default-paragraph">
              {langChoosed.section2?.text2}
            </div>
            <div className="video-link">
              <ButtonVideo
                link={
                  lang === "En"
                    ? "https://youtu.be/QgsNmdNjoBY"
                    : "https://youtu.be/i1J2v_DhlZY"
                }
                color="light-gray"
                img={LinkVideo}
              >
                {langChoosed.section2?.video}
              </ButtonVideo>
            </div>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="title-logo">
              <img src={waoLogoApp} alt="waoLogoApp" />
            </div>
            {/* <RenderText Texts={langChoosed.section3} /> */}
            <div className="default-paragraph">
              {langChoosed.section3?.text1}
            </div>
            <div className="default-list">
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section3?.text2}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section3?.text3}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section3?.text4}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section3?.text5}</div>
              </div>
            </div>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="title-logo">
              <img src={waoLogoCard} alt="waoLogoCard" />
            </div>
            {/* <RenderText Texts={langChoosed.section4} /> */}
            <div className="default-paragraph">
              {langChoosed.section4?.text1}
            </div>
            <div className="default-paragraph">
              {langChoosed.section4?.text2}
            </div>

            <div className="default-span">{langChoosed.section4?.text3}</div>
          </AnimationScroll.Row>

          <AnimationScroll.Row alignY="center">
            <div className="title-logo">
              <img src={waoLogoLink} alt="waoLogoLink" />
            </div>
            {/* <RenderText Texts={langChoosed.section5} /> */}
            <div className="default-paragraph">
              {langChoosed.section5?.text1}
            </div>
            <div className="default-paragraph">
              {langChoosed.section5?.text2}
            </div>
            <div className="default-paragraph">
              {langChoosed.section5?.text3}
            </div>
            <div className="default-span">{langChoosed.section5?.text4}</div>
          </AnimationScroll.Row>
        </AnimationScroll>

        <FooterTemplate>
          <div className="foo__content">
            <Text className="title_1" color="#ffc629">
              {langChoosed.section7?.text1}
            </Text>

            <Text className="title_obj">{langChoosed.section7?.text2}</Text>
            <div className="goal_Global2">
              <div className="goal__item ">
                <img className="goal__item_icon" src={Icon7a} alt="" />
                <Text color="white">{langChoosed.section7?.text3}</Text>
              </div>

              <div className="goal__item ">
                <img className="goal__item_icon" src={Icon7b} alt="" />
                <Text color="white">{langChoosed.section7?.text4}</Text>
              </div>

              <div className="goal__item ">
                <img className="goal__item_icon" src={Icon7c} alt="" />
                <Text color="white">{langChoosed.section7?.text5}</Text>
              </div>

              <div className="goal__item ">
                <img className="goal__item_icon" src={Icon7d} alt="" />
                <Text color="white"> {langChoosed.section7?.text6}</Text>
              </div>
            </div>
          </div>
          <div className={"images"}>
            <img src={Img7} alt="home" className={"image-home"} />
          </div>
        </FooterTemplate>
        <Whatsapp text={langChoosed.whatsapp?.text1} />
        <CookieConsent
          buttonText="Accept "
          style={{ background: "#204694" }}
          buttonStyle={{ fontSize: "16px", color: "#121a2c" }}
        >
          {langChoosed.copy?.text1}
          <Link onClick={(event) => (window.location.href = "/cookie")}>
            {langChoosed.copy?.text2}
          </Link>
        </CookieConsent>
        <AnimatedCursor
          innerSize={16}
          outerSize={12}
          color="255, 198, 41"
          outerAlpha={0.5}
          innerScale={0.9}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      </div>
    </>
  );
};

export default People;
