/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./Faq.scss";
import Accordion from "./Accordion/Accordion";
import { FooterTemplate, Section } from "Containers";
import { ButtonBubble, Text } from "../../components";
import AnimatedCursor from "react-animated-cursor";
import { Wao, WaoOne, WaoBiz, WaoCard, WaoSecurity } from "./svgs";
import { LanguageContext } from "Context";
import EN_FAQS from "./lang/EN_FAQS";
import ES_FAQS from "./lang/ES_FAQS";
import SvgOptText from "./SvgOpt/SvgOptText";
import ReactGA from "react-ga";
import Whatsapp from "components/whatsapp/whatsapp";
import EnLang from "../Business/lang/En";
import EsLang from "../Business/lang/Es";
import CookieConsent from "react-cookie-consent";
import { Link, useHistory } from "react-router-dom";
import { HeaderHelmet } from "components/HeaderHelmet/HeaderHelmet";

ReactGA.initialize("UA-236552941-1");
const Faq = () => {
  let history = useHistory();
  const [lang] = React.useContext(LanguageContext);
  const [faqsSelected, setFaqsSelected] = useState(EN_FAQS);
  const [tabActive, setTabActive] = useState(0);
  const [langChoosed, setLangChoosed] = React.useState({});

  React.useEffect(() => {
    if (lang === "Es") {
      setFaqsSelected(ES_FAQS);
    } else {
      setFaqsSelected(EN_FAQS);
    }
  }, [lang]);

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const renderFaqs = () => {
    return Object.keys(faqsSelected).map((res, key) => {
      if (tabActive === key) return <Accordion items={faqsSelected[res]} />;
      return null;
    });
  };
  const TABS = [<Wao />, <WaoOne />, <WaoBiz />, <WaoCard />, <WaoSecurity />];
  const renderFaqsTabs = () => {
    return TABS.map((res, key) => {
      return (
        <div
          className={`item-tab-accordion ${tabActive === key ? "active" : ""}`}
          onClick={() => {
            setTabActive(key);
          }}
        >
          <SvgOptText active={tabActive === key}>{res}</SvgOptText>
        </div>
      );
    });
  };

  return (
    <>
      <HeaderHelmet
        title="Wao payments | Frecuently Ask Questions"
        description="Find the right answers about any product you want"
        type="webapp"
        name="Wao payments | Frecuently Ask Questions"
      />
      <div className="Head">
        <Text className="title-question">
          {" "}
          {lang === "Es" ? "Preguntas Frecuentes" : "Frecuently Ask Questions"}
        </Text>
        <Text className="subtitle-question" align="left">
          {lang === "Es"
            ? "Encuentre las respuestas correctas sobre cualquier producto que desee"
            : "Find the right answers about any product you want"}
        </Text>
      </div>
      <div className="wall">
        <div className="top">{renderFaqsTabs()}</div>
        {/* arreglar el hr de modo que quede en el centro de la pantalla */}
        <Section>{renderFaqs()}</Section>
      </div>
      <FooterTemplate className="business fooTemp__container">
        <div className="foo__content">
          <div className="title-register">
            <div className="free">
              <span>{langChoosed.section8?.text2}</span>
            </div>
            <div className="register">
              <span>{langChoosed.section8?.text1}</span>
            </div>
          </div>
          <div className="parrafo-register">
            <div className="text-register">
              {langChoosed.section8?.text3 + langChoosed.section8?.text4}
            </div>
          </div>
          <div className="button-register">
            <ButtonBubble
              className={"light-business small"}
              onClick={() => history.push("/register-business")}
            >
              <a className="text-register" href="/register-business">
                {langChoosed.section8?.text5}
              </a>
            </ButtonBubble>
          </div>
        </div>
      </FooterTemplate>

      <Whatsapp text={langChoosed.whatsapp?.text1} />
      <CookieConsent
        buttonText="Accept "
        style={{ background: "#204694" }}
        buttonStyle={{ fontSize: "16px", color: "#121a2c" }}
      >
        {langChoosed.copy?.text1}
        <Link onClick={(event) => (window.location.href = "/cookie")}>
          {langChoosed.copy?.text2}
        </Link>
      </CookieConsent>
      <AnimatedCursor
        innerSize={16}
        outerSize={12}
        color="255, 198, 41"
        outerAlpha={0.5}
        innerScale={0.9}
        outerScale={5}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
    </>
  );
};

export default Faq;
