const En = {
  section1: {
    text1: "Available to any",
    text2: "Wao user",
  },

  section2: {
    text1:
      "Manage any Wao Account through a debit card that offers you the freedom in purchases in the store or online worldwide.",
    text2:
      "Request a physical or virtual card as soon as you open your account",
  },

  section3: {
    text1:
      "You are a business who doesn’t want to lose a Non-present card transaction or just a person in a hurry who needs money immediately.",
    text2:
      "Any Wao user can receive money in seconds with the Link service. Send a payment link directly to your customers, relatives, or friends in the way you prefer: SMS, email, WhatsApp, and get paid now.",
  },

  section4: {
    text1:
      "Manage your Wao Account through a mobile application that offers you day-to-day convenience.",
    text2:
      "Check your products balances and transactions, send, and receive money, view online statements, and more.",
  },

  section5: {
    text1:
      "Designed to control your WaoBiz Account through a web banking platform that offers you day-to-day convenience.",
    text2:
      "Our real-time administration site allows your company to manage in a unified way all the aspects of the financial service and payments process of your business.",
  },

  section7: {
    text1: "Security",
    text2:
      "We understand the value of data security. That is why so many hours were invested in infrastructure security processes in close collaboration with the world's best security experts.",
    text3:
      "With this background, we guarantee the infrastructure's security to receive, decrypt, and transmit data in strict compliance with the applicable regulations.",
    text4:"& reliability"
    },

  section8: {
    text1: "Compliance",
    text2:
      "The prevention against money laundering and terrorist financing is fundamental in Wao. Therefore, we have robust KYC, AML, and 24|7 active transactional monitoring controls, aimed at guaranteeing maximum compliance with international regulations through a powerful Underwrite module.",
    text3:
      "But don't worry... all this without compromising the ease and mobility of your transactions",
  },

  section9: {
    text1: " What can you do with Wao?",
    text2:
      "Mobilize your money wherever and whenever you want fast and secure. Receive money ",
    text3: " from any credit or debit card directly to your Wao account.",
  },
};

export default En;
