import React from "react";
import { Footer2 } from "components";
import "../legal.scss";
import Privacy3 from "../../../assets/legal/licenses.webp";
import { LanguageContext } from "Context";
import EnLang from "../Lang/En";
import EsLang from "../Lang/Es";
import ReactGA from "react-ga";

ReactGA.initialize("UA-236552941-1");
const License = () => {

  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  window.scrollTo(0,0);
  return (
    <>
      <div className="content-legal">
      <img src={Privacy3} className ="img-header" alt="licence">
    </img>
          <div className="text-inimage">Legal Aspects</div>
          <div className="text-inimage2">License</div>

        <strong className="content-legal22">Dear Wao users:</strong>
        <br></br>
        <br></br>
        <div className="content-legal22">
          Services 24-7 LLC is a Payment Service Provider registered with the
          U.S. Department of Treasury (Fincen) as a MSB (Money Service Business)
          and Prepaid Access provider. We are authorized to transmit money in
          the U.S. states and territories where we have been issued a license or
          through the licenses of our partners/providers in the other states.
        </div>
        
        <br></br>

        <p className="content-legal22">
          <strong>MSB</strong>
          <br></br>
          MSBs are regulated by FinCEN (Financial Crimes Enforcement Agency
          which is part of the U.S. Treasury Department). The main objects are
          the supervision of businesses and companies that provide money
          services. The scope includes international remittance, foreign
          currency exchange, currency transaction/transfer (including digital
          currency/virtual currency), providing prepaid items, issuing
          traveler's cheque and other services. Companies engaged in the above
          related businesses must register before they can operate legally.
        </p>
        <br></br>
        <strong className="content-legal22">Registrant Search</strong>
        <a className="content-legal22" href="https://www.fincen.gov/msb-registrant-search" target="blank">
       
            
        <text className="content-legal23">MSB search</text>
        </a>
        <br></br>
        <br></br>

        <p className="content-legal22">Enter Services 24-7 LLC at LEGAL NAME to search</p>
      </div>

      <Footer2  />
    </>
  );
};

export default License;
