import React from "react";
import "./section.scss";

const Section = (props) => {
  return (
    <React.Fragment>
      <div
        className={`section  col container ${props?.full ? "full" : ""} ${
          props?.className && props?.className
        }`}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default Section;
