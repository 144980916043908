import React from "react";
import AnimatedCursor from "react-animated-cursor";
import "./Business.scss";

import Imagen1 from "../../assets/images/business/Wao-Empresas1.webp";

import Imagen3 from "../../assets/images/business/WaoOnline.webp";
import Imagen4 from "../../assets/images/business/seccion4Empresas.webp";
import Imagen5 from "../../assets/images/business/seccion5Empresas.webp";
import Imagen6 from "../../assets/images/business/seccion6Empresas.webp";

import check from "../../assets/icons/business/fact_check.png";
import line from "../../assets/icons/business/linea.png";
import been from "../../assets/icons/business/beenhere.png";
import stars from "../../assets/icons/business/stars.png";
import CheckIcon from "../../assets/icons/checkIcon";

import waoLogoApp from "../../assets/images/waoLogoOnline.svg";
import waoLogoCard from "../../assets/images/waoLogoCard.svg";
import waoLogoLink from "../../assets/images/waoLogoLink.svg";

import { LanguageContext } from "Context";
import gsap from "gsap";
import Transicion from "components/Transicion/Transicion";
import { ButtonBubble } from "../../components";
import { FooterTemplate } from "../../Containers";

import EnLang from "./lang/En";
import EsLang from "./lang/Es";
import ResponseBusiness from "./ResponsiveBusiness";
import { Link, useHistory } from "react-router-dom";
import { UnavailableModalContext } from "Context";

import ReactGA from "react-ga";
import Whatsapp from "components/whatsapp/whatsapp";
import CookieConsent from "react-cookie-consent";
import { HeaderHelmet } from "components/HeaderHelmet/HeaderHelmet";

ReactGA.initialize("UA-236552941-1");

const Business = () => {
  let history = useHistory();
  const about = gsap.timeline();
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  const [uModalVisibility, setUModalVisibility] = React.useContext(
    UnavailableModalContext
  );
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  window.scrollTo(0, 0);
  return (
    <>
      <HeaderHelmet
        title="Wao for Business | Open a US account without having to be resident and manage your dollar account as you need"
        description="Wao offers you a digital account in dollars under the laws and regulations of the United States"
        type="webapp"
        name="Wao for Business | Open a US account without having to be resident and manage your dollar account as you need"
      />
      <ResponseBusiness />
      <div className="business-container">
        <Transicion timeline={about} />

        {/*Seccion 1*/}
        <div className="div-seccion-one">
          <div className="div-text">
            <p className="title">{langChoosed.section1?.text8}</p>
            <p className="default-subTitle ">{langChoosed.section1?.text9}</p>
            <div className="default-list ">
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section1?.text10}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section1?.text11}</div>
              </div>
              <div className="item">
                <div className="image">
                  <CheckIcon />
                </div>
                <div className="text">{langChoosed.section1?.text12}</div>
              </div>
            </div>

            <ButtonBubble
              className="small"
              // onClick={() => handleRedirectWithModal ("/register-empresa")}
            >
              <a className="text-business" href="/register-business">
                {langChoosed.section1?.text7}
              </a>
            </ButtonBubble>
          </div>
          <div className="img">
            <img className="img-one" src={Imagen1} alt="cuenta-wao" />
          </div>
        </div>

        {/*Seccion 2*/}
        <div className="div-seccion-two">
          <div className="div-tres-secciones">
            <p className="title-comen">{langChoosed.section2?.text5}</p>
            <div className="cuadro-uno">
              <div className="title-icon">
                <div className="number">
                  <p className="number-text">{langChoosed.section2?.text12}</p>
                </div>
                <div className="icon">
                  <img src={check} alt="" />
                </div>
              </div>
              <div className="text">
                <div className="subtitle">
                  <p>{langChoosed.section2?.text6}</p>
                </div>
                <div className="text-one">
                  <p>{langChoosed.section2?.text9}</p>
                </div>
              </div>
            </div>
            <div className="cuadro-dos">
              <div className="title-icon">
                <div className="number">
                  <p className="number-text">{langChoosed.section2?.text13}</p>
                </div>
                <div className="icon">
                  <img src={been} alt="" />
                </div>
              </div>
              <div className="text">
                <div className="subtitle">
                  <p>{langChoosed.section2?.text7}</p>
                </div>
                <div className="text-one">
                  <p>{langChoosed.section2?.text10}</p>
                </div>
              </div>
            </div>
            <div className="cuadro-tres">
              <div className="title-icon">
                <div className="number">
                  <p className="number-text">{langChoosed.section2?.text14}</p>
                </div>
                <div className="icon">
                  <img src={stars} alt="" />
                </div>
              </div>
              <div className="text">
                <div className="subtitle">
                  <p>{langChoosed.section2?.text8}</p>
                </div>
                <div className="text-one">
                  <p>{langChoosed.section2?.text11}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="linea-button">
            <div className="linea">
              <img src={line} alt="" />
            </div>
            <div className="button">
              <ButtonBubble
                className={" small"}
                // onClick={() => handleRedirectWithModal("/register-empresa")}
              >
                <a className="text-business" href="/register-business">
                  {langChoosed.section1?.text7}
                </a>
              </ButtonBubble>
            </div>
          </div>
        </div>

        {/*Seccion 3*/}
        <div className="div-seccion-three">
          <div className="div-text">
            <p className="title">
              <span className="span"> {langChoosed.section3?.text4} </span>
              {langChoosed.section3?.text5}
            </p>

            <div className="text-online">
              <p className="text-text">{langChoosed.section3?.text1}</p>
              <p className="text-text">{langChoosed.section3?.text2}</p>
            </div>
          </div>
          <div className="img">
            <img className="img-three" src={Imagen3} alt="cuenta-wao" />
          </div>
        </div>

        {/*Seccion 4 */}
        <div className="div-seccion-four">
          <div className="div-text">
            <p className="title">
            <span className="span"> {langChoosed.section4?.text5} </span>
              {langChoosed.section4?.text6}
            </p>

            <div className="text-online">
            <p className="text-text">{langChoosed.section4?.text1}</p>
              <p className="text-text">{langChoosed.section4?.text2}</p>
              <p className="text-text">{langChoosed.section4?.text3}</p>
            </div>
          </div>
          <div className="img">
            <img className="img-four" src={Imagen4} alt="cuenta-wao" />
          </div>
        </div>


      

        {/*Seccion 5 */}


        <div className="div-seccion-five">
          <div className="div-text">
            <p className="title">
            <span className="span"> {langChoosed.section5?.text5} </span>
              
              {langChoosed.section4?.text6}
            </p>

            <div className="text-online">
            <p className="text-text">{langChoosed.section5?.text1}</p>
              <p className="text-text">{langChoosed.section5?.text2}</p>
              <p className="text-text">{langChoosed.section5?.text3}</p>
            </div>
          </div>
          <div className="img">
            <img className="img-five" src={Imagen5} alt="cuenta-wao" />
          </div>
        </div>

        {/*Seccion 6*/}
        <div className="div-seccion-six">
          <div className="div-text">
            <p className="title">
              {langChoosed.section6?.title1}
              <span className="span"> {langChoosed.section6?.title2}</span>
            </p>

            <div className="text-online">
              <p className="text-text">{langChoosed.section6?.text1}</p>
              <p className="text-text">{langChoosed.section6?.text2}</p>
            </div>
          </div>
          <div className="img">
            <img className="img-six" src={Imagen6} alt="cuenta-wao" />
          </div>
        </div>

        <FooterTemplate className="business fooTemp__container">
          <div className="foo__content">
            <div className="title-register">
              <div className="free">
                <span>{langChoosed.section8?.text2}</span>
              </div>
              <div className="register">
                <span>{langChoosed.section8?.text1}</span>
              </div>
            </div>

            <div className="parrafo-register">
              <div className="text-register">
                {langChoosed.section8?.text3 + langChoosed.section8?.text4}
              </div>
            </div>
            <div className="button-register">
              <ButtonBubble
                className={"light-business small"}
                onClick={() => history.push("/register-business")}
              >
                <a className="text-register" href="/register-business">
                  {langChoosed.section8?.text5}
                </a>
              </ButtonBubble>
            </div>
          </div>
          {/* <div className={"images"}>
          <img src={Imagen7} alt="home" className={"image-home"} />
        </div> */}
        </FooterTemplate>

        <Whatsapp text={langChoosed.whatsapp?.text1} />
        <CookieConsent
          buttonText="Accept "
          style={{ background: "#204694" }}
          buttonStyle={{ fontSize: "16px", color: "#121a2c" }}
        >
          {langChoosed.copy?.text1}
          <Link onClick={(event) => (window.location.href = "/cookie")}>
            {langChoosed.copy?.text2}
          </Link>
        </CookieConsent>
        <AnimatedCursor
          color="255, 198, 41"
          outerAlpha={0.5}
          innerScale={0.9}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      </div>
    </>
  );
};

export default Business;
