import React from "react";

import "./text.scss";

const Text = ({ category, color, align, children, ...rest }) => {
  switch (category) {
    case "h1":
      return (
        <h1
          className={`font h1 ${color && color} ${align && align} ${
            rest.className
          }`}
        >
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2
          className={`font h2 ${color && color} ${align && align} ${
            rest.className
          }`}
        >
          {children}
        </h2>
      );

    case "a":
      return (
        <a
          {...rest}
          className={`font ${color && color} ${align && align} ${
            rest.className
          }`}
        >
          {children}
        </a>
      );
    case "small":
      return (
        <p
          className={`font small ${color && color} ${align && align} ${
            rest.className
          }`}
        >
          {children}
        </p>
      );

    case "error":
      return (
        <p
          className={`font small error ${color && color} ${align && align} ${
            rest.className
          }`}
        >
          {children}
        </p>
      );

    default:
      return (
        <p
          className={`font text ${color && color} ${align && align} ${
            rest.className
          }`}
        >
          {children}
        </p>
      );
  }
};

export default Text;
