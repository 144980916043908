const En = {

  whatsapp: {
    text1: "¿Need Help? Chat whith us"
  },
  footer: {
    text1: "LEGAL",
    text2: "CONTACT",
    text3: "ANY QUESTION?",
    text4: "License",
    text5: "Terms and Conditions",
    text6: "Disclaimer",
    text7: "Privacy Policy",
    text8: "FAQ Wao",
    text9: "All rights reserved, Services 24/7 LLC",

  },
  section7: {
    text1: "Enjoy a new digital experience",
    text2: "Sign up now",
    text3: "JOIN THE WAITLIST",
  },
  modal: {
    title: ["Thanks for your interest in Wao products! "],
    subTitle: [
      'Currently, our product for individuals, "WaoOne," is unavailable for your region. ',
    ],
    paragraphs: [
      "However, you can register here to be one of the first to enjoy a digital account without borders as soon as it is available for your region.",
    ],
    buttons: ["Join the waitlist"],
  },
  modalE: {
    title: ["Thank you for your interest in Wao products!"],
    subTitle: [
      'Currently, our business product "WaoBiz" is not available for your country.',
    ],
    paragraphs: [
      "However, you can register here to be one of the first to enjoy a digital account without borders as soon as it is available for your region.",
    ],
    buttons: ["Join the waitlist"],
  },

  form: {
    placeholderName: "Enter you name",
    placeholderEmail: "Enter your email",
    placeholderCountry: "Country of residence",
    placeholderNameE: "Company Name",
    placeholderReE: "Legal Representative",
    placeholderEmailE: "Enter your mail",
    placeholderCountryE: "Legal Country",
    errorRequired: "Required field",
    errorMaxLength: "Max. caracters",
    errorInvalidEmail: " Required field / Is not an email",
    toastPending: "Please wait...",
    toastSuccessful: "Message sent",
    toastError: "Something went wrong",
  },

   mastercard: {
    text1: "Mastercard® WaoCard is issued by Patriot Bank®, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard® WaoCard may be used everywhere Debit Mastercard is accepted.",
    text2: "Not all WAO® products and services are available in all geographic locations. Your eligibility for particular products or services is subject to final determination by Services 24-7 LLC or its partners.",
    text3: "Mastercard and the Mastercard Brand Mark are registered trademarks of Mastercard International Incorporated.",
    text4: "Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc. Google Play and the Google Play logo are trademarks of Google LLC.",
    text5: "Zelle® and the Zelle® related products are registered trademarks of Early Warning Services, LLC."
   }
};

export default En;
