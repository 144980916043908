import React from "react";

import "./buttonBubble.scss";

const ButtonBubble = ({ children, onClick, className, type='button', disabled }) => {
  let ClassName = 'bubbly-button'
  console.log(className);
  if(className) ClassName+=` ${className}`
  React.useEffect(() => {
    var animateButton = function (e) {
      e.target.classList.remove("animate");

      e.target.classList.add("animate");
      setTimeout(function () {
        e.target.classList.remove("animate");
      }, 700);
    };

    var bubblyButtons = document.getElementsByClassName(ClassName);

    for (var i = 0; i < bubblyButtons.length; i++) {
      bubblyButtons[i].addEventListener("click", animateButton, false);
    }
  }, []);

  return (
    <button disabled={disabled} type={type} className={ClassName} onClick={() => onClick && onClick()}>
      {children}
    </button>
  );
};

export default ButtonBubble;
