import React from 'react'
import SurveyA from '../Atomos/surveyA'

const SurveyM = () => {
  return (
    <div>
       <SurveyA/>
    </div>
  )
}

export default SurveyM