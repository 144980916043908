import React from "react";
import "./table.scss";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { LanguageContext } from "Context";
import EnLang from "../../Lang/En";
import EsLang from "../../Lang/Es";
const TableTwo = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  return (
    <div className="table-global">
      <div className="div-head-two">
        <div className="div-head-one">{langChoosed?.head?.head3}</div>
        <div>{langChoosed?.head?.head4}</div>
        <div>{langChoosed?.head?.head5}</div>
      </div>
      <Table className="table-one-wrapper">
        <TableHead>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody className="table-body">
          <TableRow  className="row-gray">
            <TableCell className="title-two">
              {langChoosed?.tableTwo?.text1}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text7}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text8}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="title-two">
              {langChoosed?.tableTwo?.text2}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text7}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text7}
            </TableCell>
          </TableRow>
          <TableRow  className="row-gray">
            <TableCell className="title-two">
              {langChoosed?.tableTwo?.text3}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text8}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text9}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell className="title-two">
              {langChoosed?.tableTwo?.text4}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text8}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text9}
            </TableCell>
          </TableRow>
          <TableRow  className="row-gray">
            <TableCell className="title-two">
              {langChoosed?.tableTwo?.text5}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text8}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text9}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell className="title-two">
              {langChoosed?.tableTwo?.text6}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text8}
            </TableCell>
            <TableCell className="text-two">
              {langChoosed?.tableTwo?.text9}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default TableTwo;
