import React, { memo } from "react";
import "./Modal.scss";
const Modal = memo(
  ({ children, variation = "pink", className, active, closeModal, image }) => {
    let MainClass = "modal";
    if (active) MainClass += ` active`;
    let Class = "modal-body";
    if (className !== null && className !== undefined) Class += ` ${className}`;
    let Style = {};
    if (image) {
      Style = { backgroundImage: `url(${image})` };
    }
    return (
      <div className={MainClass}>
        <div className="modal-content">
          <span className="closebutton" onClick={closeModal}>
            &#215;
          </span>
          <div style={Style} className={Class} data-variation={variation}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);
Modal.displayName = "Modal";
export default Modal;
