import React from "react";
import "./FooterTemplate.scss";
import { Footer2 } from "components";

const FooterTemplate = ({ children, className, style }) => {
  return (
    <div className={`fooTemp `}>
      <div
        style={style}
        className={className ? className : "fooTemp__container"}
      >
        {children}
      </div>
      <Footer2 />
    </div>
  );
};

export default FooterTemplate;
