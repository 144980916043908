import React from "react";
import "./table.scss";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { LanguageContext } from "Context";
import EnLang from "../../Lang/En";
import EsLang from "../../Lang/Es";
const TableFour = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  return (
    <div className="table-global">
      <div className="div-head">
        <div className="div-head-one">{langChoosed?.head?.head8}</div>
      </div>
      <Table className="table-four-wrapper">
        <TableHead>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody className="table-body">
          <TableRow>
            <TableCell className="text-four">
              <ul>
                <li> {langChoosed?.tableFour?.text1}</li>
                <br></br>
                <li> {langChoosed?.tableFour?.text2}</li>
                <br></br>
                <li> {langChoosed?.tableFour?.text3}</li>
              </ul>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default TableFour;
