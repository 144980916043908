import React from "react";
import "./survey.scss"

import SurveyP from "./Plantillas/surveyP";

const Survey = () => {
  return (
    <div className="survey">
      <SurveyP />
    </div>
  );
};

export default Survey;
