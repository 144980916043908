import React, { useState } from "react";
import Select from "react-select";
import { LanguageContext } from "Context";
import "./Supplier.scss";
import { setCountryID } from "redux/features/country";
import EnLang from "../../pages/RegisterForm/lang/En";
import EsLang from "../../pages/RegisterForm/lang/Es";
import { useDispatch } from "react-redux";
import Text from "../Text/Text";
import { fontSize, fontStyle, fontWeight, lineHeight } from "@mui/system";
import { normalize } from "gsap";
console.clear();

export const Suppliers = ({ error, handlerCountry}) => {
  const [lang] = React.useContext(LanguageContext);
  const [country, setCountry] = React.useState([]);
  const [langChoosed, setLangChoosed] = React.useState({});
  const handleSelectChange = (event) => {
    handlerCountry(event);
  };
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }

    const getCountry = () => {
      fetch(`https://wao.net/api/list/country?language=${lang}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw text;
          });
        })
        .then((res) => {
          setCountry(res);
        })
        .catch((err) => {
          const errData = JSON.parse(err);
          if (errData?.error) {
            err(errData?.error);
          } else {
            err("Ocurrio un error en el servidor");
          }
        });
    };
    getCountry();
  }, [lang]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: 30,
      height: 60,
      paddingLeft: 32,
      paddingRight: 32,
      marginBottom: 15,
      borderColor: state.isFocused ? "white" : "white",
      color: state.isSelected ? "red" : "blue",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,

      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
      },
    }),
  };

  return (
    <div className={`react-select ${error ? "error" : ""}`}>
      <Select
        className="select-input"
        placeholder={langChoosed.form?.placeholderCountryE}
        options={country.map((sup) => ({
          label: sup.country,
          value: sup.id,
        }))}
        onChange={handleSelectChange}
        
        
      />
      <div className="msg-error">
        {error && <Text category="error">{error}</Text>}
      </div>
    </div>
  );
};