import React, { memo } from "react";

const SvgOptText = ({ active, children }) => {
  const svgRef = React.useRef(null);
  const handleActive = () => {
    const pathArr = svgRef.current.querySelectorAll("path");
    pathArr.forEach((element) => {
      if (active) {
        element.classList.remove("disabled");
        element.classList.add("active");
      } else {
        element.classList.remove("active");
        element.classList.remove("disabled");
      }
    });
  };
  const handleActiveText = () => {
    const pathArr = svgRef.current.querySelectorAll("p");
    pathArr.forEach((element) => {
      if (active) {
        element.classList.remove("disabled");
        element.classList.add("active");
      } else {
        element.classList.remove("active");
        element.classList.remove("disabled");
      }
    });
  };
  React.useEffect(() => {
    if (active) {
      handleActive();
      handleActiveText();
    } else {
      const pathArr = svgRef.current.querySelectorAll("path");

      pathArr.forEach((element) => {
        element.classList.add("word");
        element.classList.add("disabled");
      });
    }
  }, [active]);
  React.useEffect(() => {
    if (active) {
      handleActive();
      handleActiveText();
    } else {
      const pathArr = svgRef.current.querySelectorAll("p");

      pathArr.forEach((element) => {
        element.classList.add("word");
        element.classList.add("disabled");
      });
    }
  }, [active]);
  return (
    <div className="option__faq" ref={svgRef}>
      {children}
    </div>
  );
};

export default SvgOptText;
