import React from "react";
import AnimatedCursor from "react-animated-cursor";
import CookieConsent from "react-cookie-consent";
import "./RegisterForm.scss";

import { ButtonBubble, Footer2 } from "../../components";
import gsap from "gsap";
import Transicion from "components/Transicion/Transicion";
import { FooterTemplate } from "../../Containers";

import { LanguageContext, UnavailableModalContext } from "Context";

import Form from "./formulario/Form";

import { Link, animateScroll as scroll } from "react-scroll";

import EnLang from "./lang/En";
import EsLang from "./lang/Es";

import ReactGA from "react-ga";
import { useHistory, withRouter } from "react-router-dom";

import Modal from "components/Modal/Modal";
import Whatsapp from "components/whatsapp/whatsapp";
import { HeaderHelmet } from "components/HeaderHelmet/HeaderHelmet";

ReactGA.initialize("UA-236552941-1");

const RegisterForm = () => {
  const history = useHistory();
  const about = gsap.timeline();
  const [lang] = React.useContext(LanguageContext);
  const [uModalVisibility, setUModalVisibility] = React.useContext(
    UnavailableModalContext
  );
  const [langChoosed, setLangChoosed] = React.useState({});

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  const handleRedirectModal = () => {
    setUModalVisibility(false);
    history.push("/register-person");
  };

  return (
    <>
      <HeaderHelmet
        title="Wao payments | Register as a person"
        description="Enjoy a new digital experience"
        type="webapp"
        name="Wao payments | Register as a person"
      />
      <Modal closeModal={handleRedirectModal} active={uModalVisibility}>
        <div className="modal-unavailable">
          <div className="modal-title" style={{ marginBottom: "31px" }}>
            {langChoosed.modal?.title[0]}
          </div>
          <div className="modal-subTitle">{langChoosed.modal?.subTitle[0]}</div>
          <div className="modal-paragraph" style={{ marginBottom: "31px" }}>
            {langChoosed.modal?.paragraphs[0]}
          </div>

          <ButtonBubble onClick={handleRedirectModal}>
            {langChoosed.modal?.buttons[0]}
          </ButtonBubble>
        </div>
      </Modal>

      <Transicion timeline={about} />

      <Form />
      <Footer2 />
      <Whatsapp text={langChoosed.whatsapp?.text1} />
      <CookieConsent
        buttonText="Accept "
        style={{ background: "#204694" }}
        buttonStyle={{ fontSize: "16px", color: "#121a2c" }}
      >
        This website uses cookies to enhance the user experience.
        <Link onClick={(event) => (window.location.href = "/cookie")}>
          Privacy Cookie
        </Link>
      </CookieConsent>
      <AnimatedCursor
        innerSize={16}
        outerSize={12}
        color="255, 198, 41"
        outerAlpha={0.5}
        innerScale={0.9}
        outerScale={5}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
    </>
  );
};

export default withRouter(RegisterForm);
