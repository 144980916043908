import React from "react";

import { Helmet } from "react-helmet-async";
import img from "../../assets/wao-payments-cover.jpg";
export const HeaderHelmet = ({ title, description, type, name }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="image" content={img} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />
      <meta property="twitter:creator" content={name} />
      <meta property="twitter:card" content={type} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={img} />
    </Helmet>
  );
};
