const Es = {
  section1: {
    text1: "Complete platform to streamline business operations",
    text2: "A USD Account for companies.",
    text4: "Apply to an account online",
    text5: "Receive your application’s verification",
    text6: "That’s all! Start using your account",
    text7: "Start Now",
    text8: "Open your account  U.S. from your country",
    text9: "A dollar account for business",
    text10: "Receive ACH and Wire Transfer",
    text11: "Apply for your U.S. card",
    text12: "Receive payments via credit or debit cards and Zelle®",
  },

  section2: {
    text1:
      "A US Digital Account built for registered corporations and enterprises.",
    text2:
      "Everything you can ask for in an account to mobilize your funds with the flexibility that your business needs. ",
    text3:
      "Wao Integrates all the advantages of a merchant processing account that allows you to receive all types of payments.",
    text4: "Manage your business finance on autopilot",
    video: "SEE VIDEO",

    text5: "Ready to begin?",
    text6: "User register",
    text7: "Activation request",
    text8: "Welcome onboard",
    text9:
      "Signup the registration of the user responsible for managing the WaoBiz account at wao.online",
    text10:
      "In the main dashboard we present the solutions available in your region, select Wao Digital Account and start the digital onboarding.",
    text11:
      "Your WaoBiz digital account is ready to operate. We will send you documentation that will help you become familiar with accessing and using banking features and advanced payments.",
    text12: "01.",
    text13: "02.",
    text14: "03."
  
    },

  section3: {
    text1:
      "Designed to control your WaoBiz Account through a Web-Banking that offers convenience in your day-to-day transactions.",
    text2:
      "With Waoonline you can make a real-time management of all aspects of your business's financial service and payments process.",
    text3: "Power-up a real web-banking",
    text4: "WaoOnline,",
    text5: "All-in-One platform"
  },

  section4: {
    text1:
      "As your business grows, so do your financial management needs. You have more providers to pay, more employees to manage, more supplies to order, and more travel demands.",
    text2:
      "Manage your WaoBiz account with a corporate card to handle your business expenses more comfortably. Request physical or virtual cards just by opening your account.",
    text3:
      "In addition, you can request additional cards for your employees to keep better control of reimbursable expenses in your company.",
    text4: "Payments where and when you want",
    text5: "WaoCard,",
    text6: "payments anywhere anytime"
  },

  section5: {
    text1: "Receive instant payments with WaoLink.",
    text2:
      "Create a payment link exclusively to your clients, providers, or employees, then send it via email, SMS, chat, or social networks, and that's it.",
    text3:
      "Use the service the way that is more suitable for your company and your clients.",
    text4: "Receive payments in seconds!",
    text5: "WaoLink,",
    text6: " receive payments in just seconds!"
  },

  section6: {
    title1: "Security",
    title2: " & reliability",
    text1:
      "We understand the value of data security. That is why so many hours were invested in infrastructure security processes in close collaboration with the world's best security experts.",
    text2:
      "With this background, we guarantee the infrastructure's security to receive, decrypt, and transmit data in strict compliance with the applicable regulations.",
  },
  section7: {
    title2: "Compliance",
    text1:
      "The prevention against money laundering and terrorist financing is fundamental in Wao. Therefore, we have robust KYC, AML, and 24|7 active transactional monitoring controls, aimed at guaranteeing maximum compliance with international regulations through a powerful Underwrite module.",
  },
  section8: {
    text1: "Signup for free",
    text2: "Are you a business?",
    text3:
      "Open your account in the United States without having to be a US resident and receive transactions via",
    text4: " ACH, Wire Transfer, credit or debit card y Zelle®.",
    text5: "Start now",
  },
  whatsapp: {
    text1: "¿Need Help? Chat whith us",
  },
  copy: {
    text1: "This website uses cookies to enhance the user experience.",
    text2: "Privacy Cookie",
  },
};

export default Es;
