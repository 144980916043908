import React, { useRef, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./NavBar.scss";

import Logo from "assets/Navbar/waoNavLogo.svg";
import LogoBiz from "assets/Navbar/waoBizNavLogo.svg";
import LogoOne from "assets/Navbar/waoOneNavLogo.svg";

import { ActiveItemContext } from "Context";
import { LanguageContext, UnavailableModalContext } from "Context";
import Building from "assets/Navbar/building.svg";
import Person from "assets/Navbar/person.svg";

const menuEs = [
  {
    name: "Inicio",
    link: "/",
  },
  {
    name: "Personas",
    link: "/people",
  },

  {
    name: "Empresas",
    link: "/business",
  },
  {
    name: "Nosotros",
    link: "/about",
  },
  {
    name: "FAQ",
    link: "/faq",
  },
];
const menuEn = [
  {
    name: "Home",
    link: "/",
  },

  {
    name: "Personal",
    link: "/people",
  },
  {
    name: "Business",
    link: "/business",
  },
  {
    name: "About",
    link: "/about",
  },
  {
    name: "FAQ",
    link: "/faq",
  },
];

const NavBar = () => {
  const [lang, setLang] = React.useContext(LanguageContext);
  const [activeItem, setActiveItem] = React.useContext(ActiveItemContext);
  const [uModalVisibility, setUModalVisibility] = React.useContext(
    UnavailableModalContext
  );
  const [langChoosed, setLangChoosed] = React.useState([]);
  const [dropDown, setDropDown] = React.useState(false);
  let location = useLocation();
  const nav = useRef();
  const buttonRef = React.useRef(null);
  const dropDownRef = useRef(null);
  const history = useHistory();
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    }
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropDownRef]);

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(menuEn);
    } else {
      setLangChoosed(menuEs);
    }
  }, [lang]);
  const findPage = () => {
    langChoosed.forEach((data, i) => {
      if (data.link === location.pathname) {
        changePage(i);
      }
    });
  };
  useEffect(() => {
    findPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = (i) => {
    /* const navbar = document.getElementById("offcanvasRight");
    const fade = document.getElementsByClassName("offcanvas-backdrop");
    const navbar2 = document.getElementsByClassName("navbar");

    if (navbar && fade[0] && navbar2) {
      navbar.classList.toggle("show");
      fade[0].classList.toggle("show");
      fade[0].remove();
      document.body.style = "auto";
      navbar2.style = "";
    } */
    const button = document.getElementsByClassName("btn-close");

    console.log(button[0]);
    if (button[0]) button[0].click();
    window.scrollTo(0, 0);
    setActiveItem(i);
  };

  const toggleLang = () => {
    if (lang === "En") {
      setLang("Es");
    } else {
      setLang("En");
    }
  };

  const RenderLogo = () => {
    let logo = Logo;
    if (location.pathname === "/business") logo = LogoBiz;
    if (location.pathname === "/people") logo = LogoOne;

    return (
      <div className="logo-content">
        <Link to="/">
          <img src={logo} alt="logo-wao" />
        </Link>
      </div>
    );
  };

  const NavItems = () => {
    return (
      <ul className="nav-items-content">
        {langChoosed.map((data, i) => {
          return (
            <li key={i}>
              <Link
                className={`item ${
                  location.pathname === data.link && "active"
                }`}
                to={data.link}
                onClick={() => {
                  changePage(i);
                }}
              >
                {data.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  const LoginButton = () => {
    return (
      <a data-variation="login" href="https://wao.online/login ">
        {" "}
        {lang === "En" ? "Login" : "Ingresar"}
      </a>
    );
  };
  const handleRedirect = (e) => {
    e.preventDefault();
    setDropDown(false);
    setUModalVisibility(true);
    history.push("/register-person");
  };
  const handleRedirectE = (e) => {
    e.preventDefault();
    setDropDown(false);
    setUModalVisibility(true);
    history.push("/register-business");
  };
  const SignUpButton = () => {
    return (
      <button data-variation="signup" onClick={() => setDropDown(!dropDown)}>
        {lang === "En" ? "Open an account" : "Abrir cuenta"}
        <div
          ref={dropDownRef}
          className={`dropdown ${dropDown ? "active" : ""}`}
        >
          <ul>
            <li>
              <a onClick={handleRedirectE} href="#">
                {lang === "En" ? "As a bussiness" : "Como empresa"}
                <img src={Building} alt="Building" />
              </a>
            </li>
            <li>
              <a onClick={handleRedirect} href="/">
                {lang === "En" ? "As a person" : "Como persona"}
                <img src={Person} alt="Person" />
              </a>
            </li>
          </ul>
        </div>
      </button>
    );
  };

  const AuthButtons = () => {
    return (
      <div className="auth-buttons">
        <LoginButton />
        <SignUpButton />
      </div>
    );
  };

  const ToggleLenguageButtons = () => {
    return (
      <div className="lang-buttons-navbar">
        <label
          className={`${lang === "En" ? "active" : ""}`}
          onClick={toggleLang}
        >
          EN
        </label>
        <span>/</span>
        <label
          className={`${lang === "Es" ? "active" : ""}`}
          onClick={toggleLang}
        >
          ES
        </label>
      </div>
    );
  };

  return (
    <>
      {/* ------------------------------- MOBILE ------------------------------- */}
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container-fluid">
          <RenderLogo />
          <div className="right">
            <div className="auth-buttons">
              <button
                data-variation="signup"
                onClick={() => setDropDown(!dropDown)}
              >
                {lang === "En" ? "Open an account" : "Abrir cuenta"}
                <div className={`dropdown ${dropDown ? "active" : ""}`}>
                  <ul>
                    <li>
                      <a onClick={handleRedirectE} href="#">
                        {lang === "En" ? "As a bussiness" : "Como empresa"}
                        <img src={Building} alt="Building" />
                      </a>
                    </li>
                    <li>
                      <a onClick={handleRedirect} href="/">
                        {lang === "En" ? "As a person" : "Como persona"}
                        <img src={Person} alt="Person" />
                      </a>
                    </li>
                  </ul>
                </div>
              </button>
            </div>
            <button
              class="btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <div className="top">
                <ul className=" navbar-nav items_content">
                  <div className="auth-buttons mobile">
                    <LoginButton />
                  </div>
                  {langChoosed.map((data, i) => {
                    return (
                      <li
                        className="nav-item"
                        key={i}
                        onClick={() => buttonRef.current.click()}
                      >
                        <Link
                          className={`item ${
                            location.pathname === data.link && "active"
                          }`}
                          to={data.link}
                          onClick={() => {
                            changePage(i);
                          }}
                        >
                          {data.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="bottom">
                <ToggleLenguageButtons />
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* ------------------------------- DESKTOP ------------------------------- */}
      <header className="navbarWao__container" ref={nav}>
        <RenderLogo />
        <NavItems />
        <div className="nav-buttons-content">
          <AuthButtons />
          <ToggleLenguageButtons />
        </div>
      </header>
    </>
  );
};

export default NavBar;
