import React from "react";
import WhatsappIcon from "assets/images/whatsapp.svg";
import "./whatsapp.scss";
import { Link } from "react-router-dom";

const Whatsapp = (props) => {
  return (
    <div className="whatsapp-wrapper">
     
      <Link
      className="link-whatsapp"
        to={{
          pathname:
            "https://api.whatsapp.com/send/?phone=18634439683&text&type=phone_number&app_absent=0",
        }}
        target="_blank"
      >
        <img className="icon-whatsapp" src={WhatsappIcon} alt="" />
        {/* <div className="text-whatsapp">{props.text}</div> */}
      </Link>
    </div>
  );
};

export default Whatsapp;
