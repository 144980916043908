import React, { memo, useState } from "react";
import Iconplus from "../../../assets/icons/iconplus.svg";
import Iconminus from "../../../assets/icons/iconminus.svg";
const Accordion = ({ items }) => {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <div className="faq-web-wao">
      {items.map((item, i) => {
        return (
          <div className="faq-item" key={i} onClick={() => toggle(i)}>
            <div className="faq-header">
              <p
                className={`title-faq ${selected === i ? "active" : ""}`}
                onClick={() => toggle(i)}
              >
                {item.title}
              </p>

              {selected === i ? (
                <img src={Iconminus} alt="" onClick={() => toggle(i)} />
              ) : (
                <img src={Iconplus} alt="" onClick={() => toggle(i)} />
              )}
            </div>

            <div className={`content ${selected === i ? "show" : "hidden"}`}>
              {item.content}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
