import React from "react";
import "./Cookies.scss";
import { Text } from "../../components";
import { Section } from "Containers";
import AnimatedCursor from "react-animated-cursor";


import ReactGA from "react-ga";

ReactGA.initialize("UA-236552941-1");


const Cookie= () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

    return (
      <>

      <Section className="galletas">
  <h1>POLÍTICA DE COOKIES </h1>
  <p>
  El acceso a este Sitio Web www.wao.net puede implicar la utilización de cookies. Las cookies son
pequeñas cantidades de información que se almacenan en el navegador utilizado por cada
Usuario —en los distintos dispositivos que pueda utilizar para navegar— para que el servidor
recuerde cierta información que posterior y únicamente el servidor que la implementó leerá. Las
cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación.
<br />
<br />
Las cookies son procedimientos automáticos de recogida de información relativa a las
preferencias determinadas por el Usuario durante su visita al Sitio Web con el fin de reconocerlo
como Usuario, y personalizar su experiencia y el uso del Sitio Web, y pueden también, por
ejemplo, ayudar a identificar y resolver errores.
<br />
<br />
La información recabada a través de las cookies puede incluir la fecha y hora de visitas al Sitio
Web, las páginas visionadas, el tiempo que ha estado en el Sitio Web y los sitios visitados justo
antes y después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda
contactarse con el número de teléfono del Usuario o con cualquier otro medio de contacto
personal. Ninguna cookie puede extraer información del disco duro del Usuario o robar
información personal. La única manera de que la información privada del Usuario forme parte del
archivo Cookie es que el usuario dé personalmente esa información al servidor.
<br />
<br />
Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a
las mismas les será de aplicación la Política de Privacidad anteriormente descrita. En este sentido,
para la utilización de estas será necesario el consentimiento del Usuario. Este consentimiento
será comunicado, en base a una elección auténtica, ofrecido mediante una decisión afirmativa y
positiva, antes del tratamiento inicial, removible y documentado.
  </p>
  <br /><br />
<h3>Cookies de terceros</h3>

<p> Son cookies utilizadas y gestionadas por entidades externas que proporcionan a Fintech servicios
solicitados por este mismo para mejorar el Sitio Web y la experiencia del usuario al navegar en el
Sitio Web. Los principales objetivos para los que se utilizan cookies de terceros son la obtención
de estadísticas de accesos y analizar la información de la navegación, es decir, cómo interactúa
el Usuario con el Sitio Web.
<br /><br />
La información que se obtiene se refiere, por ejemplo, al número de páginas visitadas, el idioma,
el lugar a la que la dirección IP desde el que accede el Usuario, el número de Usuarios que
acceden, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador que usan, el
operador o tipo de dispositivo desde el que se realiza la visita. Esta información se utiliza para
mejorar el Sitio Web, y detectar nuevas necesidades para ofrecer a los Usuarios un Contenido y/o
servicio de óptima calidad. En todo caso, la información se recopila de forma anónima y se
elaboran informes de tendencias del Sitio Web sin identificar a usuarios individuales.
<br /><br />
Puede obtener más información sobre las cookies, la información sobre la privacidad, o consultar
la descripción del tipo de cookies que se utiliza, sus principales características, periodo de
expiración, etc. en
el siguiente(s) enlace(s):
<a href="https://developers.google.com/analytics/" target="_blank" rel="noopener noreferrer">https://developers.google.com/analytics/</a>

<br /><br />
La(s) entidad(es) encargada(s) del suministro de cookies podrá(n) ceder esta información a
terceros, siempre y cuando lo exija la ley o sea un tercero el que procese esta información para
dichas entidades.</p>

<h3>Cookies de redes sociales</h3>


<p>

Fintech incorpora plugins de redes sociales, que permiten acceder a las mismas a partir del Sitio
Web. Por esta razón, las cookies de redes sociales pueden almacenarse en el navegador del
Usuario. Los titulares de dichas redes sociales disponen de sus propias políticas de protección de
datos y de cookies, siendo ellos mismos, en cada caso, responsables de sus propios ficheros y de
sus propias prácticas de privacidad. El Usuario debe referirse a las mismas para informarse
acerca de dichas cookies y, en su caso, del tratamiento de sus datos personales. Únicamente a
título informativo se indican a continuación los enlaces en los que se pueden consultar dichas
políticas de privacidad y/o de cookies:
<br /><br />

<ul>
  <li>Facebook: <a href="https://www.facebook.com/policies/cookies/" target="_blank"> https://www.facebook.com/policies/cookies/ </a></li>
  <li>Twitter: <a href="https://twitter.com/es/privacy " target="_blank"> https://twitter.com/es/privacy </a></li> 
  <li>Instagram: <a href="https://help.instagram.com/1896641480634370?ref=ig" target="_blank"> https://help.instagram.com/1896641480634370?ref=ig</a></li>
  <li>YouTube: <a href="https://policies.google.com/privacy?hl=es-419&amp;gl=mx" target="_blank" rel="noopener noreferrer">YouTube: https://policies.google.com/privacy?hl=es-419&amp;gl=mx</a></li>
<li>Pinterest:  <a href="https://policy.pinterest.com/es/privacy-policy" target="_blank" rel="noopener noreferrer">https://policy.pinterest.com/es/privacy-policy</a></li>
<li>LinkedIn:  <a href="https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies</a></li>
</ul> 
</p>

<h3>Deshabilitar, rechazar y eliminar cookies</h3>

<p>El Usuario puede deshabilitar, rechazar y eliminar las cookies —total o parcialmente— instaladas
en su dispositivo mediante la configuración de su navegador (entre los que se encuentran, por
ejemplo, Chrome, Firefox, Safari, Explorer). En este sentido, los procedimientos para rechazar y
eliminar las cookies pueden diferir de un navegador de Internet a otro. En consecuencia, el
Usuario debe acudir a las instrucciones facilitadas por el propio navegador de Internet que esté
utilizando. En el supuesto de que rechace el uso de cookies —total o parcialmente— podrá seguir
usando el Sitio Web, si bien podrá tener limitada la utilización de algunas de las prestaciones de
este.</p>
      </Section>

      <AnimatedCursor
      innerSize={16}
      outerSize={12}
      color='255, 198, 41'
      outerAlpha={0.5}
      innerScale={0.9}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
    />


</>
    );
  };
  
  export default Cookie;
  