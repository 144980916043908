const En = {
  licence: {
    text1: "Dear Wao users:",
    text2:
      "Services 24-7 LLC is a Payment Service Provider registered with the U.S. Department of Treasury (Fincen) as a MSB (Money Service Business) and Prepaid Access provider. We are authorized to transmit money in the U.S. states and territories where we have been issued a license or through the licenses of our partners/providers in the other states.",
    text3: "MSB",
    text4:
      "MSBs are regulated by FinCEN (Financial Crimes Enforcement Agency which is part of the U.S. Treasury Department). The main objects are the supervision of businesses and companies that provide money services. The scope includes international remittance, foreign currency exchange, currency transaction/transfer (including digital currency/virtual currency), providing prepaid items, issuing traveler's cheque and other services. Companies engaged in the above related businesses must register before they can operate legal",
    text5: "Registrant Search",
    text6: "MSB search",
    text6: "Enter Services 24-7 LLC at LEGAL NAME to search",
  },
  privacy: {
    text2: "Privacy Policy",
  },
  head: {
    head0: "net 24|7 GLBA Form ",
    head1: "FACTS",
    head2: "WHAT DOES net 24|7 DO WITH YOUR PERSONAL INFORMATION?",
    head3: "Reasons we can share your personal information",
    head4: "Does net 24|7 share?",
    head5: "Can you limit this sharing?",
    head6: "Questions?",
    head7:
      "Call or chat with us at +1 (863) 4439683 or go to  http://www.net24-7.com/privacypolicy.php",
    head8: "Other important information"
    },
  table: {
    text1: "Why?",
    text2: "What?",
    text3: "How?",
    text4:
      "Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, Share, and protect your personal information. Please read this notice carefully to understand what we do.",
    text5:
      "The types of personal information we collect and share depend on the product or service you have with us. This information can include:",
    text6: "Social Security number,  phone number and email address",
    text7: "Name, address, date of birth and biometric data",
    text8: "Payment card and bank information, including account balances",
    text9:
      "When you are no longer our customer, we continue to share your information as described in this notice.",
    text10:
      "All ﬁnancial companies need to share customers personal information to run their everyday business. In the section below, we list the reasons ﬁnancial companies can share their customers personal information; the reasons net 24|7 chooses to share; and whether you can limit this sharing.",
  },
  tableTwo: {
    text1:
      "For our everyday business purposes such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus",
    text2:
      "For our marketing purposes to offer our products and services to you",
    text3: "For joint marketing with other ﬁnancial companies",
    text4:
      "For our afﬁliates’ everyday business purposes information about your transactions and experiences",
    text5:
      "For our afﬁliates’ everyday business purposes information about your creditworthiness",
    text6: "For nonafﬁliates to market to you",
    text7: "Yes",
    text8: "No",
    text9: "We don’t share",
  },
  tableThree: {
    text1: "Who we are",
    text2: "Who is providing this notice?",
    text3: "What we do",
    text4: "How does net 24|7 protect my personal information?",
    text5: "Why can’t I limit all sharing?",
    text6: "Deﬁnitions",
    text7: "Afﬁliates",
    text8: "Nonafﬁliates",
    text9: "Joint marketing",
    text10: "SERVICES 24-7 LLC",
    text11:
      "To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured ﬁles and buildings.",
    text12: "We collect your personal information, for example, when you",
    text13: "sign-up for an account or provide identity verification",
    text14: "engage in a transaction",
    text15: "Federal law gives you the right to limit only",
    text16:
      "sharing for afﬁliates’ everyday business purposes—information about your creditworthiness",
    text17: "afﬁliates from using your information to market to you",
    text18: "sharing for nonafﬁliates to market to you",
    text19:
      "State laws and individual companies may give you additional rights to limit sharing.",
    text20:
      "Companies related by common ownership or control. They can be ﬁnancial and nonﬁnancial companies.",
    text21:
      "Companies not related by common ownership or control. They can be ﬁnancial and nonﬁnancial companies.",
    text22:
      "Nonaffiliates we share with can include financial institutions, processors, payment card associations and other entities that are involved in the payment process.",
    text23:
      "A formal agreement between nonafﬁliated ﬁnancial companies that together market ﬁnancial products or services to you.",
  },
  tableFour: {
    text1:
      "Vermont Residents — In accordance with Vermont law, we will not share personal financial information about you, other than transaction experience information, nor will we share any personal financial information for marketing purposes.",
    text2:
      "Nevada Residents — Nevada law requires that we provide you with the following contact information: Bureau of Consumer Protection, Office of the Nevada Attorney General, 555 E. Washington Ave., Suite 3900, Las Vegas, NV 89101; Phone number: 702-486-3132; email: agInfo@ag.nv.gov.",
    text3:
      "California Residents — We will not share information we collect about you with nonaffiliated third parties, except as permitted by law, including, for example, with your consent or to service your account.",
  },
  terms: {
    text2: "Terms and Conditions",
  },
  disclaimer: {
    text2: "Disclaimer",
  },
};
export default En;
