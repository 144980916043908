import React from "react";

import InstaIcon from "assets/images/insta.svg";
import FacebookIcon from "assets/images/facebook.svg";

import TwitterIcon from "assets/images/twitter.svg";
import TiktokIcon from "assets/images/tiktok.svg";
import YoutubeIcon from "assets/images/youtube.svg";
import logoblanco from "assets/images/Wao_Logo_Wao P-A Blanco.svg";
import correo from "assets/images/mail.svg";
import telefono from "assets/images/phone.svg";
import whatsapp from "assets/images/whatapp.svg";
import { LanguageContext } from "Context";

import { Text } from "..";
import "./footer2.scss";
import EnLang from "../../pages/RegisterForm/lang/En";
import EsLang from "../../pages/RegisterForm/lang/Es";

const Footer2 = ({ className, position }) => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  return (
    <div
      className={`main-footer2 ${className && className} ${
        position ? position : "absolute"
      } `}
    >
      <div className="containera">
        <div className="containerlogo-footer">
          <img className="grande" src={logoblanco} alt="" />
        </div>
        <div className="cont-barra">
          {/* Column1 */}
          <div className="titles">
            <h4 className="titles"> {langChoosed.footer?.text1}</h4>
            <ui className="lines">
              <li>
                <Text category="a" href="/license">
                  {langChoosed.footer?.text4}
                </Text>
              </li>
              <li>
                <Text category="a" href="/Terms">
                  {langChoosed.footer?.text5}
                </Text>
              </li>
              <li>
                <Text category="a" href="/disclaimer">
                  {langChoosed.footer?.text6}
                </Text>
              </li>
              <li>
                <Text category="a" href="/privacy">
                  {langChoosed.footer?.text7}
                </Text>
              </li>
            </ui>
          </div>
          {/* Column2 */}
          <div className="titles">
            <h4 className="titles"> {langChoosed.footer?.text2}</h4>
            <ui className="lines">
              <li>
                <img className="img-fluid" src={correo} alt="" />
                <Text category="a" href="mailto:support@wao.net" target="blank">
                  support@wao.net
                </Text>{" "}
              </li>
              <li>
                <img className="img-fluid" src={telefono} alt="" />
                <Text category="a" href="tel: +18033531153" target="blank">
                  + 1 803 353 11 53
                </Text>{" "}
              </li>
              <li>
                <img className="img-fluid" src={whatsapp} alt="" />
                <Text
                  category="a"
                  href="https://wa.me/18634439683"
                  target="blank"
                >
                  + 1 863 443 96 83
                </Text>{" "}
              </li>
            </ui>
          </div>
          {/* Column3 */}
          <div className="titles">
            <h4 className="titles"> {langChoosed.footer?.text3}</h4>
            <ui className="lines">
              <li>
                <a href="/faq" className="bold">
                  FAQ Wao
                </a>
              </li>
            </ui>
          </div>
          {/* Column4 */}
          <div className="mastercard">
            <ui className="mastercard-lines">
              <li>
                <Text className="text-mastercard">
                {langChoosed.mastercard?.text1}
              
                </Text>
              </li>
              <li>
                <Text className="text-mastercard">
                {langChoosed.mastercard?.text2}
                </Text>
              </li>
              <li>
                <Text className="text-mastercard">
                {langChoosed.mastercard?.text3}
                </Text>
              </li>
              <li>
                <Text className="text-mastercard">
                {langChoosed.mastercard?.text4}
                </Text>
              </li>
              <li>
                <Text className="text-mastercard">
                {langChoosed.mastercard?.text5}
                </Text>
              </li>
            </ui>
          </div>
    
        </div>
      </div>
      <hr />
      <div className="media">
        <div className="hijo">
          <Text
            category="a"
            href="https://www.facebook.com/24.7Wao/"
            target="blanck"
          >
            {" "}
            <img src={FacebookIcon} alt="" />
          </Text>
          <Text
            category="a"
            href="https://www.instagram.com/wao24.7/"
            target="blanck"
          >
            {" "}
            <img src={InstaIcon} alt="" />
          </Text>
          <Text category="a" href="https://twitter.com/wao24_7" target="blanck">
            {" "}
            <img src={TwitterIcon} alt="" />
          </Text>
          <Text
            category="a"
            href="https://www.youtube.com/channel/UCKU9jK4GGQsiCLsHv_0QSwg"
            target="blanck"
          >
            {" "}
            <img src={YoutubeIcon} alt="" />
          </Text>
          <Text
            category="a"
            href="https://www.tiktok.com/@wao24.7?_t=8YjA2iHXZYr&_r=1"
            target="blanck"
          >
            {" "}
            <img src={TiktokIcon} alt="" />
          </Text>
        </div>
      </div>
      <div className="hijo2">
        <Text className="lines2">{langChoosed.footer?.text9}</Text>
      </div>
    </div>
  );
};
export default Footer2;
