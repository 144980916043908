const Es = {
  section1: {
    text1: "Plataforma única para agilizar las operaciones comerciales",
    text2: "Cuenta en Estados Unidos para empresas",
    text4: "Aplique a una cuenta en línea ",
    text5: "Reciba la verificación de sus datos",
    text6: "¡Listo! Empiece a usar su cuenta",
    text7: "Empiece ahora",
    text8: "Abra su cuenta en los EE.UU. desde su país" ,
    text8a:"EE.UU. desde su país",
    text9: "Una cuenta en dólares para empresas",
    text10: "Reciba ACH y Wire Transfer",
    text11: "Solicite su tarjeta de los EE. UU.",
    text12: "Reciba pagos vía tarjeta y Zelle®",
  },

  section2: {
    text1:
      "Una cuenta digital en los Estados Unidos creada para corporaciones y empresas legalmente registradas.",
    text2:
      "Todo lo que puede pedir en una cuenta para movilizar sus fondos con la flexibilidad que su negocio necesita.",
    text3:
      "Wao integra todas las ventajas de una cuenta de procesamiento que le permitirá recibir todo tipo de pagos.",
    text4: "Administre las finanzas de su empresa en piloto automático",
    video: "VER VIDEO",
    
    text5: "¡Comience ahora!",
    text6: "Registro de usuario",
    text7: "Solicitud de activación",
    text8: "Bienvenido a bordo",
    text9: "Inicie el registro del usuario responsable del manejo de la cuenta WaoBiz en wao.online",
    text10: "En el dashboard principal le presentamos las soluciones disponibles en su región, seleccione “Wao Digital Account” e inicie el onboarding digital.",
    text11: "Su cuenta digital WaoBiz se encuentra lista para operar. Le enviaremos documentación que ayudará a familiarizarse con el acceso y uso de funciones bancarias y pagos avanzados.",
    text12: "01.",
    text13: "02.",
    text14: "03."
  },

  section3: {
    text1:
      "Site administrativo diseñado para gestionar su cuenta WaoBiz a través de un web-Banking que le ofrece comodidad en sus operaciones del día a día.",
    text2:
      "Con WaoOnline podrá monitorear en tiempo real todos los aspectos de las funciones financieras y procesos de pago de su negocio.",
    text3: "¡Conozca un web-Banking real!",
    text4: "WaoOnline,",
    text5: "una plataforma todo en uno"
  },

  section4: {
    text1:
      "A medida que su empresa crece, también lo hacen sus necesidades de gestión financiera. Tiene más proveedores a los que pagar, más empleados que gestionar, más suministros que pedir y más exigencias de viajes.",
    text2:
      "Maneje su cuenta WaoBiz con una tarjeta corporativa, para gerenciar con mayor comodidad los gastos de su negocio. Solicite tarjetas físicas o virtuales solamente con abrir la cuenta.",
    text3:
      "Podrá solicitar tarjetas adicionales para sus empleados y lograr un mayor control de los gastos reembolsables en su empresa.",
    text4: "Pagos donde y cuando quiera",
    text5: "WaoCard,",
    text6: "pagos donde y cuando quiera"
  },

  section5: {
    text1: "Reciba pagos instantáneos con WaoLink.",
    text2:
      "Puede crear un enlace de pago exclusivo para sus clientes, proveedores o empleados, luego envíelo por correo electrónico, SMS, chat o redes sociales, y listo.",
    text3:
      "Utilice el servicio de la manera que se adapte mejor a su empresa y sus clientes.",
    text4: "¡Reciba pagos en segundos!",
    text5: "WaoLink,",
    text6: "reciba pagos en segundos"
  },

  section6: {
    title1: "Seguridad",
    title2: "y confiabilidad",
    text1:
      "Entendemos el valor de los datos. Por eso se invirtieron tantas horas en procesos de seguridad de infraestructuras en estrecha colaboración con los mejores expertos del mundo.",
    text2:
      "Con estos antecedentes, garantizamos la seguridad de la infraestructura para recibir, descifrar y transmitir datos en estricto cumplimiento de la normativa aplicable.",
  },
  section7: {
    title2: "Cumplimiento",
    text1:
      "La prevención contra el blanqueo de capitales y la financiación del terrorismo es fundamental en Wao. Por ello, contamos con robustos controles de monitoreo transaccional activo KYC, AML y 24|7, orientados a garantizar el máximo cumplimiento de las regulaciones internacionales a través de un poderoso módulo de gestión y control.",
  },
  section8: {
    text1: "Regístrese gratis",
    text2: "¿Eres Empresa?",
    text3: "Abra su cuenta en los Estados Unidos sin necesidad de ser residente americano y reciba transacciones vía de",
    text4: " ACH, Wire Transfer, tarjetas y Zelle®.",
    text5: "Empiece ahora",

  },
  whatsapp: {
    text1: "¿Necesitas ayuda? habla con nosotros"
  },
  copy: {
    text1: "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
    text2: "Cookies de privacidad"
  }
};

export default Es;
