const En = {
  section1: {
    text1:
      "En un mundo tan dinámico todo cambia a gran velocidad… Incluso la Banca",
    text2: "Únete a la nueva generación de servicios financieros.",
  },

  section2: {
    text1: "¿Quiénes",
    text2: "somos?",
    text3:
      "Wao es una plataforma diseñada y desarrollada por la empresa de tecnología financiera net 24|7, la misma ofrece a sus clientes productos y servicios tecnológicos que les permiten disfrutar de herramientas innovadoras y seguras, con la promesa de cambiar la forma en que los usuarios manejan sus finanzas.",
    text4:
      "Hemos reunido un equipo que tiene más de 25 años de experiencia en el mercado de la industria de pagos, con un amplio conocimiento estratégico en las áreas de tecnología, marketing y finanzas. Además, net 24|7 cuenta con el apoyo de empresas y partners expertas en tecnología y medios de pago, con recursos específicos en todas las áreas involucradas en los procesos transaccionales.",
  },

  section3: {
    text1:
      "A diferencia de las tecnologías bancarias tradicionales, Wao ha sido diseñado para facilitar la creación de soluciones financieras, inclusivas, eficientes y empoderadoras a través de la tecnología digital y los datos.",
  },

  section6: {
    text1: "¿Cómo nos conectamos con",
    text2:
      "La tecnología y la globalización están redefiniendo nuestro mundo, ahora las finanzas y los mercados no conocen fronteras.",
    text3:
      " En esta nueva realidad, Wao está disponible en cualquier parte del mundo donde un usuario acceda a nuestros servicios digitales.",
    text4: "clientes?",
    text5: "nuestros ",
  },

  section8: {
    text1: "Licencia",
    text2:
      "Services 24-7 LLC es un proveedor de servicios de pago registrado en el Departamento del Tesoro de los EE. UU. (FinCEN) como MSB (Money Service Business) y proveedor de acceso prepago. Estamos autorizados a transmitir dinero en los estados y territorios de EE. UU. donde se nos haya emitido una licencia o mediante las licencias de nuestros socios/proveedores en los otros estados.",
    text3: "Los MSB están regulados por FinCEN (Agencia de Ejecución de Delitos Financieros que forma parte del Departamento del Tesoro de los EE. UU.). Los objetos principales son la supervisión de negocios y empresas que prestan servicios de dinero. El alcance incluye remesas internacionales, cambio de moneda extranjera, transacción/transferencia de moneda (incluida moneda digital/moneda virtual), suministro de artículos prepagos, emisión de cheques de viajero y otros servicios. Las empresas dedicadas a los negocios relacionados antes mencionados deben registrarse antes de que puedan operar legalmente.",
    text4: "Ver Licencia"
    },
    section9 : {
      text1: "Disclaimer",
      text2: "El propósito de la Ley PATRIOTA de EE. UU. es disuadir y castigar los actos terroristas en los Estados Unidos y en todo el mundo, mejorar las herramientas de investigación de las fuerzas del orden público y otros propósitos, algunos de los cuales incluyen:",
    text3: "Fortalecer las medidas estadounidenses para prevenir, detectar y enjuiciar el lavado de dinero internacional y el financiamiento del terrorismo.",
    text4: "Someter a un escrutinio especial a jurisdicciones extranjeras, instituciones financieras extranjeras y clases de transacciones internacionales o tipos de cuentas que sean susceptibles de abuso criminal.",
    text5: "Requerir que todos los elementos apropiados de la industria de servicios financieros reporten posibles lavados de dinero.",
    text6: "Fortalecer las medidas para prevenir el uso del sistema financiero de los EE. UU. para beneficio personal por parte de funcionarios extranjeros corruptos y facilitar la repatriación de activos robados a los ciudadanos de los países a los que pertenecen dichos activos.",
    text7: "Ver disclaimer"
    },
    section10: {
      text1: "Términos",
      text2: "y condiciones",
      text3: "Condiciones de Uso",
      text4: "Este sitio web es propiedad de SERVICE 24-7 LLC (net 24|7) o es operado por una subsidiaria de net 24|7, y tiene como objetivo proporcionar al USUARIO información general sobre los productos, servicios y acceso a la suscripción de los mismos. El Uso de estos productos y servicios está sujeto al cumplimiento por parte del USUARIO de los términos y condiciones aquí contenidos. Lea atentamente estos términos de uso antes de comenzar a utilizar el sitio web. Al utilizar este Sitio Web, el USUARIO indica que acepta estos términos de uso y que se compromete a cumplirlos. Si no está de acuerdo con estos términos de uso, no use ningún sitio web propiedad de net 24|7.",
      text5: "Uso del Sitio Web",
      text6: "El acceso al Sitio Web está permitido de forma temporal y nos reservamos el derecho de retirar o modificar el servicio que ofrecemos en el sitio web sin previo aviso. En consecuencia, net 24|7 no será responsable si, por cualquier motivo, el Sitio Web no está disponible en algún momento o durante algún período. net 24|7 actualiza con frecuencia el contenido sin previo aviso; sin embargo, la información digital publicada en el Sitio Web puede estar desactualizada en un momento dado, en este sentido net 24|7 no está obligado a actualizar dicho material. EL USUARIO es responsable de todos los esfuerzos previos necesarios para acceder al Sitio Web. Cualquier información enviada por el USUARIO y recibida por net 24|7, como comentarios, sugerencias o ideas, se considerará cedida a net 24|7 a título gratuito, y el USUARIO que la envía acepta implícitamente que no tiene carácter confidencial. Le sugerimos que NO ENVÍE ninguna información que pueda ser tratada de esta manera. net 24|7 tendrá derecho a utilizar cualquier información enviada para los fines que considere apropiados. net 24|7 puede revisar estos Términos de uso en cualquier momento modificando esta página. Se espera que revise esta página de vez en cuando para notar cualquier cambio que hagamos, ya que son vinculantes para usted. Algunas de las disposiciones contenidas en estos Términos de uso también pueden ser reemplazadas por disposiciones o avisos publicados en otras partes de nuestro sitio web.",
      text7: "net 24|7 Responsabilidad",
      text8: "El contenido que se muestra en el sitio web se proporciona sin garantías ni condiciones en cuanto a su precisión. net 24|7 declara expresamente que todo el material publicado, incluidos los comentarios, no pretende ser una ciencia exacta sobre los temas allí informados. Por lo tanto, net 24|7 se exime de toda responsabilidad que surja de la confianza depositada en dicho contenido por parte de cualquier visitante del sitio web, o por cualquier persona que pueda estar informada de tales publicaciones. net 24|7 no es responsable de los enlaces a otros sitios y recursos proporcionados por terceros, estos enlaces se proporcionan únicamente para su información. net 24|7 no tiene control sobre el contenido de esos sitios o recursos, y no acepta ninguna responsabilidad por ellos o por cualquier pérdida o daño que pueda surgir del uso que usted haga de ellos.",
      text9: "Virus, Piratería y otros Delitos",
      text10: "El USUARIO no debe hacer un mal uso del Sitio Web introduciendo a sabiendas virus, troyanos, gusanos, bombas lógicas u otro material malicioso o tecnológicamente dañino. Además de intentar obtener acceso no autorizado al sitio web, el servidor en el que se almacena el sitio web o cualquier servidor, computadora o base de datos conectada al sitio web net 24|7. No debe bordar el sitio web mediante un ataque de denegación de servicio o un ataque de denegación de servicio distribuido (DDoS). El incumplimiento de esta disposición, el USUARIO incurriría en un delito tipificado en la Ley de Uso de Informática de 1990, por lo que será informado a las autoridades competentes y cesará de inmediato en su derecho a utilizar el Sitio Web. net 24|7 no es responsable de ninguna pérdida o daño causado por un ataque distribuido de denegación de servicio (DDoS), virus o cualquier otro material tecnológicamente dañino que pueda infectar su equipo informático, software, datos u otro material patentado debido a su uso de nuestro sitio web o su descarga de cualquier material publicado en él, o en cualquier sitio web vinculado a él.",
      text11: "Condiciones de Privacidad",
      text12: "net 24|7 ha implementado las medidas de seguridad adecuadas para evitar que sus datos personales se pierdan, utilicen, accedan, modifiquen o divulguen accidentalmente de forma no autorizada. Como parte de estas medidas de seguridad, limitamos el acceso a sus datos personales solo a aquellos empleados, agentes, contratistas y otros terceros que tengan una necesidad comercial de conocer. Solo procesarán sus datos personales de acuerdo con nuestras instrucciones y están sujetos a un deber de confidencialidad. net 24|7, con el fin de mejorar la experiencia del USUARIO, utiliza cookies en el sitio web, para diferenciarlo de otros usuarios. Tiene como finalidad permitir que el servidor web recuerde algunos datos relativos al USUARIO, tales como sus preferencias para la visualización de los productos, servicios que más le interesan, entre otros aspectos.",
      text13: "Derechos de Propiedad Intelectual",
      text14: "net 24|7 posee todos los derechos de autor y propiedad intelectual del Sitio web, el material publicado en él y cualquier tecnología relacionada con el Sitio web. Ningún título o propiedad de cualquiera de los anteriores se otorga o transfiere al USUARIO a través del uso del Sitio Web.",
      text15: "Jurisdicción y Ley Aplicable",
      text16: "Estos términos de uso y cualquier disputa o reclamo que surja de o en relación con ellos o su tema o formación (incluidas las disputas o reclamos no contractuales) se regirán e interpretarán de acuerdo con las leyes del Estado de Florida de los Estados Unidos. Estados de América. Todos los USUARIOS que ingresen a este Sitio Web, y/oa cualquiera de las publicaciones digitales de net 24|7, aceptan someterse a esta ley.",

    },
    whatsapp: {
      text1: "¿Necesitas ayuda? habla con nosotros"
    },
    copy: {
      text1: "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
      text2: "Cookies de privacidad"
    }
};

export default En;
