import React from 'react'
import SurveyM from '../Moleculas/surveyM'

const SurveyO = () => {
  return (
    <div>
        
               <SurveyM/>
    </div>
  )
}

export default SurveyO