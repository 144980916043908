import React from "react";
import "./buttonVideo.scss";

const ButtonVideo = ({ color, link, icon, img, children }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={`container-buttonV ${color ? color : ""}`}
    >
      <img className="display" src={img} alt="video" />
      {children}
      <div className={`ic-play ${color ? color : ""}`}>
        {
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 5.26795C12.3333 6.03775 12.3333 7.96225 11 8.73205L3.5 13.0622C2.16666 13.832 0.499999 12.8697 0.499999 11.3301L0.5 2.66987C0.5 1.13027 2.16667 0.168021 3.5 0.937822L11 5.26795Z"
              fill="white"
            />
          </svg>
        }
      </div>
    </a>
  );
};

export default ButtonVideo;
