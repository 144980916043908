import React, { useState, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Es from "./lang/Es.js";
import En from "./lang/En.js";
import { LanguageContext } from "Context";
import { ButtonBubble } from "components/index.js";
import InputText from "./Inputs/inputs.jsx";
import Text from "../../../components/Text/Text";
import "./surveyA.scss";
import Selectdos, {
  Selectcinco,
  Selectcuatro,
  Selectseis,
  Selecttres,
} from "./Inputs/inputsSelect.jsx";

const SurveyA = (props) => {
  let history = useHistory();
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  const [empresa, setEmpresa] = useState("");
  const [sugerencia, setSugerencia] = useState("");
  const [preguntados, setPreguntados] = useState({ value: "" });
  const [preguntatres, setPreguntatres] = useState({ value: "" });
  const [preguntacuatro, setPreguntacuatro] = useState({ value: "" });
  const [preguntacinco, setPreguntacinco] = useState({ value: "" });
  const [preguntaseis, setPreguntaseis] = useState({ value: "" });

  const [error, setError] = useState({
    empresa: "",
    preguntados: "",
    preguntatres: "",
    preguntacuatro:"",
    preguntacinco:"",
    preguntaseis:"",
    sugerencia: ""
  });
  //LENGUAGE

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(En);
    } else {
      setLangChoosed(Es);
    }
  }, [lang]);

  //HANDLERS
  const handlerEmpresa = (e) => {
    e.target && setEmpresa(e.target.value);
  };
  const handlerSugerencia = (e) => {
    e.target && setSugerencia(e.target.value);
  };
  const handlerPreguntados = (e) => {
    setPreguntados(e.target.value);

  };
  const handlerPreguntatres = (e) => {
    setPreguntatres(e.target.value);
 
  };
  const handlerPreguntacuatro = (e) => {
    setPreguntacuatro(e.target.value);

  };
  const handlerPreguntacinco = (e) => {
    setPreguntacinco(e.target.value);

  };
  const handlerPreguntaseis = (e) => {
    setPreguntaseis(e.target.value);

  };

  //VALIDACIÓN
  const validation = () => {
    let pass = true;
    let AllErrors = { ...error };

    console.log(AllErrors);
    //PREGUNTA 1
    if (!empresa) {
      AllErrors = { ...AllErrors, empresa: langChoosed?.Error?.error1 };
      pass = false;
    } else if (empresa.length > 255) {
      AllErrors = {
        ...AllErrors,
        empresa: `${langChoosed?.Error?.error3}`,
      };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, empresa: "" };
    }
    //PREGUNTA 7
    if (!sugerencia) {
      AllErrors = { ...AllErrors, sugerencia: langChoosed?.Error?.error1 };
      pass = false;
    } else if (sugerencia.length > 255) {
      AllErrors = {
        ...AllErrors,
        sugerencia: `${langChoosed?.Error?.error3}`,
      };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, sugerencia: "" };
    }
    //PREGUNTA 2
    if (preguntados === "") {
      AllErrors = { ...AllErrors, preguntados: langChoosed?.Error?.error2 };
      pass = false;
    } else if (preguntados.value === "") {
      AllErrors = { ...AllErrors, preguntados: langChoosed?.Error?.error2 };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, preguntados: "" };
    }

    //PREGUNTA 3
    if (preguntatres === "") {
      AllErrors = { ...AllErrors, preguntatres: langChoosed?.Error?.error2 };
      pass = false;
    } else if (preguntatres.value === "") {
      AllErrors = { ...AllErrors, preguntatres: langChoosed?.Error?.error2 };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, preguntatres: "" };
    }
    //PREGUNTA 4
    if (preguntacuatro === "") {
      AllErrors = { ...AllErrors, preguntacuatro: langChoosed?.Error?.error2 };
      pass = false;
    } else if (preguntacinco.value === "") {
      AllErrors = { ...AllErrors, preguntacuatro: langChoosed?.Error?.error2 };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, preguntacuatro: "" };
    }
    //PREGUNTA 5
    if (preguntacinco === "") {
      AllErrors = { ...AllErrors, preguntacinco: langChoosed?.Error?.error2 };
      pass = false;
    } else if (preguntacinco.value === "") {
      AllErrors = { ...AllErrors, preguntacinco: langChoosed?.Error?.error2 };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, preguntacinco: "" };
    }
    //PREGUNTA 6
    if (preguntaseis === "") {
      AllErrors = { ...AllErrors, preguntaseis: langChoosed?.Error?.error2 };
      pass = false;
    } else if (preguntaseis.value === "") {
      AllErrors = { ...AllErrors, preguntaseis: langChoosed?.Error?.error2 };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, preguntaseis: "" };
    }

    console.log(AllErrors);
    setError(AllErrors);
    return pass;
  };
  //INTEGRACIÓN

  const data = {
    nameCompany: empresa,
    knowWao: preguntados,
    digitalAccountProcessQualification: preguntatres,
    recommended: preguntacuatro,
    information: preguntacinco,
    qualificatioCommunicationChannels: preguntaseis,
    suggestion: sugerencia,
    language: lang,
  };

  const reset = () => {
   setEmpresa("")
  };


  const sendSurvey = (e) => {
    if (validation()) {
      //RUTA DE QA
        //http://test.wao.net/api/user/SaveSurveyAnswer
      //RUTA DE PRODUCCIÓN
        //https://wao.net/api/user/SaveSurveyAnswer
      fetch(`https://wao.net/api/user/SaveSurveyAnswer`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw text;
          });
        })
        .then((res) => {
          // toast.success(res.menssage) ;
          toast.success(langChoosed?.Message?.success) ;
          reset();
     
        })

        .catch((err) => {
          const errData = JSON.parse(err);
          if (errData?.menssage) {
            toast.error(errData?.menssage);
          } else {
            toast.error("error en el servidor");
          }
        });


    }
  };

  React.useEffect(() => {
   setEmpresa()
  }, [])


  return (
    <div>
      <div className="form-encuesta">
        {/*TÍTULO Y SUBTÍTULO */}
        <div className="text-encuesta">
          <text className="title"> {langChoosed?.Text?.text1}</text>
          <text className="subtitle"> {langChoosed?.Text?.text2}</text>
        </div>

        {/*FORMULARIO */}
        <div className="div-input">
          {/*Pregunta 1*/}
          <InputText
            name="empresa"
            id="empresa"
            type="text"
            label={langChoosed?.Preguntas?.pregunta1}
            onChange={handlerEmpresa}
            error={error.empresa}
            required
          />
          {/*Pregunta 2*/}
          <label htmlFor="" className="label-input">
            {langChoosed?.Preguntas?.pregunta2}
          </label>

          <Selectdos
            name="preguntados"
            id="preguntados"
            error={error.preguntados}
            onChange={handlerPreguntados}
          />

          {/*Pregunta 3*/}
          <label htmlFor="" className="label-input">
            {langChoosed?.Preguntas?.pregunta3}
          </label>

          <Selecttres
            name="preguntatres"
            id="preguntatres"
            error={error.preguntatres}
            onChange={handlerPreguntatres}
          />

          {/*Pregunta 4*/}
          <label htmlFor="" className="label-input">
            {langChoosed?.Preguntas?.pregunta4}
          </label>
          <Selectcuatro
            name="preguntacuatro"
            id="preguntados"
            error={error.preguntacuatro}
            onChange={handlerPreguntacuatro}
          />
          {/*Pregunta 5*/}
          <label htmlFor="" className="label-input">
            {langChoosed?.Preguntas?.pregunta5}
          </label>
          <Selectcinco
            name="preguntacinco"
            id="preguntacinco"
            error={error.preguntacinco}
            onChange={handlerPreguntacinco}
          />
          {/*Pregunta 6*/}
          <label htmlFor="" className="label-input">
            {langChoosed?.Preguntas?.pregunta6}
          </label>
          <Selectseis
            name="preguntaseis"
            id="preguntaseis"
            error={error.preguntaseis}
            onChange={handlerPreguntaseis}
          />
          {/*Pregunta 7*/}
          <InputText
            name="sugerencia"
            id="sugerencia"
            type="text"
            label={langChoosed?.Preguntas?.pregunta7}
            onChange={(e) => handlerSugerencia(e)}
            error={error.sugerencia}
            required
          />
        </div>
        {/*BOTÓN */}
        <div className="boton-encuesta">
          {/*Botón*/}
          <ButtonBubble
            // disabled={isLoading}
            type="submit"
            onClick={sendSurvey}
            className="large"
          >
            {langChoosed.Button?.button1}
          </ButtonBubble>
        </div>
        <ToastContainer
          className="toast-person"

          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          onClose= {() => history.push("/")}
  
        />
      </div>
 
    </div>
  );
};

export default withRouter(SurveyA);
