import React, { useState } from "react";
import PropTypes from "prop-types";
import Es from "../lang/Es.js";
import En from "../lang/En.js";
import Text from "../../../../components/Text/Text";
import { LanguageContext } from "Context";
import InputText from "./inputs.jsx";
import { style } from "@mui/system";

//PREGUNTA 2
const Selectdos = (props) => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(En);
    } else {
      setLangChoosed(Es);
    }
  }, [lang]);
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        placeholder={langChoosed?.Opciones?.opcion}
        className={`select-encuesta ${props.error ? "errorselect" : ""}`}
        borderRadius="30px"
        onChange={props.onChange}
        required
      >
        <option value="" selected>
          {langChoosed?.Opciones?.opcion}
        </option>

        <option value={langChoosed?.Opciones?.opcion1P2}>
          {langChoosed?.Opciones?.opcion1P2}
        </option>
        <option value={langChoosed?.Opciones?.opcion2P2}>
          {langChoosed?.Opciones?.opcion2P2}
        </option>
        <option value={langChoosed?.Opciones?.opcion3P2}>
          {langChoosed?.Opciones?.opcion3P2}
        </option>
        <option value={langChoosed?.Opciones?.opcion4P2}>
          {langChoosed?.Opciones?.opcion4P2}
        </option>
        <option value={langChoosed?.Opciones?.opcion5P2}>
          {langChoosed?.Opciones?.opcion5P2}
        </option>
        <option value={langChoosed?.Opciones?.opcion6P2}>
          {langChoosed?.Opciones?.opcion6P2}
        </option>
      </select>
      <div className="error-encuesta-msg">
        {props.error && <Text category="error">{props.error}</Text>}
      </div>
    </>
  );
};

Selectdos.propTypes = {};

export default Selectdos;

//PREGUNTA 3
export const Selecttres = (props) => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  const [value, setValue] = useState("");
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(En);
    } else {
      setLangChoosed(Es);
    }
  }, [lang]);

  const Inco = () => {
    if (value === "6") {
        <InputText
        name="inconveniente"
        id="inconveniente"
        value="inco"
        type="text"
        required
      />
    } else {
      <InputText className={(style = "display:none")} />;
    }
  };
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        placeholder={langChoosed?.Opciones?.opcion}
        className={`select-encuesta ${props.error ? "errorselect" : ""}`}
        borderRadius="30px"
        onChange={props.onChange}
        required

      >
        <option value="" selected>
          {langChoosed?.Opciones?.opcion}
        </option>
        <option value={langChoosed?.Opciones?.opcion1P3}>
          {langChoosed?.Opciones?.opcion1P3}
        </option>
        <option value={langChoosed?.Opciones?.opcion2P3}>
          {langChoosed?.Opciones?.opcion2P3}
        </option>
        <option value={langChoosed?.Opciones?.opcion3P3}>
          {langChoosed?.Opciones?.opcion3P3}
        </option>
        <option value={langChoosed?.Opciones?.opcion4P3}>
          {langChoosed?.Opciones?.opcion4P3}
        </option>
        {/* <option value="6">{langChoosed?.Opciones?.opcion5P3}</option> */}
      </select>
      <div className="error-encuesta-msg">
        {props.error && <Text category="error">{props.error}</Text>}
      </div>
   
    </>
  );
};

//PREGUNTA 4
export const Selectcuatro = (props) => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(En);
    } else {
      setLangChoosed(Es);
    }
  }, [lang]);
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        placeholder={langChoosed?.Opciones?.opcion}
        className={`select-encuesta ${props.error ? "errorselect" : ""}`}
        borderRadius="30px"
        onChange={props.onChange}
        required
      >
        <option value="1">{langChoosed?.Opciones?.opcion}</option>
        <option value={langChoosed?.Opciones?.opcion1P4}>
          {langChoosed?.Opciones?.opcion1P4}
        </option>
        <option value={langChoosed?.Opciones?.opcion2P4}>
          {langChoosed?.Opciones?.opcion2P4}
        </option>
      </select>
      <div className="error-encuesta-msg">
        {props.error && <Text category="error">{props.error}</Text>}
      </div>
    </>
  );
};
//PREGUNTA 5
export const Selectcinco = (props) => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(En);
    } else {
      setLangChoosed(Es);
    }
  }, [lang]);
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        placeholder={langChoosed?.Opciones?.opcion}
        className={`select-encuesta ${props.error ? "errorselect" : ""}`}
        borderRadius="30px"
        onChange={props.onChange}
        required
      >
        <option value="1">{langChoosed?.Opciones?.opcion}</option>
        <option value={langChoosed?.Opciones?.opcion1P5}>
          {langChoosed?.Opciones?.opcion1P5}
        </option>
        <option value={langChoosed?.Opciones?.opcion2P5}>
          {langChoosed?.Opciones?.opcion2P5}
        </option>
      </select>
      <div className="error-encuesta-msg">
        {props.error && <Text category="error">{props.error}</Text>}
      </div>
    </>
  );
};
//PREGUNTA 6
export const Selectseis = (props) => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(En);
    } else {
      setLangChoosed(Es);
    }
  }, [lang]);
  return (
    <>
      <select
        name={props.name}
        id={props.id}
        placeholder={langChoosed?.Opciones?.opcion}
        className={`select-encuesta ${props.error ? "errorselect" : ""}`}
        borderRadius="30px"
        onChange={props.onChange}
        required
      >
        <option value="1">{langChoosed?.Opciones?.opcion}</option>
        <option value={langChoosed?.Opciones?.opcion1P6}>
          {langChoosed?.Opciones?.opcion1P6}
        </option>
        <option value={langChoosed?.Opciones?.opcion2P6}>
          {langChoosed?.Opciones?.opcion2P6}
        </option>
        <option value={langChoosed?.Opciones?.opcion3P6}>
          {langChoosed?.Opciones?.opcion3P6}
        </option>
      </select>
      <div className="error-encuesta-msg">
        {props.error && <Text category="error">{props.error}</Text>}
      </div>
    </>
  );
};
Selectdos.propTypes = {};
