const Es = {
  whatsapp: {
    text1: "¿Necesitas ayuda? habla con nosotros"
  },
  footer: {
    text1: "LEGAL",
    text2: "CONTACTO",
    text3: "¿NECESITAS AYUDA?",
    text4: "Licencia",
    text5: "Términos y Condiciones",
    text6: "Disclaimer",
    text7: "Política de privacidad",
    text8: "FAQ Wao",
    text9: "Todos los derechos reservados, Servicios 24/7 LLC",
   
  },
  section1: {
    text1: "Cuenta Digital sin Fronteras",
    text2: "Puedes solicitarla ¡AHORA!",
    text3: `Una cuenta en los Estados Unidos`,
    text4: "Disponible para personas y empresas",
    text5: "En dólares, protege tu dinero",
    text7: "Personal",
    text8: "Empresa",

  },

  section2: {
    text1: "¿Qué es Wao?",
    text2: "Es toda una plataforma financiera.",
    text3:
      "Wao te ofrece una cuenta digital en dólares bajo las leyes y regulación de los Estados Unidos.",
    text4: "Es una cuenta financiera que puede ser utilizada por personas y empresas como una real alternativa a la banca tradicional.",
  },

  section3: {
    text1: "¿Wao es como una cuenta bancaria regular?",
    text2: "¡Es mucho más que eso!",
    text3: "Utiliza tu dinero con la flexibilidad que las nuevas tecnologías pueden ofrecer, sin las restricciones de una cuenta bancaria regular.",
    text4: "El objetivo de Wao es poder adaptarse a tantos usos como sea posible, de una manera segura, rápida y sencilla.",
  },

  section4: {
    text1: "¿Wao es solo una tarjeta de débito?",
    text2: "WaoCard es dinero en tus manos.",
    text3: "La cuenta digital te permite tener una tarjeta de débito para pagos físicos o virtual.",
    text5:"Pagos en comercios",
    text6:"Compras en internet",
    text7:"Pagos recurrentes",
    text8:"Servicios de suscripción",
    text9:"Pagos donde y cuando quieras."
    
  },

  section5: {
    text1: "¡Wao, es todo eso y mucho más!",
    text2: "Una nueva generación de servicios financieros.",
    text3: "Descubre todo lo que puedes hacer con Wao, la plataforma digital que permite a personas y empresas satisfacer todas sus necesidades financieras en Dólares con la seguridad regulatoria de los Estados Unidos.",

  },

  section6: {
    text1: "¡Wao, es todo eso y mucho más!",
    text2: "Una nueva generación de servicios financieros.",
    text3:
      "Descubre todo lo que puedes hacer con wao, una plataforma digital que empodera a las empresas y personas.",
  },

  section7: {
    text1: "Disfruta de una nueva experiencia digital",
    text2: "Regístrate",
    text3: "ÚNETE A LA LISTA DE ESPERA",
  },
  section7P: {
    text1: "Disfruta de una nueva experiencia digital",
    text2: "Regístrate",
    text3: "ÚNETE A LA LISTA DE ESPERA",
  },

  modal:{
    title:['¡Gracias por tu interés en los productos Wao!'],
    subTitle:['Actualmente, WaoOne (personas) no está disponible para tu país.'],
    paragraphs:["No obstante, regístrate para ser de los primeros en disfrutar de una cuenta digital sin fronteras."],
    buttons:['Regístrate']
  },
  modalE:{
    title:['¡Gracias por su interés en los productos Wao!'],
    subTitle:['Actualmente, nuestro producto para empresas, "WaoBiz", no está disponible para su país.'],
    paragraphs:["No obstante, regístrese para ser de los primeros en disfrutar de una cuenta digital sin fronteras."],
    buttons:['Regístrese']
  },

  form: {
    placeholderName: "Ingresa tu nombre",
    placeholderEmail: "Ingresa tu correo",
    placeholderCountry: "País de residencia",
    placeholderNameE: "Nombre de su empresa",
    placeholderReE: "Nombre del representante legal",
    placeholderEmailE: "Correo",
    placeholderCountryE: "País de jurisdicción",
    errorRequired: "Campo requerido",
    errorMaxLength: "Max. caracteres ",
    errorInvalidEmail: "Correo no válido  ",

    toastPending: "Espere por favor...",
    toastSuccessful: "Mensaje enviado exitosamente",
    toastError: "Algo salio mal",
  },
  mastercard: {
    text1: "Mastercard® WaoCard is issued by Patriot Bank®, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard® WaoCard may be used everywhere Debit Mastercard is accepted.",
    text2: "Not all WAO® products and services are available in all geographic locations. Your eligibility for particular products or services is subject to final determination by Services 24-7 LLC or its partners.",
    text3: "Mastercard and the Mastercard Brand Mark are registered trademarks of Mastercard International Incorporated.",
    text4: "Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc. Google Play and the Google Play logo are trademarks of Google LLC.",
    text5: "Zelle® and the Zelle® related products are registered trademarks of Early Warning Services, LLC."
   }
};

export default Es;
