import React, { useEffect } from "react";

export const LanguageContext = React.createContext();

export const LanguageProvider = (props) => {
  const [lang, setLang] = React.useState("Es");
  useEffect(() => {
  const lenguages = navigator.languages
    for (let x = 0; x < lenguages.length; x++) {
      if (lenguages[x].includes('es')) {
        setLang("Es");
      }
    }
    
  }, []);

  return (
    <LanguageContext.Provider value={[lang, setLang]}>
      {props.children}
    </LanguageContext.Provider>
  );
};
