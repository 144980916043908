import React from "react";
import { Footer2 } from "components";
import "../legal.scss";
import Privacy3 from "../../../assets/legal/terms.webp";
import { LanguageContext } from "Context";
import EnLang from "../Lang/En";
import EsLang from "../Lang/Es";
import ReactGA from "react-ga";

ReactGA.initialize("UA-236552941-1");

const License = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  window.scrollTo(0,0);
  return (
    <>
      <div className="content-legal">
        <img src={Privacy3} className="img-header" alt="terms"></img>
        <div className="text-inimage">Legal Aspects</div>
        <div className="text-inimage2 terms">{langChoosed?.terms?.text2}</div>
        <div className="content-legal22">
          <strong>TERMS OF USE</strong>
        </div>
        <p className="content-legal22">
          This website is owned by SERVICE 24-7 LLC (net24|7) or operated by a
          subsidiary of net24|7, and is intended to provide the USER with
          general information about the products, services and access to the
          subscription to the same. The Use of these products and services is
          subject to compliance by the USER of the terms and conditions
          contained herein. Please read these terms of use carefully before you
          start using the Web Site. By using this Web Site, the USER indicates
          that they accept these terms of use and that they agree to abide by
          them. If you do not agree with these terms of use, do not use any Web
          Site owned by net24|7.
        </p>
        <br></br>
        <div className="content-legal22">
          <strong>USE OF THE WEBSITE</strong>
        </div>

        <p className="content-legal22">
          Access to the Web Site is permitted on a temporary basis, and we
          reserve the right to withdraw or modify the service we provide on the
          website without any notice. Accordingly, net24|7 will not be liable if
          for any reason the Web Site is unavailable at any time or for any
          period. net24|7 frequently updates the content without prior notice;
          however, the digital information published on the Website may be out
          of date at any given time, in this sense, net24|7 is not required to
          update such material. THE USER is responsible for all previous efforts
          necessary to access the Website. Any information submitted by the USER
          and received by net 24|7, such as comments, suggestions or ideas, will
          be considered transferred to net24|7 free of charge, and the USER who
          sends it implicitly accepts that it is not confidential. We suggest
          that you DO NOT SEND any information that can be treated in this way.
          net24|7 shall have the right to use any information submitted for any
          purposes it deems appropriate. net24|7 may review these Terms of Use
          at any time by modifying this page. You are expected to review this
          page from time to time to notice any changes we make as they are
          binding on you. Some of the provisions contained in these Terms of Use
          may also be superseded by provisions or notices posted elsewhere on
          our website.
        </p>
        <br></br>
        <div className="content-legal22">
          <strong>NET 24|7 LIABILITY</strong>
        </div>

        <p className="content-legal22">
          Content displayed on the Web Site is provided without any warranties
          or conditions as to its accuracy. net24|7 expressly states that all
          published material, including comments, is not intended to be an exact
          science on the topics there reported. Therefore, net24|7 disclaims all
          liability arising from the reliance placed on such content by any
          visitor to the Web Site, or by any person who may be informed of such
          postings. net24|7 is not responsible for links to other sites and
          resources provided by third parties, these links are provided only for
          your information. net24|7 has no control over the content of those
          sites or resources, and accepts no responsibility for them or for any
          loss or damage that may arise from your use of them.
        </p>
        <br></br>
        <div className="content-legal22">
          <strong>VIRUSES, PIRACY AND OTHER CRIMES</strong>
        </div>

        <p className="content-legal22">
          The USER must not misuse the Web Site by knowingly introducing
          viruses, Trojans, worms, logic bombs or other malicious or
          technologically harmful material. As well as attempting to gain
          unauthorized access to the Web Site, the server on which the Website
          is stored, or any server, computer or database connected to the
          net24|7 website. You should not embroider the website by using a
          denial-of-service attack or a distributed denial-of-service attack
          (DDoS). By breaching this provision, the USER would commit a criminal
          offence under the Law on the Use of Computers of 1990, which will be
          informed to the competent authorities and their right to use the
          Website will cease immediately. net24|7 is not responsible for any
          loss or damage caused by a distributed denial of service attack
          (DDoS), viruses or any other technologically harmful material that may
          infect your computer equipment, software, data or other proprietary
          material due to your use of our website or your download of any
          material posted on it, or on any website linked to it.
        </p>
        <br></br>
        <div className="content-legal22">
          <strong>PRIVACY CONDITIONS</strong>
        </div>

        <p className="content-legal22">
          Net 24|7 has implemented appropriate security measures to prevent your
          personal data from being accidentally lost, used, accessed, altered or
          disclosed in an unauthorized manner. As part of this security
          measures, we limit access to your personal data only to those
          employees, agents, contractors and other third parties who have a
          business need to be aware of. They will only process your personal
          data according to our instructions and are subject to a duty of
          confidentiality. net24|7, in order to improve the USER experience,
          uses cookies on the website, to differentiate them among other users.
          Its purpose is to allow the web server to remember some data
          concerning the USER, such as their preferences for the visualization
          of the products, services that interest them the most, among other
          aspects.
        </p>
        <br></br>
        <div className="content-legal22">
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
        </div>

        <p className="content-legal22">
          Net 24|7 owns all copyright and intellectual property rights in the
          Website, the material published on it and any technology related to
          the Website. No title or ownership of any of the above is granted or
          transferred to the USER through the use of the Website.
        </p>
        <br></br>
        <div className="content-legal22">
          <strong>JURISDICTION AND APPLICABLE LAW</strong>
        </div>

        <p className="content-legal22">
          These terms of use and any disputes or claims arising out of or in
          connection with them or their subject or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the laws of the State of Florida of the United
          States of America. All USERS who enter this Website, and/or any of
          net24|7 digital publications, agree to be bound by this law.
          <br></br>
          <br></br>
          In accordance with the provisions of this document, the customer
          declares to know and accept the Terms and Conditions of this Legal
          Notice.
          <br></br>
          <br></br>
          The information in this document is considered confidential between
          the parties with Copyright 2020 net24|7.
        </p>
        <br></br>
        <br></br>
        <div className="content-legal22">
          <strong>OTHER LEGAL DOCUMENTS</strong>
        </div>
        <a href="/disclaimer">
          {" "}
          <text className="content-legal22">Disclaimer</text>
        </a>
      </div>

      <Footer2 position={"relative"} />
    </>
  );
};

export default License;
