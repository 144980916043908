const Es = {
  section1: {
    text1: "Send and receive money anywhere in the world",
    text2: "With your relatives and friends.",
    text3: "Accept payments, send transfers and manage your finances in one place. It's the fastest way to make your day-to-day personal transactions.",
    text4: "We want to offer you a new way to move your money",
    text5: "Start Now",
  },

  section2: {
    text1: "It is a digital account aimed at any individual who needs a financial platform in USD with the safety of the USA regulations.",
    text2: "You can send and receive money from family, friends, and loved ones quickly and safely from almost anywhere in the world through a single mobile application.",
    video: "SEE VIDEO",
  },

  section3: {
    text1:
      "Connect with your loved ones and manage your Wao account through a mobile application that offers you: ",
    text2: "100% digital affiliation",
    text3: "Manage your transfers and your card",
    text4: "Custom security settings",
    text5: "Visibility of your transactions in real-time",
  },

  section4: {
    text1:
      "Manage any Wao account through your card, which offers you the freedom to make purchases in stores or online from anywhere in the world.",
    text2:"Request your physical or virtual card immediately after opening your account.",
    text3:"Payments where and when you want",

  },

  section5: {
    text1:"Make instant payments with Waolink.",
    text2: "Create a payment link exclusively to your relatives or friends, then send it via email, SMS, chat, or social networks, and that's it.",
    text3:"A service that is suitable to your needs and your loved ones.",
    text4: "Receive your payment in seconds!"
  },

  section7: {
    text1: "What is the goal of Wao?",
    text2:"Seek to become your banking alternative to all our customers to release them from:",
    text3: "Bureaucratic processes",
    text4: "Endless requirements",
    text5: "Contracts and penalties",
    text6: "Operating restrictions",
  },
  section: {
    text1: "",
    text2: "",
    text3: "",
    text4: "",
    text5: "",
    text6: "",
    text7: "",
  },
  whatsapp: {
    text1: "¿Need Help? Chat whith us"
  },
  copy: {
    text1: "This website uses cookies to enhance the user experience.",
    text2: "Privacy Cookie"
  }
};

export default Es;
