import React, { memo } from "react";

const DolarIcon = memo(() => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99935 0.666016C4.39935 0.666016 0.666016 4.39935 0.666016 8.99935C0.666016 13.5993 4.39935 17.3327 8.99935 17.3327C13.5993 17.3327 17.3327 13.5993 17.3327 8.99935C17.3327 4.39935 13.5993 0.666016 8.99935 0.666016ZM8.99935 15.666C5.32435 15.666 2.33268 12.6743 2.33268 8.99935C2.33268 5.32435 5.32435 2.33268 8.99935 2.33268C12.6743 2.33268 15.666 5.32435 15.666 8.99935C15.666 12.6743 12.6743 15.666 8.99935 15.666ZM9.25768 8.28268C7.78268 7.90768 7.30768 7.49935 7.30768 6.89102C7.30768 6.19102 7.96602 5.69935 9.05768 5.69935C10.2077 5.69935 10.641 6.24935 10.6743 7.06602H12.0993C12.0577 5.94935 11.3743 4.92435 10.0243 4.59102V3.16602H8.08268V4.57435C6.82435 4.84102 5.81602 5.65768 5.81602 6.91602C5.81602 8.40768 7.05768 9.15768 8.86602 9.59102C10.491 9.97435 10.816 10.5493 10.816 11.1493C10.816 11.591 10.491 12.3077 9.06602 12.3077C7.73268 12.3077 7.20768 11.7077 7.13268 10.941H5.69935C5.78268 12.3577 6.83268 13.1577 8.08268 13.416V14.8327H10.0327V13.441C11.2993 13.1993 12.2993 12.4743 12.3077 11.1327C12.2993 9.29935 10.7243 8.66602 9.25768 8.28268Z"
        fill="#2B333F"
      />
    </svg>
  );
});
DolarIcon.displayName = "DolarIcon";
export default DolarIcon;
