import Location from "../../../assets/icons/location.svg";
import contacless from "../../../assets/images/Fqa/contactless.svg";

const En = {
  whatsapp: {
    text1: "¿Need Help? Chat whith us",
  },
};
const DataWao = [
  {
    title: "What is Wao?",
    content: (
      <p>
        Wao is a Cloud-Base technology platform designed in a Digital Banking
        format, which can be used by individuals and businesses as a Banking
        alternative.
      </p>
    ),
  },
  {
    title: "If Wao is a Digital Banking product, Who is behind Wao?",
    content: (
      <p>
        Wao was designed and developed by <b>net 24|7</b>. <b>net 24|7</b> is a
        financial technology company that offers their customers technological
        services that allow them to enjoy innovative and secure financial tools,
        promising to change the way that users manage their finances.
      </p>
    ),
  },
  {
    title: "Is net 24|7 a bank?",
    content: (
      <p>
        No, <b>net 24|7</b> isn’t a Bank. Our banking services are provided by
        partners (Banks and Fintechs) that allows us to serve as a Banking
        Alternative for our users. However, according with the US regulation we
        are considered by FinCEN as a Financial Institution.
      </p>
    ),
  },
  {
    title: "Is Digital Banking secure?",
    content: (
      <p>
        Yes, safety is at the center of everything we do. Our platform is
        constantly updated and maintained to exceed industry standards, so you
        can be sure you're in good hands. In addition, we comply with the
        broadest and strictest regulations regarding the transmission of money,
        governed by U.S. law.
      </p>
    ),
  },
  {
    title: "What is a Wao Account?",
    content: (
      <p>
        Wao Account is a Digital Banking Account capable of serving people and
        companies alike almost anywhere in the world giving them access to
        advanced financial and payment functions.
        <br />
        There are 2 types of Wao accounts:
        <b className="blue"> Personal and For Business.</b>
      </p>
    ),
  },
  {
    title: "Wao Accounts types",
    content: (
      <p>
        <b className="blue">WaoOne</b> aimed at any person who wants to send and
        receive money in a fast and secure way from anywhere in the world. Aside
        from giving financial flexibility, WaoOne offers you to keep your money
        safe and protected from inflation and devaluation because your Digital
        Account is in the currency of your choice.
        <br />
        <br />
        <br />
        <b className="blue">WaoBiz</b> is our Digital Account built for
        registered corporations and enterprises. WaoBiz has everything you can
        ask in a digital bank account to mobilize your funds and receive
        payments integrating your e-commerce platform with our latest generation
        tools."
      </p>
    ),
  },
  {
    title: "Why I should open a Wao Digital Account?",
    content: (
      <p>
        We think there's lots of benefits becoming a Wao user, here's just a
        few:
        <br />
        <br />* You can open a true US$ account remotely, without the need to be
        a US citizen, resident or registered business and it can take less than
        a few minutes.
        <br />* You can make and receive money from US Bank Accounts (ACH
        payments)<span className="red">*</span>
        <br />* You can make and receive Wire Transfer from any Bank of the
        world<span className="red">*</span>
        <br />* You can receive payment from your family, friends or customers
        through credit and debit cards.<span className="red">*</span>
        <br />* All of your money is available to you, all of the time, allowing
        you quick and easy access to it when you need it.
    
        <p className="note">
          <span className="red">*</span>
          Certain conditions apply. Not all the features are available in all
          countries.
        </p>
      </p>
    ),
  },
  {
    title:
      "Wao Digital Accounts are available for individuals and companies in any country?",
    content: (
      <p>
        As a U.S. company, net 24|7 and its affiliates must comply with U.S. law,
        including trade sanctions administered and enforced by the Office of
        Foreign Assets Control (OFAC). To further reduce risk, fraud and illegal
        activities, net 24|7 and its partners prohibit additional high-risk
        locations. For a full list of the prohibited or ineligible geographic
        locations, see below:
        <br />
        <br />
        <img src={Location} alt="" />
      </p>
    ),
  },
  {
    title:
      "My country is eligible, but Why some products or services still unavailable for me?",
    content: (
      <p>
        We collaborate with many partners. That’s why certain products and
        services require that you meet their requirements as well. The most
        common reasons why you wouldn’t be eligible for certain products are:
        <br />
        <br />* The product or service is not yet available in your country.
        <br />* Unsupported identity documents (for example, certain passports
        due to a lack of security features).
      </p>
    ),
  },
  {
    title:
      "Why the difference between the services that you provide in each country?",
    content: (
      <p>
        The banking needs of people and companies are very different based on
        the realities of the country where they carry out their activities.The
        goal of Wao is to be able to adapt to as many uses as possible without
        losing the essence of how flexible, convenient and secure a Digital
        Account should be.
      </p>
    ),
  },
];
const DataWaoOne = [
  {
    title: "How do I open a WaoOne Digital Account?",
    content: (
      <p>To open a WaoOne Account, please download our mobile application.</p>
    ),
  },
  {
    title: "Do I need to have a smartphone?",
    content: (
      <p>
        Yes, you’ll need a smartphone in order to take full advantage of these
        Wao Accounts and to access all of your account features. Feel free to
        download the <strong>WaoApp</strong> in the Apple Store or Google Play.
      </p>
    ),
  },
  {
    title: "Who can open a WaoOne Digital Account?",
    content: (
      <p>
        You can open a WaoOne Account in the WaoApp (on your smartphone) if you
        are an individual that fulfill the following requirements:
        <br />
        <br />* Be a resident of an eligible country
        <br />* Be at least 18 years old Hold a compatible smartphone device
        <br />* Hold a compatible smartphone device
        <br />* Hold a supported government ID and proof of residence
        <br />* Be able to confirm your identity through our Biometric
        Verification Process.
        <br />* Be able to fulfill our AML Verification Process
      </p>
    ),
  },
  {
    title: "Why I need to provide this information?",
    content: (
      <p>
        We are a licensed and regulated Financial Institution, as such there are
        US requirements we need to meet. One of these is to Know Your Customer
        (KYC). To meet this obligation we need you to provide us sufficient
        information when you apply for an account for us to confirm you are who
        you say you are and that we are allowed to offer you our services. We
        will also periodically ask you to confirm your details are up to date
        and provide documentation if they have changed.
        <br />
        <br />
        An important part of this process is ensuring we know where you reside.
        You can provide us any of the following documents to demonstrate your
        proof of address. Here some examples of documentation that are
        considered as a proof of address:
        <br />
    
        * Utility bill (Power, water or land line) issued within the last 90
        days
        <br />* Valid driver’s license
        <br />* Bank statement issued within the last 90 days
        <br />* Voter registration card
        <br />
        <br />
        We prefer you do the verification when you make your application but you
        have 30 days from the approval of your account to upload the information
        and become a Verified User.
      </p>
    ),
  },
  {
    title: "Do I need a US phone number?",
    content: (
      <p>
        You do not need a US telephone number to apply for a Wao Digital
        Account. You can use any mobile number as your primary phone contact
        number.
      </p>
    ),
  },
  {
    title:
      "How long does it take to approve my Wao Digital Account application?",
    content: (
      <p>
        We've made opening an account with us as easy as we can, but we still
        need to complete all the regulatory and procedural checks required by
        compliance. If you submit all your details correctly and meet our
        requirements your account application will be automatically approved at
        the same time you make it.
        <br />
        <br />
        If we require further supporting information we will accept your
        application and then contact you within 48hrs (business days) to explain
        what we need. The faster you reply to us, the sooner we will be able to
        provide you with a decision.
        <br />
        <br />
        If there is no way we can open an account for you, your application will
        be declined. The most common reason for this is that you are located in
        a country we are prohibited from serving by our compliance, licenses or
        regulations.
      </p>
    ),
  },
  {
    title: "How can I know if the WaoOne account is right for me?",
    content: (
      <p>
        Knowing that <strong>WaoOne</strong> is an account where a holder is a
        person, it is essential to consider what use you will give to the
        account.
        <br />
        <br />
        <strong>WaoOne</strong> is intended for people who only use its digital
        account for their personal banking needs and conducts transactions
        between family, friends, and acquaintances.
        <br />
        <br />
        You shouldn't use your <strong>WaoOne</strong> Digital Account for
        business-related purposes primarily because you risk commingling funds,
        not accurately tracking deductible expenses, and potentially putting you
        afoul of the tax regulator in your country of residence. For this
        reason, if you want to use your digital account on a business basis, we
        recommend you use a WaoBiz Digital Account to ensure you are in the best
        position to file your taxes and stay on good footing with the tax
        regulator in your country of residence.
      </p>
    ),
  },
  {
    title: "How do I manage my WaoOne Digital Account?",
    content: (
      <p>
        It's easy and convenient to make the switch from traditional banking to
        our Digital Account service. You'll get secure and convenient access to
        your account and services right from your tablet or mobile phone with
        <strong> WaoApp</strong>.
        <br />
        <br />
        Just open the app and log in with your credentials. Once you log in,
        you'll see the Wao products available to you. Now in there, you can view
        the balance and transactions associated with your products, send and
        receive funds, online view statements, and more within the app.
        <br />
        <br />
        Although all Wao services are convenient, one of them stands out in its
        own bright, the <strong>WaoCard</strong>. This card allows you to use
        your money for purchases in-store or online anywhere in the world.
        <br />
        <br />
        For your convenience, you can get a <strong>WaoCard</strong> in a
        physical or virtual format (or both).
      </p>
    ),
  },
  {
    title: "How do I add money to my WaoOne Digital Account?",
    content: (
      <p>
        As with any Banking Account, the easiest way to add funds to your Wao
        account is through a banking transaction. You can do it via ACH (US
        Banks), Wire Transfer (any Banks), or even via Zelle®. Of course, any
        other Wao user can also send funds to you in seconds.
        <br />
        <br />
        Another popular way to add money to your own or your loved one's
        WaoAccount is sending money using credit or debit cards via a{" "}
        <strong> WaoLink</strong>.
        <br />
        <br />
        You can also make cash deposits to your Wao Account at retail locations
        and participants in our network of partners.
        <br />
        <br />
        *Certain conditions apply. Not all the features are available in all
        countries.
      </p>
    ),
  },
  {
    title: "How can I get my WaoCard?",
    content: (
      <p>
        After you open and add funds to a WaoOne Account, you can request a
        physical or virtual <strong>WaoCard</strong>.<br></br>
        <br></br>
        <ul>
          <li>
            <strong>Physical Card</strong>: As soon as you request a physical
            card, we will begin to personalize your new WaoCard. The time to get
            the card depends on your country of residence but is usually in your
            hands in (2 to 7) business days after the request is made.
          </li>
      
          <li>
            <strong>Virtual Card</strong>: You can create and use the virtual
            card within seconds. Just follow the instructions in the WaoApp.
          </li>
        </ul>
        *Certain conditions apply. Not all the features are available in all
        countries.
      </p>
    ),
  },
  {
    title: "What is WaoLink?",
    content: (
      <p>
        <strong>WaoLink</strong> is a versatile tool that allows Wao Digital
        Account users to create Payment Links capable of being sent by email,
        messenger app or SMS to your family or friends so that they can send a
        payment to you.
      </p>
    ),
  },
  {
    title: "How does Wao Payment Links work?",
    content: (
      <p>
        It is the simplest option to receive money online. Any{" "}
        <strong>WaoOne</strong> user can create a WaoLink in just a few clicks
        through the
        <strong> WaoApp</strong>.<br></br>
        <br></br>
        When you create a payment link, our platform automatically generates a
        unique URL for your payment which you only have to copy and paste to
        send it to whoever you want.
        <br></br>
        <br></br>
        As soon as your family member or friend makes the payment, you can
        immediately see the details in your Wao Account.
      </p>
    ),
  },
  {
    title: "How do I activate my WaoCard?",
    content: (
      <p>
        As soon as you receive your card, follow the instructions in the Wao
        App, under the Cards option in the main menu.
      </p>
    ),
  },
  {
    title: "Who can I send money from my Wao Digital account?",
    content: (
      <p>
        You can use our Banking functions to send money to any Bank Account in
        USA or abroad. Simply log into your WaoApp and select the 'Sent Money'
        tab. You will then be prompted to enter the receiver's information, or
        you can choose one from your contacts if you created them previously.
        <br></br>
        <br></br>
        *Certain conditions apply. Not all the features are available in all
        countries.
      </p>
    ),
  },
];
const DataWaoBiz = [
  {
    title: "Who should choose a WaoBiz Digital Account?",
    content: (
      <p>
        <strong>WaoBiz </strong>was built to meet today's and tomorrow's
        business needs. Wao is a product that is constantly evolving with the
        aim that companies are always up to date with solutions that will
        facilitate the daily banking requirements of your business.
        <br />
        <br />
        Forget devaluation and volatile exchange rates, that's a thing of the
        past, with <strong>WaoBiz </strong> you will get banking alternative in
        the currency of your convenience.
      </p>
    ),
  },
  {
    title:
      "Who can open a WaoBiz Digital Account and what document are needed?",
    content: (
      <p>
        Any legal entity formally incorporated in its country of origin is ready
        to take its digital banking and payment processing management to the
        next level.
        <br></br>
        <br></br>
        Although you will be asked information about the company and its
        shareholders during the onboarding, the entire process is done in an
        automated and seamless way for you.
        <br></br>
        <br></br>
        Have handy the following company information that you will need to
        upload on the platform:
        <br></br>
        <br></br>
        <ul>
          <li>Company’s legal registration documents.</li>
          <br></br>
          <li>Company’s Taxpayer ID (EIN / equivalent in your country).</li>
          <br></br>
          <li>
            Supported government ID and proof of residence of legal
            representatives.
          </li>
        </ul>
        You may be asked for additional information if our Compliance Team deems
        it necessary. In addition, we will keep you updated on the progress of
        the process via the email you provided during onboarding.
      </p>
    ),
  },
  {
    title: "Why do I need to provide this information?",
    content: (
      <p>
        We are a licensed and regulated Financial Institution; as such, there
        are U.S. regulatory requirements we need to meet. One of these is to
        Know Your Customer (KYC). To meet this obligation, we need you to
        provide sufficient information when applying for an account. That way,
        we can confirm you are who you say you are and that we are allowed to
        offer you our services. We will also periodically ask you to confirm
        your details are up to date and provide documentation if they have
        changed.
      </p>
    ),
  },
  {
    title: "How do I open a WaoBiz Digital Account?",
    content: (
      <p>
        To open a WaoBiz Account, you must go through our automated web
        onboarding process at{" "}
        <a href="http://www.wao.online" target="blank">
          www.wao.online
        </a>
      </p>
    ),
  },
  {
    title: "Which type of business can open a WaoBiz Digital Account?",
    content: (
      <p>
        <strong>
          {" "}
          As a U.S. company, net 24|7 and its affiliates must comply with federal
          law and regulations. For example, companies that offer illegal
          services or products cannot open a WaoBiz Digital account. In
          addition, certain high-risk businesses may also be prohibited, such as
          the ones in the following industries:
        </strong>
        <br></br>
        <br></br>
        Adult entertainment products, services and content, prostitution,
        pornography, People and Organizations that support terrorism, Political
        Parties, Games of chance or competitions of / with cash / money, credit
        services, mortgage, debt reduction, foreclosure / debt protection
        services, credit card interest rate reduction services, male enhancement
        products, teeth whitening products, diet pills and products that offer
        health properties that have not been approved or verified by the
        applicable local and / or national regulatory body, services that offer
        subsidies, incentives, monetary rewards from the government, sale of
        cigarettes and spirits without a license issued by the authorities
        places that correspond, Services related to esotericism and horoscopes,
        auction services for or Internet, Timeshares, Exchange / Guarantee of
        Checks and Exchange House Establishments, sale of controlled drugs, Any
        product or service that infringes or directly facilitates the
        infringement of trademarks, patents, copyrights, trade secrets or
        property rights or privacy of products / services licensed, sale or
        resale of a service without additional benefit to the buyer; sites that,
        in our sole discretion, we determine are unfair, misleading or predatory
        to consumers, sale of "views", "likes", comments, followers and other
        forms of activity on social networks, products or services that transmit
        or sell movies , TV shows, music videos or other multimedia content
        without property rights. In general, NET 24|7 prohibits any type of
        transaction that violates local, national, or international laws, and
        that could damage its reputation.
      </p>
    ),
  },

  {
    title: "How long does it take to approve my WaoBiz Account application?",
    content: (
      <p>
        We've made opening an account as easy as possible. However, we still
        need to comply with all the regulatory and procedural checks required.
        If you submit all the requested information and meet the requirements,
        your account application will be approved within hours.
        <br></br>
        <br></br>
        If we require further supporting information, we will temporarily accept
        your application. Then contact you within 48hrs (business days) to
        explain what additional information is needed. The faster you reply, the
        sooner we will be able to provide you with a decision.
        <br></br>
        <br></br>
        If there is no way to open an account for you, we will decline your
        application. The most common reason for this is a combination of risk
        factors like the type of business, country of residence, or the
        inability to serve you with our licenses.
      </p>
    ),
  },
  {
    title: "How to manage my WaoBiz Digital Account?",
    content: (
      <p>
        Most companies have already adopted Digital banking in some way. Surely
        your company already has access to regular bank accounts through bank's
        websites.
        <br></br>
        <br></br>
        <strong> WaoOnline</strong> is our real-time administration site, which
        allows your company to manage all aspects of your business's digital
        banking and payments processing in a unified way. It represents an
        evolution of the traditional banking model, becoming an effective
        solution to expand the benefits of technology and digital
        transformation.
        <br></br>
        <br></br>
        After completing the onboarding process, you will get the credentials to
        log in WaoOnline and begin to enjoy our Digital Financial experience.
      </p>
    ),
  },
  {
    title: "How do I add funds to my WaoBiz Digital Account?",
    content: (
      <p>
        As in any Bank Account, the easiest way to add funds to your
        <strong>WaoBiz </strong>
        account is through a bank transfer. It can be via ACH (U.S. banks), Wire
        Transfers (any bank worldwide), or even Zelle® (U.S. banks). Of course,
        any other Wao user can also send funds to you in seconds.
        <br></br>
        <br></br>
        As a <strong>WaoBiz</strong> user, your company also has access to all
        the Payment Processing capabilities we can offer.
        <br></br>
        <br></br>
        You can also make cash deposits to your <strong>WaoBiz</strong> Account
        at retail locations and participants in our network of partners.
        <br></br>
        <br></br>
        *Certain conditions apply. Not all the features are available in all
        countries.
      </p>
    ),
  },
  {
    title: "How I can get my WaoCard?",
    content: (
      <p>
        After you open and add money to a WaoBiz, you can request a physical
        WaoCard.
        <br></br>
        <br></br>
        <ul>
          <li>
            Physical Card: As soon as you request a physical card, we will begin
            to personalize your new WaoCard. The time to get the card depends on
            your country of residence but is usually in your hands in (2 to 7)
            business days after the request is made.
          </li>
        </ul>
        <br></br>
        <br></br>
        *Certain conditions apply. Not all the features are available in all
        countries.
      </p>
    ),
  },
  {
    title: "What are the payment functionalities that WaoBiz allows me to use?",
    content: (
      <p>
        <strong>WaoBiz</strong> provides all the tools you need to accept
        payments online from customers around the world.
        <br></br>
        <br></br>
        <ul>
          <li>
            <strong>API</strong>: Easily integrate your payment process with our
            powerful API technology. Choose the integration that's right for
            you; WaoBiz offers flexible integration options without compromising
            the payment experience.
          </li>
          <br></br>
          <li>
            <strong>Payments</strong> buttom: It is a hosted payment interface,
            with which you can receive online payments on any website or mobile
            application and offer your customers the advantages of instant
            eCommerce.
          </li>
          <br></br>
          <li>
            <strong>Virtual</strong> Terminal: You can turn your computer into a
            credit card processing terminal. Our solution on WaoOnline allows
            you to receive payments in just a few clicks.
          </li>
          <br></br>
          <li>
            <strong>Electronic Invoincing</strong>: Save time and get paid
            faster from your customers. Create and send an invoice in seconds,
            without the need to write a line of code.
          </li>
          <br></br>
          <li>
            <strong>Capture devices</strong>: Extend Wao capabilities with
            devices that help your business to capture data from your customers
            faster and securely.
          </li>
        </ul>
      </p>
    ),
  },
  {
    title: "How does my company integrate WaoBiz Payment Features?",
    content: (
      <p>
        Getting started is easy. After opening your <strong>WaoBiz</strong>{" "}
        Digital Account, we will help you to choose the right integration for
        your business; we offer:
        <br></br>
        <br></br>
        <ol>
          <li>Kickoff discovery, we need to understand your business</li>
          <li>Access to documentation and detailed instructions</li>
          <li>Secure sandbox</li>
          <li>Support team available during your development</li>
          <li>Certification letter</li>
          <li>Extended support in the production launch</li>
        </ol>
      </p>
    ),
  },
  {
    title: "What functionalities provide the WaoAPI integration?",
    content: (
      <p>
        <ol>
          <li>
            Integration options to accept credit and debit cards payments
            online.
          </li>
          <li>Security components for credit and debit cards transactions</li>
          <li>Easily void processed transactions</li>
          <li>Integration options to use or confirm Zelle® payments</li>
          <li>WaoLink implementation capabilities</li>
          <li>Custom reports</li>
          <li>Custom Webhook Data</li>
          <li>Custom Service Fee And Taxes</li>
          <li>Special integrations (customizable for each company)</li>
        </ol>
      </p>
    ),
  },
];
const DataWaoCard = [
  {
    title: "What does your waocard allow you?",
    content: (
      <p>
        <b>WaoCard</b> allows you to use the money you have available in the Wao
        Digital Account both at stores or through digital payments. You can not
        spend more money than the amount shown in your account balance.
      </p>
    ),
  },
  {
    title: "What kind of cards do you issue?",
    content: (
      <p>
        <b>WaoCard</b> is a Dual-Interface card with the latest technology in
        payment capabilities. You have the option to request a virtual card as
        well.
      </p>
    ),
  },
  {
    title: "What is a dual-interface card?",
    content: (
      <p>
        It is a card you can use in two different ways when paying at stores.
        First, pay using the contactless interface where you see this symbol{" "}
        <img src={contacless} alt="symbol" /> Then, just tap the card at the
        point of sale. Or, if the symbol <img src={contacless} alt="symbol" />{" "}
        is not present, use the contact interface to insert the card at the
        point of sale.
      </p>
    ),
  },
  {
    title: "How do I activate my WaoCard?",
    content: (
      <p>
        As soon as you receive the card, follow the instructions in the{" "}
        <b className="blue">WaoApp </b>
        or <b className="blue">WaoOnline</b>, under the “Cards” option in the
        main menu.
      </p>
    ),
  },
  {
    title: "What is the difference between freezing and blocking my WaoCard?",
    content: (
      <p>
        Use the freeze option if you want to disable your card temporarily,
        adding an extra security layer. You can unfreeze your card at any time
        on your <b className="blue">WaoApp </b>
        or <b className="blue">WaoOnline</b>. Block your card in case your card
        has been lost, stolen, or if you detect a fraudulent transaction. If you
        block your card, a new card will be issued and sent to your preferred
        address.
      </p>
    ),
  },
  {
    title: "How do I set a PIN for my new WaoCard?",
    content: (
      <p>
        After your card is activated, proceed to set a PIN for ATM transactions
        following the instructions in the <b className="blue">WaoApp </b>
        or <b className="blue">WaoOnline</b>.
      </p>
    ),
  },

  {
    title: "Can you use your WaoCard in other countries?",
    content: (
      <p>
        Yes, you can use your card worldwide. Just be aware of international
        usage fees.
      </p>
    ),
  },
  {
    title:
      "What do I do if I don't recognize a transaction in my Wao Digital Account?",
    content: (
      <p>
        Please immediately freeze your card and create a support ticket in the
        option “Support” in the main menu of the <b className="blue">WaoApp </b>
        or <b className="blue">WaoOnline</b>. If you are sure that the
        transaction you don't recognize is fraudulent, please immediately block
        your card.
      </p>
    ),
  },
  {
    title: "What are my spending limits with my WaoCard?",
    content: (
      <p>
        You will be able to spend up to USD 1,000 per day and up to USD 25,000
        per month. In case you need to raise your limits, please contact us at
        <b className="blue"> card@wao.net.</b>
      </p>
    ),
  },
];

const DataWaoOneSecurity = [
  {
    title: "How would I access my Wao Account if I lost my mobile device?",
    content: (
      <p>
        Losing your mobile phone always is an inconvenience and stressful
        situation. However, you have an alternative way of accessing your Wao
        Account Online. Go to your Wao's website (
        <b className="blue">www.wao.net</b>). Whenever you want to access your
        account, all you have to do is open your web browser and visit Wao's
        website. After the page loads, look for the tab or button that says "
        <b className="blue">Login</b>”. Use the same credential used on your
        mobile device to log in.
      </p>
    ),
  },
  {
    title: "How can I unlock my account?",
    content: (
      <p>
        If your account is locked after entering the incorrect password or
        answering security questions incorrectly, follow these easy steps:
        <br />
        <br />
        1. Visit <b className="blue">www.wao.net</b> and select Log In in the
        upper-right corner.
        <br />
        2. Enter your Personal ID and tap Log In.
        <br />
        3. Click forgot password.
        <br />
        4. Follow the steps on screen to complete your reset and log in.
        <br />
        <br />
        If you have attempted Login and still are locked out, please contact us
        at{" "}
        <a href="tel:+18033531153" className="blue">
          {" "}
          +1 (803) 353-1153
        </a>
      </p>
    ),
  },
  {
    title: "How do I cancel my account?",
    content: (
      <p>
        If you want to cancel your Wao Account, please contact us via email at{" "}
        <a href="mailto:support@wao.net" className="blue">
          support@wao.net
        </a>
      </p>
    ),
  },
  {
    title: "What is your security policy?",
    content: (
      <p>
        You can see our detailed security policy in the URL{" "}
        <a href="/privacy" target="blank">
          privacy policy
        </a>
      </p>
    ),
  },
  {
    title: "How do l identify and avoid potential scams?",
    content: (
      <p>
        <b>Know the red flags.</b> The most common types of scams will target
        you through fake emails, text messages, voice calls, letters or even
        someone who shows up at your front door unexpectedly. Scammers use
        different tactics to get victims to fall for their schemes. In some
        cases, they can be friendly, sympathetic and seem willing to help. In
        others, they use fear tactics to persuade a victim.
        <br />
        <br />
        Know the best ways to avoid being scammed:
        <br />
        <br />
        <ul>
          <li>
            <strong>Don’t respond</strong>: If you're not 100% certain of the
            source of the call, email or text, then hang up the phone, don’t
            click on the link in the email and don't reply to the text message.
          </li>
          <br />
          <li>
            <strong>
              Don’t trust caller ID or answer phone calls from unknown numbers:
            </strong>
            If you recognize the caller ID but the call seems suspicious, hang
            up the phone. Phone numbers can be easily spoofed to appear to be
            from a legitimate caller.
          </li>
          <br />
          <li>
            <strong>Don’t give out your information</strong>: Never provide any
            personally identifiable information unless you're absolutely certain
            the person and reason are legitimate. Remember: Wao will never ask
            you to send us personal information such as an account number,
            Social Security number or Tax ID over text, email or online.
          </li>
          <br />
          <li>
            <strong>Research and validate</strong>: If the individual or
            organization seems suspicious, make sure the request being made is
            legitimate by calling the organization through an official number
            from their website or consulting with a trusted family member or
            friend.
          </li>
        </ul>
        <br />
        If you feel you may have been a victim of a scam, contact us immediately
        at{" "}
        <a href="tel:+18033531153" className="blue">
          {" "}
          +1 (803) 353-1153
        </a>
      </p>
    ),
  },
  {
    title: "What is phishing?",
    content: (
      <p>
        Phishing is the fraudulent attempt to obtain sensitive information, such
        as usernames, passwords, and account details, typically through an
        email, text message, or even a phone call. These messages may
        impersonate a company, charity, or government agency and often make up
        an urgent request to convince you to sign on to a fake site, open an
        email attachment containing malware, or respond with personal or account
        information. The information you provide can be used to commit identity
        theft or access your account to steal money.
      </p>
    ),
  },
  {
    title:
      "What should I do if I receive a suspicious email, SMS, text or phone call regarding my Wao account?",
    content: (
      <p>
        If you receive a suspicious email or text message, don’t respond, click
        any links, or open attachments. Don’t sign on to your account from a
        link in a suspicious message. To sign-on, use the WaoApp or type
        <b className="blue"> www.wao.net </b> into your browser.
        <br />
        <br />
        If you clicked on a link, opened an attachment, or provided personal or
        account information, call us immediately at  
        <a href="tel:+18033531153" className="blue">
          +1 (803) 353-1153
        </a>
        <br />
        <br />
        If you didn’t respond, forward the suspicious email or text to{" "}
        <a href="mailto:support@wao.net" className="blue">
          support@wao.net
        </a>
          and delete it. You will receive an automated response. We will review
        your message right away and take action as needed.
      </p>
    ),
  },
  {
    title: "What should I do if I believe my identity has been compromised?",
    content: (
      <p>
        To minimize the damage, here are 10 steps to take once you realize you
        may be a victim of identity theft.
        <br />
        <br />
        1. File a claim with your identity theft insurance, if applicable.
        <br />
        2. Notify companies of your stolen identity.
        <br />
        3. Report Identity Theft. Report identity (ID) theft 
        <strong>to the Federal Trade Commission (FTC) online at</strong>{" "}
        IdentityTheft.gov or by phone at{" "}
        <a href="tel:+18774384338" className="blue">
          +1-877-438-4338
        </a>
        . The FTC will collect the details of your situation. Contact your local
        police department.
        <br />
        4. Place a fraud alert on your credit reports.
        <br />
        5. Freeze your credit.
        <br />
        6. Sign up for a credit monitoring service.
        <br />
        7. Tighten security on your accounts.
        <br />
        8. Review your credit reports for mystery accounts.
        <br />
        9. Scan credit card and bank statements for unauthorized charges.
      </p>
    ),
  },
];

const EN_FAQS = {
  DataWao,
  DataWaoOne,
  DataWaoBiz,
  DataWaoCard,
  DataWaoOneSecurity,
 
};
export default EN_FAQS;
