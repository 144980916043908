const Es = {
    Text: {
        text1: "Completar Formulario",
        text2: "Gracias por iniciar el proceso de incorporación a WaoBiz, su opinión es muy importante para nosotros y por ello nos gustaría conocer sus impresiones en esta fase: "
    },
    Preguntas: {
        pregunta1: "Nombre de su empresa",
        pregunta2: "¿Por cuál medio ha conocido WaoBiz?",
        pregunta3: "¿Cómo calificaría el proceso de solicitud de la cuenta digital WaoBiz, en nuestra Plataforma? ",
        pregunta4: "¿Recomendaría a sus amigos, conocidos y familiares iniciar el proceso de registro en nuestra Plataforma para obtener una cuenta digital WaoBiz?",
        pregunta5: "¿Ha requerido información o soporte en alguno de nuestros canales de comunicación?",
        pregunta6: "¿Cómo calificaría la gestión de nuestros canales de comunicación?",
        pregunta7: "¿Desea comunicarnos alguna sugerencia?"
    
    },
    Opciones: {
        opcion:"Seleccione una opción",
      opcion1P2: "Publicidad",
      opcion2P2: "Instagram",
      opcion3P2: "Facebook",
      opcion4P2: "Recomendación de un amigo",
      opcion5P2: "Correo Electrónico",
      opcion6P2: "Búsqueda de información en internet",
      opcion1P3: "Sencillo",
      opcion2P3: "Flexible",
      opcion3P3: "Complejo",
      opcion4P3: "He tenido un inconveniente",
      opcion5P3: "Especifique cuál inconveniente",
      opcion1P4: "Sí",
      opcion2P4: "No",
      opcion1P5: "WhatsApp",
      opcion2P5: "Redes Sociales",
      opcion1P6: "Excelente",
      opcion2P6: "Buena",
      opcion3P6: "Puede mejorar",
    },
    Button: {
        button1: "Enviar encuesta"
    },

    Error: {
        error1: "El campo no debe estar vacío",
        error2: "Debe seleccionar una opción",
        error3: "Debe contener 255 como máximo letras o caracteres"

    },
    Message: {
        success: "¡Muchas gracias por tomarse el tiempo de completar nuestra encuesta! 😃 ",
    } 
 



}

export default Es;