import React from "react";
import { LanguageContext } from "Context";
import EN_FAQS from "./lang/En";
import ES_FAQS from "./lang/Es";

export const Wao = () => {

  return (
    <svg
      className="svg_all"
      width="238"
      height="78"
      viewBox="0 0 218 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M111.105 51.9409C110.058 51.938 109.036 51.6234 108.168 51.037C107.301 50.4506 106.628 49.6191 106.235 48.6486L106.158 48.438L103.626 40.3904L101.147 48.5193C100.841 49.3057 100.353 50.0081 99.7219 50.5684C99.091 51.1286 98.3358 51.5307 97.5187 51.7413C96.7324 51.9509 95.9071 51.968 95.1128 51.7912C94.3185 51.6143 93.5785 51.2487 92.9554 50.7252C92.2411 50.129 91.7 49.3518 91.3887 48.475V48.4306L84.4569 28.0232H78.6225C77.8297 28.0232 77.0694 27.7083 76.5088 27.1477C75.9482 26.5871 75.6333 25.8268 75.6333 25.034C75.6333 24.2412 75.9482 23.4809 76.5088 22.9203C77.0694 22.3597 77.8297 22.0448 78.6225 22.0448H86.1049C86.8619 22.0457 87.6 22.2805 88.2185 22.717C88.8369 23.1535 89.3053 23.7705 89.5597 24.4835L89.5782 24.5278L96.2291 44.1592L98.9486 35.2248C99.3082 34.2885 99.9385 33.4806 100.759 32.9041C101.58 32.3276 102.554 32.0087 103.556 31.988H103.615C104.616 31.997 105.591 32.3061 106.415 32.8752C107.238 33.4443 107.872 34.2473 108.234 35.1804L108.312 35.391L111.024 44.004L117.246 24.1435C117.358 23.7631 117.545 23.4087 117.796 23.1012C118.046 22.7937 118.356 22.5391 118.705 22.3523C119.055 22.1655 119.439 22.0502 119.834 22.0131C120.229 21.976 120.627 22.0179 121.006 22.1363C121.384 22.2548 121.736 22.4473 122.039 22.7029C122.342 22.9584 122.592 23.2718 122.773 23.6247C122.954 23.9776 123.063 24.363 123.094 24.7585C123.124 25.154 123.076 25.5516 122.951 25.9282L115.89 48.4861C115.586 49.3447 115.062 50.109 114.371 50.703C113.469 51.4979 112.308 51.9378 111.105 51.9409V51.9409Z"
        fill="#1A428A"
      />
      <path
        d="M157.419 52C156.626 52 155.865 51.6851 155.305 51.1245C154.744 50.5639 154.429 49.8035 154.429 49.0108C154.429 48.218 154.744 47.4576 155.305 46.897C155.865 46.3364 156.626 46.0215 157.419 46.0215C158.62 46.0347 159.798 45.6904 160.803 45.0325C161.809 44.3745 162.596 43.4326 163.065 42.3263C163.533 41.2201 163.663 39.9994 163.436 38.8195C163.21 37.6395 162.637 36.5536 161.792 35.6996C160.947 34.8456 159.867 34.2621 158.69 34.0233C157.512 33.7844 156.29 33.901 155.179 34.3582C154.068 34.8154 153.118 35.5926 152.45 36.591C151.781 37.5894 151.425 38.764 151.425 39.9654C151.425 40.7582 151.11 41.5186 150.55 42.0791C149.989 42.6397 149.229 42.9547 148.436 42.9547C147.643 42.9547 146.883 42.6397 146.322 42.0791C145.762 41.5186 145.447 40.7582 145.447 39.9654C145.461 37.6025 146.174 35.2965 147.497 33.3384C148.819 31.3803 150.692 29.8577 152.879 28.9627C155.066 28.0677 157.469 27.8403 159.785 28.3093C162.101 28.7782 164.227 29.9225 165.893 31.5977C167.56 33.273 168.693 35.4042 169.15 37.7227C169.606 40.0411 169.366 42.4429 168.46 44.6252C167.554 46.8074 166.021 48.6723 164.056 49.9848C162.091 51.2973 159.782 51.9985 157.419 52Z"
        fill="#1A428A"
      />
      <path
        d="M138.511 39.5222C138.53 39.3754 138.562 39.2307 138.607 39.0898C138.565 39.2317 138.533 39.3761 138.511 39.5222V39.5222Z"
        fill="#1A428A"
      />
      <path
        d="M138.708 38.8129C138.782 38.6347 138.874 38.465 138.985 38.3066C138.877 38.4667 138.784 38.6361 138.708 38.8129V38.8129Z"
        fill="#1A428A"
      />
      <path
        d="M144.217 38.794C143.985 38.2562 143.601 37.7982 143.112 37.4764C142.622 37.1546 142.05 36.9833 141.464 36.9834V36.9834C140.673 36.9861 139.916 37.3023 139.358 37.8628C139.703 37.5145 140.128 37.2564 140.596 37.1112C141.065 36.966 141.561 36.9382 142.043 37.0302C142.524 37.1221 142.976 37.3311 143.357 37.6387C143.739 37.9463 144.039 38.343 144.232 38.794H144.217Z"
        fill="#1A428A"
      />
      <path
        d="M144.486 48.9477C144.491 49.7376 144.183 50.4973 143.629 51.0604C143.075 51.6234 142.32 51.9439 141.53 51.9517V51.9517C140.955 51.952 140.392 51.7861 139.909 51.4741C139.426 51.1621 139.043 50.7172 138.807 50.1929C136.925 51.3735 134.748 51.9997 132.526 51.9998C129.381 51.9584 126.377 50.6879 124.157 48.4601C121.937 46.2323 120.676 43.224 120.646 40.079C120.616 36.9339 121.817 33.9019 123.994 31.6315C126.171 29.3612 129.149 28.0328 132.293 27.9307C133.07 27.9387 133.814 28.2493 134.366 28.7967C134.919 29.3441 135.236 30.0851 135.251 30.8624C135.266 31.6398 134.977 32.3925 134.446 32.9606C133.915 33.5287 133.184 33.8676 132.407 33.9054C131.212 33.9135 130.045 34.2751 129.054 34.9449C128.063 35.6146 127.293 36.5625 126.84 37.6692C126.387 38.7758 126.271 39.9918 126.508 41.164C126.745 42.3362 127.323 43.4122 128.17 44.2565C129.016 45.1008 130.094 45.6756 131.267 45.9087C132.44 46.1417 133.656 46.0225 134.761 45.5661C135.866 45.1097 136.812 44.3364 137.478 43.3437C138.145 42.351 138.503 41.1832 138.508 39.9874C138.509 39.8315 138.521 39.6759 138.545 39.5218C138.567 39.3757 138.599 39.2313 138.641 39.0895C138.674 38.9971 138.704 38.9048 138.741 38.8124C138.817 38.6356 138.91 38.4662 139.018 38.3062C139.018 38.3062 139.018 38.3062 139.018 38.3062C139.099 38.1916 139.184 38.0845 139.276 37.9773C139.31 37.9446 139.342 37.9101 139.372 37.8739C139.93 37.3134 140.688 36.9971 141.478 36.9945C142.064 36.9943 142.637 37.1657 143.126 37.4874C143.615 37.8092 143.999 38.2673 144.231 38.805C144.306 38.9867 144.364 39.1746 144.405 39.3666C144.407 39.3986 144.407 39.4307 144.405 39.4627C144.435 39.6298 144.449 39.7992 144.449 39.9689V39.9689L144.486 48.9477Z"
        fill="#1A428A"
      />
      <path
        d="M70.9892 28.0307C72.6402 28.0307 73.9785 26.6924 73.9785 25.0415C73.9785 23.3906 72.6402 22.0522 70.9892 22.0522C69.3383 22.0522 68 23.3906 68 25.0415C68 26.6924 69.3383 28.0307 70.9892 28.0307Z"
        fill="#FFC629"
      />
      <path
        d="M70.9892 28.0307C72.6402 28.0307 73.9785 26.6924 73.9785 25.0415C73.9785 23.3906 72.6402 22.0522 70.9892 22.0522C69.3383 22.0522 68 23.3906 68 25.0415C68 26.6924 69.3383 28.0307 70.9892 28.0307Z"
        fill="#FFC629"
      />
    </svg>
  );
};
export const WaoOne = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  
  React.useEffect(() => {
    if (lang === "Es") {
      setLangChoosed(ES_FAQS);
    } else {
      setLangChoosed(EN_FAQS);
    }
  }, [lang]);
  return (
 <p className="text-personas">{langChoosed.section1?.text1}</p>
  );
};
export const WaoBiz = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  
  React.useEffect(() => {
    if (lang === "Es") {
      setLangChoosed(ES_FAQS);
    } else {
      setLangChoosed(EN_FAQS);
    }
  }, [lang]);
  return (
    <p className="text-personas">{langChoosed.section1?.text2}</p>
  );
};
export const WaoCard = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  
  React.useEffect(() => {
    if (lang === "Es") {
      setLangChoosed(ES_FAQS);
    } else {
      setLangChoosed(EN_FAQS);
    }
  }, [lang]);
  return (
    <p className="text-personas">{langChoosed.section1?.text3}</p>
  );
};
export const WaoSecurity = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  
  React.useEffect(() => {
    if (lang === "Es") {
      setLangChoosed(ES_FAQS);
    } else {
      setLangChoosed(EN_FAQS);
    }
  }, [lang]);
  return (
    <p className="text-personas">{langChoosed.section1?.text4}</p>
  );
};
