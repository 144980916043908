import React, { memo } from "react";

const CheckIcon = memo(() => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1673 8.99167L17.134 6.66667L17.4173 3.59167L14.409 2.90833L12.834 0.25L10.0007 1.46667L7.16732 0.25L5.59232 2.90833L2.58398 3.58333L2.86732 6.66667L0.833984 8.99167L2.86732 11.3167L2.58398 14.4L5.59232 15.0833L7.16732 17.75L10.0007 16.525L12.834 17.7417L14.409 15.0833L17.4173 14.4L17.134 11.325L19.1673 8.99167ZM15.8756 10.225L15.409 10.7667L15.4756 11.475L15.6256 13.1L13.3423 13.6167L12.9756 14.2333L12.1506 15.6333L10.6673 14.9917L10.0007 14.7083L9.34232 14.9917L7.85898 15.6333L7.03398 14.2417L6.66732 13.625L4.38398 13.1083L4.53398 11.475L4.60065 10.7667L4.13398 10.225L3.05898 9L4.13398 7.76667L4.60065 7.225L4.52565 6.50833L4.37565 4.89167L6.65898 4.375L7.02565 3.75833L7.85065 2.35833L9.33398 3L10.0007 3.28333L10.659 3L12.1423 2.35833L12.9673 3.75833L13.334 4.375L15.6173 4.89167L15.4673 6.51667L15.4006 7.225L15.8673 7.76667L16.9423 8.99167L15.8756 10.225Z"
        fill="currentColor"
      />
      <path
        d="M8.40898 10.4583L6.47565 8.51667L5.24232 9.75833L8.40898 12.9333L14.5256 6.8L13.2923 5.55833L8.40898 10.4583Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default CheckIcon;
