import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaperPlane from "../../../assets/Homev3/paperPlane.svg";
import "./Form.scss";
import { Suppliers } from "components/suppliers/Suppliers";
import { Text, ButtonBubble } from "../../../components";
import { LanguageContext } from "Context";
import axios from "axios";

import { useRef } from "react";

import EnLang from "../lang/En";
import EsLang from "../lang/Es";

const Input = ({ error, ...rest }) => {
  const InputRef = useRef(null);
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  const focus = () => {
    InputRef.current.focus();
  };

  return (
    <>
      <div className={`continput ${error ? "error" : ""}`} onClick={focus}>
        <input ref={InputRef} {...rest} />
      </div>
      <div className="error-msg">
        {" "}
        {error && <Text category="error">{error}</Text>}
      </div>
    </>
  );
};

const Form = () => {
  const form = useRef();
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState({ label: "", value: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    name: "",
    legalRepresentative: "",
    email: "",
    country: "",
  });
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  const handlerName = (e) => {
    e.target && setName(e.target.value);
  };

  const handlerEmail = (e) => {
    e.target && setEmail(e.target.value);
  };
  const handlerCountry = (e) => {
    setCountry(e);
  };

  const validation = () => {
    let pass = true;
    let AllErrors = { ...error };
    console.log(country);
    console.log(AllErrors);
    //COUNTRY
    if (country.label === "") {
      AllErrors = { ...AllErrors, country: langChoosed?.form?.errorRequired };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, country: "" };
    }
    //NAME
    if (!name) {
      AllErrors = { ...AllErrors, name: langChoosed?.form?.errorRequired };
      pass = false;
    } else if (name.length > 30) {
      AllErrors = {
        ...AllErrors,
        name: `${langChoosed?.form?.errorMaxLength} 30`,
      };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, name: "" };
    }

    //EMAIL

    if (!email) {
      AllErrors = { ...AllErrors, email: langChoosed?.form?.errorRequired };
      pass = false;
    } else if (
      !/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,63}$/i.test(
        email
      )
    ) {
      AllErrors = { ...AllErrors, email: langChoosed?.form?.errorInvalidEmail };
      pass = false;
    } else {
      AllErrors = { ...AllErrors, email: "" };
    }

    console.log(AllErrors);
    setError(AllErrors);
    return pass;
  };
  const reset = () => {
    setError({ name: "", email: "", country: "" });
    handlerName("");

    handlerEmail("");
    handlerCountry("");
    form.current.reset();
  };
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        let country_name = data.country_name;
        localStorage.setItem("pais", JSON.stringify(data.country_name));
        localStorage.setItem("ip", JSON.stringify(data.ip));
        localStorage.getItem("pais", JSON.stringify(country_name));
        localStorage.getItem("ip", JSON.stringify(data.ip));
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getGeoInfo();
  const sendEmail = (e) => {
    e.preventDefault();
    const ip = JSON.parse(localStorage.getItem("ip"))
    const pais = JSON.parse(localStorage.getItem("pais"))
    if (validation()) {
      //RUTA DE QA
      // fetch(`http://test.wao.net/api/user/register`, {
      //RUTA DE PRODUCCIÓN
      // fetch(`https://wao.net/api/user/register`, {
      fetch(`https://wao.net/api/user/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          country_id: country.value,
          language: lang,
          infoDevice: {
            ip: ip,
            browser: "chrome",
            countryDevice: pais,
          },
          bussiness: false,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw text;
          });
        })
        .then((res) => {
          toast.success(res.menssage);
          reset();
        })

        .catch((err) => {
          const errData = JSON.parse(err);
          if (errData?.menssage) {
            toast.error(errData?.menssage);
          } else {
            toast.error("error en el servidor");
          }
        });
    }
  };

  return (
    <div className="form" id="form">
      {/*  <div className="back-image"></div>
      <div className="back-blur"></div> */}
      <div className="paper-image">
        <img src={PaperPlane} alt="paper" />
      </div>
      <div className="paper-image-2">
        <img src={PaperPlane} alt="paper" />
      </div>
      <div className="form-body">
        <div className="encabezado">
          <div className="default-title">{langChoosed.section7?.text1}</div>
          <div className="default-subTitle ">{langChoosed.section7?.text2}</div>
        </div>
        <form
          ref={form}
          onSubmit={sendEmail}
          autocomplete="off"
          className="form-complet"
        >
          <div className="inputform">
            <Input
              type="name"
              name="name"
              id="name"
              placeholder={langChoosed?.form?.placeholderName}
              onChange={(e) => handlerName(e)}
              error={error.name}
            />
            <Input
              type="text"
              name="email"
              id="email"
              placeholder={langChoosed?.form?.placeholderEmailE}
              onChange={(e) => {
                handlerEmail(e);
              }}
              error={error.email}
            />

            <div className="suppliers-div">
              <Suppliers
                error={error.country}
                handlerCountry={handlerCountry}
              />
            </div>
            <div className="button-unete">
              <ButtonBubble
                disabled={isLoading}
                type="submit"
                onClick={sendEmail}
              >
                {langChoosed.section7?.text3}
              </ButtonBubble>
            </div>
          </div>
        </form>

        <ToastContainer
        className="toast-person"
          position="bottom-center"
          autoClose={50000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </div>
  );
};

export default Form;
