import React from 'react'
import { FooterTemplate } from "../../../../../Containers";
import ButtonBubble from "../../../../../components/buttonBubble/ButtonBubble"
import { LanguageContext } from "Context";

import EnLang from "../../../../Business/lang/En";
import EsLang from "../../../../Business/lang/Es";

import "../../../../Business/Business.scss"
import { useHistory } from 'react-router-dom';



const Register = () => {
  let history = useHistory();
    const [lang] = React.useContext(LanguageContext);
    const [langChoosed, setLangChoosed] = React.useState({});
  
    React.useEffect(() => {
      if (lang === "En") {
        setLangChoosed(EnLang);
      } else {
        setLangChoosed(EsLang);
      }
    }, [lang]);
  
  return (
    <><FooterTemplate className="business fooTemp__container">
    <div className="foo__content">
    <div className="title-register">
              <div className="free">
                <span>{langChoosed.section8?.text2}</span>
              </div>
              <div className="register">
                <span>{langChoosed.section8?.text1}</span>
              </div>
            </div>
      <div className="parrafo-register">
        <div className="text-register">
          {langChoosed.section8?.text3 + langChoosed.section8?.text4}
        </div>
      </div>
      <div className="button-register">
        <ButtonBubble
          className={"light-business small"}
          onClick={() => history.push("/register-business")}
        >
          <a className="text-register" href="/register-business">
            {langChoosed.section8?.text5}
          </a>
        </ButtonBubble>
      </div>
    </div>
    {/* <div className={"images"}>
    <img src={Imagen7} alt="home" className={"image-home"} />
  </div> */}
  </FooterTemplate></>
    
  )
}

export default Register