const Es = {
  section1: {
    text1: "Envía y recibe dinero a cualquier lugar del mundo",
    text2: "Con tus familiares y amigos",
    text3: "Envía transferencias, Zelle®, todo desde un solo lugar. La forma más rápida de organizar las finanzas personales de tu día a día.",
    text4: "Queremos ofrecerte una nueva forma de mover tu dinero",
    text5: "Empiece ahora",
  },

  section2: {
    text1: "Es la cuenta digital dirigida a cualquier individuo que necesita una plataforma financiera en dólares en la seguridad de los Estados Unidos.",
    text2: "Podrás enviar y recibir dinero de familiares, amigos y seres queridos de forma rápida y segura desde casi cualquier lugar del mundo. Todo esto a través de una única aplicación móvil.",
    video: "VER VIDEO",
  },

  section3: {
    text1:
      "Conecta con los tuyos y administra tu cuenta Wao a través de una aplicación móvil que te ofrece:",
    text2: "Afiliación 100 % digital",
    text3: "Maneja tus transferencias y tu tarjeta",
    text4: "Configuración de seguridad personalizada",
    text5: "Visibilidad de tus consumos en tiempo real",
  },

  section4: {
    text1:"Maneja cualquier cuenta Wao a través de tu tarjeta, que te ofrece la libertad de realizar compras en tiendas o en línea en cualquier lugar donde se acepte Mastercard.",
    text2: "Solicita tu tarjeta física o virtual inmediatamente después de abrir tu cuenta.",
    text3: "Pagos donde y cuando quieras"

  },

  section5: {
    text1:"Realiza pagos instantáneos con WaoLink.",
    text2: "Crea un enlace de pago exclusivo para tus familiares o amigos, luego envíalo por correo electrónico, SMS, chat o redes sociales, y listo.",
    text3: "Un servicio que se adapta a tus necesidades y a la de tus seres queridos.",
    text4:"¡Recibe tu pago ahora!"
  },

  section7: {
    text1: "¿Cuál es el objetivo de Wao?",
    text2:"Ser la alternativa bancaria para nuestros clientes, liberándolos de:",
    text3: "Procesos burocráticos",
    text4: "Requisitos interminables",
    text5: "Contratos y penalidades",
    text6: "Restricciones operativas",
  },
  section: {
    text1: "",
    text2: "",
    text3: "",
    text4: "",
    text5: "",
    text6: "",
    text7: "",
  },
  whatsapp: {
    text1: "¿Necesitas ayuda? habla con nosotros"
  },
  copy: {
    text1: "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
    text2: "Cookies de privacidad"
  }
};

export default Es;
