const Es = {
  licence: {
    text1: "Estimados usuarios de Wao:",
    text2:
      "Services 24-7 LLC es un proveedor de servicios de pago registrado en el Departamento del Tesoro de los EE. UU. (Fincen) como MSB (Money Service Business) y proveedor de acceso prepago. Estamos autorizados a transmitir dinero en los estados y territorios de EE. UU. donde se nos haya emitido una licencia o mediante las licencias de nuestros socios/proveedores en los otros estados.",
    text3: "MSB",
    text4:
      "Los MSB están regulados por FinCEN (Agencia de Ejecución de Delitos Financieros que forma parte del Departamento del Tesoro de los EE. UU.). Los objetos principales son la supervisión de negocios y empresas que prestan servicios de dinero. El alcance incluye remesas internacionales, cambio de moneda extranjera, transacción/transferencia de moneda (incluida moneda digital/moneda virtual), suministro de artículos prepagos, emisión de cheques de viajero y otros servicios. Las empresas dedicadas a los negocios relacionados antes mencionados deben registrarse antes de que puedan operar legalmente.",
    text5: "Búsqueda de registrantes",
    text6: "Buscar MSB",
    text6: "Ingrese Services 24-7 LLC en NOMBRE LEGAL para buscar",
  },
  privacy: {
    text2: "Privacy Policy",
  },
  head: {
    head0: "net 24|7 GLBA Form ",
    head1: "Facts",
    head2: "What does net 24|7 do with your personal information?",
    head3: "Reasons we can share your personal information",
    head4: "Does net 24|7 share?",
    head5: "Can you limit this sharing?",
    head6: "Questions?",
    head7:
      "Call or chat with us at +1 (863) 4439683 or go to  http://www.net24-7.com/privacypolicy.php",
    head8: "Other important information"
    },
  table: {
    text1: "Why?",
    text2: "What?",
    text3: "How?",
    text4:
      "Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, Share, and protect your personal information. Please read this notice carefully to understand what we do.",
    text5:
      "The types of personal information we collect and share depend on the product or service you have with us. This information can include:",
    text6: "Social Security number,  phone number and email address",
    text7: "Name, address, date of birth and biometric data",
    text8: "Payment card and bank information, including account balances",
    text9:
      "When you are no longer our customer, we continue to share your information as described in this notice.",
    text10:
      "All ﬁnancial companies need to share customers personal information to run their everyday business. In the section below, we list the reasons ﬁnancial companies can share their customers personal information; the reasons net 24|7 chooses to share; and whether you can limit this sharing.",
  },
  tableTwo: {
    text1:
      "For our everyday business purposes such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus",
    text2:
      "For our marketing purposes to offer our products and services to you",
    text3: "For joint marketing with other ﬁnancial companies",
    text4:
      "For our afﬁliates’ everyday business purposes information about your transactions and experiences",
    text5:
      "For our afﬁliates’ everyday business purposes information about your creditworthiness",
    text6: "For nonafﬁliates to market to you",
    text7: "Yes",
    text8: "No",
    text9: "We don’t share",
  },
  tableThree: {
    text1: "Who we are",
    text2: "Who is providing this notice?",
    text3: "What we do",
    text4: "How does net 24|7 protect my personal information?",
    text5: "Why can’t I limit all sharing?",
    text6: "Deﬁnitions",
    text7: "Afﬁliates",
    text8: "Nonafﬁliates",
    text9: "Joint marketing",
    text10: "SERVICES 24-7 LLC",
    text11:
      "To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured ﬁles and buildings.",
    text12: "We collect your personal information, for example, when you",
    text13: "sign-up for an account or provide identity verification",
    text14: "engage in a transaction",
    text15: "Federal law gives you the right to limit only",
    text16:
      "sharing for afﬁliates’ everyday business purposes—information about your creditworthiness",
    text17: "afﬁliates from using your information to market to you",
    text18: "sharing for nonafﬁliates to market to you",
    text19:
      "State laws and individual companies may give you additional rights to limit sharing.",
    text20:
      "Companies related by common ownership or control. They can be ﬁnancial and nonﬁnancial companies.",
    text21:
      "Companies not related by common ownership or control. They can be ﬁnancial and nonﬁnancial companies.",
    text22:
      "Nonaffiliates we share with can include financial institutions, processors, payment card associations and other entities that are involved in the payment process.",
    text23:
      "A formal agreement between nonafﬁliated ﬁnancial companies that together market ﬁnancial products or services to you.",
  },
  tableFour: {
    text1:
      "Vermont Residents — In accordance with Vermont law, we will not share personal financial information about you, other than transaction experience information, nor will we share any personal financial information for marketing purposes.",
    text2:
      "Nevada Residents — Nevada law requires that we provide you with the following contact information: Bureau of Consumer Protection, Office of the Nevada Attorney General, 555 E. Washington Ave., Suite 3900, Las Vegas, NV 89101; Phone number: 702-486-3132; email: agInfo@ag.nv.gov.",
    text3:
      "California Residents — We will not share information we collect about you with nonaffiliated third parties, except as permitted by law, including, for example, with your consent or to service your account.",
  },
  terms: {
    text2: "Terms and Conditions",
  },
  disclaimer: {
    text2: "Disclaimer",
  },
};
export default Es;
