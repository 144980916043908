import React, { memo } from "react";

const UserIcon = memo(() => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00065 2.00065C7.91732 2.00065 8.66732 2.75065 8.66732 3.66732C8.66732 4.58398 7.91732 5.33398 7.00065 5.33398C6.08398 5.33398 5.33398 4.58398 5.33398 3.66732C5.33398 2.75065 6.08398 2.00065 7.00065 2.00065ZM7.00065 9.50065C9.25065 9.50065 11.834 10.5757 12.0007 11.1673V12.0007H2.00065V11.1757C2.16732 10.5757 4.75065 9.50065 7.00065 9.50065ZM7.00065 0.333984C5.15898 0.333984 3.66732 1.82565 3.66732 3.66732C3.66732 5.50898 5.15898 7.00065 7.00065 7.00065C8.84232 7.00065 10.334 5.50898 10.334 3.66732C10.334 1.82565 8.84232 0.333984 7.00065 0.333984ZM7.00065 7.83398C4.77565 7.83398 0.333984 8.95065 0.333984 11.1673V13.6673H13.6673V11.1673C13.6673 8.95065 9.22565 7.83398 7.00065 7.83398Z"
        fill="#2B333F"
      />
    </svg>
  );
});
UserIcon.displayName = "UserIcon";
export default UserIcon;
