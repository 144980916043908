import React from "react";
import { Section } from "Containers";
import "./scroll-down.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ScrollDown = (props) => {
  React.useEffect(() => {
    const textTitles = [...document.querySelectorAll(".title-scroll-down")];

    gsap.to(".phone", {
      scrollTrigger: {
        trigger: ".phone",
        start: "center 55%", // when the top of the trigger hits the top of the viewport
        end: "center 75%", // end after scrolling 500px beyond the start
        endTrigger: ".text__last",
        pinSpacing: true,
        pin: true, // pin the trigger element while active
        scrub: true,
        // markers:{startColor:'pink',endColor:'purple',},
      },
    });
    textTitles.forEach((title, i) => {
      gsap.to(title, {
        opacity: 1,
        duration: 5,
        scrollTrigger: {
          trigger: title,
          start: "top 50%",
          end: "top 160px",
          // markers:{startColor:'blue',endColor:'red',},
          scrub: true,
          toggleClass: {
            targets: ".phone",
            className: `phone${i}`,
          },
        },
      });
    });
  });

  return (
    <React.Fragment>
      <Section id="scroll-phone">
        <div className="outer">
          <div className="device">
            <div className="phone"></div>
            {/* <img className="phone" src={bg} alt="bg"/> */}
          </div>
          <div className="text">
            {props?.text?.map((data, i) => {
              return props?.text.length - 1 === i ? (
                <div className="title-scroll-down text__last">
                  <h2 className="title">{data.title}</h2>
                  {data?.description ? (
                    <p className="description white">{data.description()}</p>
                  ) : null}
                </div>
              ) : (
                <div className="title-scroll-down">
                  <h2 className="title">{data.title}</h2>
                  {data?.description ? (
                    <p className="description white">{data.description()}</p>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </React.Fragment>
  );
};
export default ScrollDown;
