import React from "react";
import "./table.scss";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { LanguageContext } from "Context";
import EnLang from "../../Lang/En";
import EsLang from "../../Lang/Es";
const TableThree = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  return (
    <div className="table-global">
      <div className="div-head-three">
        <div className="div-head-one">{langChoosed?.head?.head6}</div>
        <div> {langChoosed?.head?.head7}</div>
      </div>
      <Table className="table-one-wrapper">
        <TableHead>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody className="table-body">
          <TableRow className="row-gray">
            <TableCell className="title-three">
              {langChoosed?.tableThree?.text1}
            </TableCell>
            <TableCell className="text"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="title">
              {langChoosed?.tableThree?.text2}
            </TableCell>
            <TableCell className="text">
              {langChoosed?.tableThree?.text10}
            </TableCell>
          </TableRow>

          <TableRow className="row-gray">
            <TableCell className="title">
              {langChoosed?.tableThree?.text4}
            </TableCell>
            <TableCell className="text">
              {langChoosed?.tableThree?.text11}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="title">
              {langChoosed?.tableThree?.text4}
            </TableCell>
            <TableCell className="text">
              {langChoosed?.tableThree?.text12}
              <br></br>
              <br></br>
              <ul>
                <li> {langChoosed?.tableThree?.text13}</li>
                <li> {langChoosed?.tableThree?.text14}</li>
              </ul>
            </TableCell>
          </TableRow>

          <TableRow className="row-gray">
            <TableCell className="title">
              {langChoosed?.tableThree?.text5}
            </TableCell>
            <TableCell className="text">
              {langChoosed?.tableThree?.text15}
              <br></br>
              <br></br>
              <ul>
                <li> {langChoosed?.tableThree?.text16}</li>
                <li> {langChoosed?.tableThree?.text17}</li>
                <li> {langChoosed?.tableThree?.text18}</li>
              </ul>
              {langChoosed?.tableThree?.text19}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="title">
              {langChoosed?.tableThree?.text6}
            </TableCell>
            <TableCell className="text"></TableCell>
          </TableRow>
          <TableRow className="row-gray">
            <TableCell className="title">
              {langChoosed?.tableThree?.text7}
            </TableCell>
            <TableCell className="text">
              {langChoosed?.tableThree?.text20}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="title">
              {langChoosed?.tableThree?.text8}
            </TableCell>
            <TableCell className="text">
              {langChoosed?.tableThree?.text21}
              <br></br>
              <br></br>
              <ul>
                <li> {langChoosed?.tableThree?.text22}</li>
              </ul>
            </TableCell>
          </TableRow>
          <TableRow className="row-gray">
            <TableCell className="title">
              {langChoosed?.tableThree?.text9}
            </TableCell>
            <TableCell className="text">
              {langChoosed?.tableThree?.text23}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default TableThree;
