const En = {
  section1: {
    text1: "In this dynamic world, everything is changing faster than ever before…. Even the banking services",
    text2: "Join the new generation of financial services.",
  },

  section2: {
    text1: "¿Who we ",
    text2: "are?",
    text3:
      "Wao is a platform designed and developed by the financial tech company net24|7, offering to all its customers tech products and services giving users the way to enjoy secure and innovating tools, this with the compromise to change the way end users manage their finance.",
    text4:
      "Wao has driven to gather a team with more than 25 years of experience on payments means industry, with a wide strategic knowledge on tech fields. marketing and finance. Besides, net24|7 is supported and partnerships with several payments means tech companies, with specific resources on all involved areas in the transactions processes.",
  },

  section3: {
    text1:
      "Rather than traditional banking technologies, Wao has been designed to facilitate the creation of financial solutions, inclusive, efficient and empowering through digital technology and data.",
  },

  section6: {
    text1: "¿Cómo nos conectamos con",
    text2:
      "La tecnología y la globalización están redefiniendo nuestro mundo, ahora las finanzas y los mercados no conocen fronteras.",
    text3:
      " En esta nueva realidad, Wao está disponible en cualquier parte del mundo donde un usuario acceda a nuestros servicios digitales.",
    text4: "clientes?",
    text5: "nuestros ",
  },
  section8: {
    text1: "License",
    text2: "Services 24-7 LLC is a Payment Service Provider registered with the U.S. Department of Treasury (Fincen) as a MSB (Money Service Business) and Prepaid Access provider. We are authorized to transmit money in the U.S. states and territories where we have been issued a license or through the licenses of our partners/providers in the other states. ",
    text3: "MSBs are regulated by FinCEN (Financial Crimes Enforcement Agency which is part of the U.S. Treasury Department). The main objects are the supervision of businesses and companies that provide money services. The scope includes international remittance, foreign currency exchange, currency transaction/transfer (including digital currency/virtual currency), providing prepaid items, issuing traveler's cheque and other services. Companies engaged in the above related businesses must register before they can operate legally.",
    text4: "See License"
  },
  section9 : {
    text1: "Disclaimer",
    text2: "The purpose of the USA PATRIOT Act is to deter and punish terrorist acts in the United States and around the world, to enhance law enforcement investigatory tools, and other purposes, some of which include:",
    text3: "To strengthen U.S. measures to prevent, detect and prosecute international money laundering and financing of terrorism.",
    text4: "To subject to special scrutiny foreign jurisdictions, foreign financial institutions, and classes of international transactions or types of accounts that are susceptible to criminal abuse.",
    text5: "To require all appropriate elements of the financial services industry to report potential money laundering.",
    text6: "To strengthen measures to prevent use of the U.S. financial system for personal gain by corrupt foreign officials and facilitate repatriation of stolen assets to the citizens of countries to whom such assets belong.",  
    text7: "See disclaimer"
  },
  section10: {
    text1: "Terms",
    text2: "and Conditions",
    text3: "Terms of Use",
    text4: "This website is owned by SERVICE 24-7 LLC (net 24|7) or operated by a subsidiary of net 24|7, and is intended to provide the USER with general information about the products, services and access to the subscription to the same. The Use of these products and services is subject to compliance by the USER of the terms and conditions contained herein. Please read these terms of use carefully before you start using the Web Site. By using this Web Site, the USER indicates that they accept these terms of use and that they agree to abide by them. If you do not agree with these terms of use, do not use any Web Site owned by net 24|7.",
    text5: "Use of the Website",
    text6: "Access to the Web Site is permitted on a temporary basis, and we reserve the right to withdraw or modify the service we provide on the website without any notice. Accordingly, net 24|7 will not be liable if for any reason the Web Site is unavailable at any time or for any period. net 24|7 frequently updates the content without prior notice; however, the digital information published on the Website may be out of date at any given time, in this sense, net 24|7 is not required to update such material. THE USER is responsible for all previous efforts necessary to access the Website. Any information submitted by the USER and received by net 24|7, such as comments, suggestions or ideas, will be considered transferred to net 24|7 free of charge, and the USER who sends it implicitly accepts that it is not confidential. We suggest that you DO NOT SEND any information that can be treated in this way. net 24|7 shall have the right to use any information submitted for any purposes it deems appropriate. net 24|7 may review these Terms of Use at any time by modifying this page. You are expected to review this page from time to time to notice any changes we make as they are binding on you. Some of the provisions contained in these Terms of Use may also be superseded by provisions or notices posted elsewhere on our website.",
    text7: "net 24|7 Liability",
    text8: "Content displayed on the Web Site is provided without any warranties or conditions as to its accuracy. net 24|7 expressly states that all published material, including comments, is not intended to be an exact science on the topics there reported. Therefore, net 24|7 disclaims all liability arising from the reliance placed on such content by any visitor to the Web Site, or by any person who may be informed of such postings. net 24|7 is not responsible for links to other sites and resources provided by third parties, these links are provided only for your information. net 24|7 has no control over the content of those sites or resources, and accepts no responsibility for them or for any loss or damage that may arise from your use of them.",
    text9: "Viruses, Piracy and other Crimes",
    text10: "The USER must not misuse the Web Site by knowingly introducing viruses, Trojans, worms, logic bombs or other malicious or technologically harmful material. As well as attempting to gain unauthorized access to the Web Site, the server on which the Website is stored, or any server, computer or database connected to the net 24|7 website. You should not embroider the website by using a denial-of-service attack or a distributed denial-of-service attack (DDoS). By breaching this provision, the USER would commit a criminal offence under the Law on the Use of Computers of 1990, which will be informed to the competent authorities and their right to use the Website will cease immediately. net 24|7 is not responsible for any loss or damage caused by a distributed denial of service attack (DDoS), viruses or any other technologically harmful material that may infect your computer equipment, software, data or other proprietary material due to your use of our website or your download of any material posted on it, or on any website linked to it.",
    text11: "Privacy Conditions",
    text12: "net 24|7 has implemented appropriate security measures to prevent your personal data from being accidentally lost, used, accessed, altered or disclosed in an unauthorized manner. As part of this security measures, we limit access to your personal data only to those employees, agents, contractors and other third parties who have a business need to be aware of. They will only process your personal data according to our instructions and are subject to a duty of confidentiality. net 24|7, in order to improve the USER experience, uses cookies on the website, to differentiate them among other users. Its purpose is to allow the web server to remember some data concerning the USER, such as their preferences for the visualization of the products, services that interest them the most, among other aspects.",
    text13: "Intellectual Property Rights",
    text14: "net 24|7 owns all copyright and intellectual property rights in the Website, the material published on it and any technology related to the Website. No title or ownership of any of the above is granted or transferred to the USER through the use of the Website.",
    text15: "Jurisdiction and Applicable Law",
    text16: "These terms of use and any disputes or claims arising out of or in connection with them or their subject or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the State of Florida of the United States of America. All USERS who enter this Website, and/or any of net 24|7 digital publications, agree to be bound by this law.",

  },
  
  whatsapp: {
    text1: "¿Need Help? Chat whith us"
  },
  copy: {
    text1: "This website uses cookies to enhance the user experience.",
    text2: "Privacy Cookie"
  }

  
};

export default En;
