import React, { useState } from "react";
import "./Loading.scss";
import { withRouter, useHistory } from "react-router-dom";
import { Footer2 } from "components";
import DotLoader from "react-spinners/DotLoader";
import ReactGA from "react-ga";

ReactGA.initialize("UA-236552941-1");
const Loading = () => {
  let history = useHistory();
  let [loading, setLoading] = useState(true);



  setTimeout(() => {
    window.location.href = "https://wao.online/on-boarding";;
  }, 2000);
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <div className="loading-wrapper">
        <text className="title-loading">
          Estamos procesando su solicitud...
        </text>
        <DotLoader
          color="#204694"
          loading={loading}
          setTimeout={setTimeout}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
      <Footer2 />
    </>
  );
};

export default withRouter(Loading);
