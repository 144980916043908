const En = {

  whatsapp: {
    text1: "¿Need Help? Chat whith us"
  },
  section1: {
    text1: "Digital Account without Borders",
    text2: "you can apply  today!",
    text3: "Your account in the United States",
    text4: "Person or business, doesn’t matter",
    text5: "In dollar to protect your money",
    text7: "Personal",
    text8: "Business",

  },

  section2: {
    text1: "What is Wao?",
    text2: "It is an entire financial platform.",
    text3:
      "Wao offers you a digital account in dollars under the laws and regulations of the United States.",
    text4:
      "It is a digital account that people and companies can use as a real alternative to traditional banking.",
  },

  section3: {
    text1: "Wao is it like a regular bank account?",
    text2: "It's much more than that!",
    text3:
      "Use your money with the flexibility that new technologies can offer without the restrictions of a regular bank account.",
    text4:
      "Wao's goal is to adapt as many usages as possible safely, quickly, and simply.",
  },

  section4: {
    text1: "Wao is just a card?",
    text2: "WaoCard is money in your hands",
    text3:
      "A Wao account allows you to have a prepaid card for present or virtual payments.",
    text5: "Pay at store",
    text6: "Do online shopping",
    text7: "Set recurring payments",
    text8: "Use in subscription services",
    text9: "Payments where and when you want.",
  },

  section5: {
    text1: "Wao is all that and much more!",
    text2: "It is a new generation of financial services.",
    text3:
      "Discover everything you can do with Wao, the digital platform that allows people and companies to satisfy all their financial needs in USD with the safety of United States regulations.",
  },

  section6: {
    text1: "¡Wao, es todo eso y mucho más!",
    text2: "Una nueva generación de servicios financieros.",
    text3:
      "Descubre todo lo que puedes hacer con wao, una plataforma digital que empodera a las empresas y personas.",
  },

  section7: {
    text1: "Enjoy a new digital experience",
    text2: "Sign up now",
    text3: "JOIN THE WAIT LIST",
  },

  modal: {
    title: ["Thanks for your interest in Wao products! "],
    subTitle: [
      'Currently, our product for individuals, "WaoOne," is unavailable for your region.',
    ],
    paragraphs: [
      "However, you can register here to be one of the first to enjoy a digital account without borders as soon as it is available for your region.",
    ],
    buttons: ["Join the waitlist"],
  },


  form: {
    placeholderName: "Enter you name",
    placeholderEmail: "Enter your email",
    placeholderCountry: "Country of residence",
    errorRequired: "Required field",
    errorMaxLength: "Max. caracters",
    errorInvalidEmail: "Is not an email",

    toastPending: "Please wait...",
    toastSuccessful: "Message sent",
    toastError: "Something went wrong",
  },

  copy: {
    text1: "This website uses cookies to enhance the user experience.",
    text2: "Privacy Cookie"
  },
  section8: {
    text1: "Signup for free",
    text2: "Are you a business?",
    text3:
      "Open your account in the United States without having to be a US resident and receive transactions via",
    text4: " ACH, Wire Transfer, credit or debit card y Zelle®.",
    text5: "Start now",
  },
  
};

export default En;
