import React from "react";

export const ActiveItemContext = React.createContext();

export const ActiveItemProvider = (props) => {
  const [activeItem, setActiveItem] = React.useState(0);

  return (
    <ActiveItemContext.Provider value={[activeItem, setActiveItem]}>
      {props.children}
    </ActiveItemContext.Provider>
  );
};
