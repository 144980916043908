const En = {
  section1: {
    text1: "Disponible para los",
    text2: "usuarios Wao",
  },

  section2: {
    text1:
      "Maneja cualquier cuenta Wao a través de una tarjeta de Débito que te ofrece la libertad de hacer compras en comercios o por internet, desde cualquier parte del mundo.",
    text2:
      " Solicita tu tarjeta física o virtual solamente con abrir tu cuenta.",
  },

  section3: {
    text1:
      "¿Eres un negocio que no quiere perder una transacción con tarjeta no presente? o ¿Simplemente una persona con prisa que necesita dinero de inmediato?",
    text2: "Cualquier usuario de Wao puede recibir dinero en segundos con el servicio WaoLink. Envía un enlace de pago directamente a tus clientes, familiares o amigos de la forma que prefieras: SMS, correo electrónico, WhatsApp y recibe tus pagos ahora.",
  },

  section4: {
    text1:
    "Administra tu cuenta Wao a través de una aplicación móvil que te ofrece comodidad en el día a día.",
    text2:
      "Verifica los saldos, transacciones de sus productos, recibe y envía dinero, accede a tus estados de cuenta en línea y más.",
  },

  section5: {
    text1:
      "Diseñado para controlar tu cuenta WaoBiz a través de una plataforma de banca web que te ofrece comodidad en el día a día.",
    text2:
      "Nuestro sitio de administración en tiempo real permite a tu empresa administrar de manera unificada todos los aspectos del servicio financiero y el proceso de pagos de tu negocio.",
  },
  section6: {
    text1: "Cómo nos conectamos con nuestros clientes",
    text2:
      "La tecnología y la globalización están redefiniendo nuestro mundo, ahora las finanzas y los mercados no conocen fronteras.",
    text3:
      " En esta nueva realidad, Wao está disponible en cualquier parte del mundo donde un usuario acceda a nuestros servicios digitales.",
   },

    section7: {
    text1: "Seguridad",
    text2:
      "Entendemos el valor de la seguridad de los datos. Por eso se invirtieron tantas horas en el procesos de seguridad de infraestructuras, esto en estrecha colaboración con los mejores expertos en seguridad del mundo.",
    text3:
      "Con estos antecedentes, garantizamos la seguridad de la infraestructura para recibir, descifrar y transmitir datos en estricto cumplimiento de la normativa aplicable.",
    text4:"y confiabilidad"
    },

  section8: {
    text1: "Cumplimiento",
    text2:
      "La prevención contra el blanqueo de capitales y la financiación del terrorismo es fundamental en Wao. Por ello, contamos con robustos controles de monitoreo transaccional activo KYC, AML y 24|7, orientados a garantizar el máximo cumplimiento de las regulaciones internacionales a través de un poderoso módulo de gestión y control.",
    text3:
      "Pero no te preocupes... todo esto sin comprometer la facilidad y movilidad de tus transacciones",
  },

  section9: {
    text1: " ¿Qué puedes hacer con Wao?",
    text2:
      "Moviliza tu dinero donde y cuando quieras rápido y seguro. Recibe dinero de cualquier  ",
    text3: " tarjeta de crédito o débito directamente a tu cuenta de Wao.",
  },
};

export default En;
