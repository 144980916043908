import React from "react";
import { Footer2 } from "components";
import TableOne from "./Table/table-one";
import TableTwo from "./Table/table-two";
import Privacy1 from "../../../assets/legal/privacy-1.png";
import Privacy2 from "../../../assets/legal/privacy-2.png";
import Privacy3 from "../../../assets/legal/privacy-3.webp";
import "../legal.scss";
import { LanguageContext } from "Context";
import EnLang from "../Lang/En";
import EsLang from "../Lang/Es";
import ReactGA from "react-ga";
import TableThree from "./Table/table-three";
import TableFour from "./Table/table-four";

ReactGA.initialize("UA-236552941-1");
const License = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  window.scrollTo(0,0);
  return (
    <>
      <div className="content-legal">
        <img src={Privacy3} className="img-header" alt="privacy"/>

        <div className="text-inimage">Legal Aspects</div>
        <div className="text-inimage2">{langChoosed?.privacy?.text2}</div>

        <p className="content-legal22">
          This website is owned by SERVICE 24-7 LLC (NET 24|7) or operated by a
          subsidiary of NET 24|7 and is intended to provide the USER with
          general information about the products, services, and access to the
          subscription to the same. The use of these products and services is
          subject to compliance by the USER of the terms and conditions
          contained herein. Please read these terms of use carefully before you
          start using the website. By using this website, the USER indicates
          that they accept these terms of use and that they agree to abide by
          them. If you do not agree with these terms of use, do not use any
          website owned by NET 24|7.
        </p>

        <p className="content-legal22">
          The use of the NET 24|7 transactional platform, any digital
          information issued by NET 24|7, or any other Internet site or
          application owned by NET 24|7 or operated by any NET 24|7 subsidiary,
          attributes to you the condition of USER and implies the full and
          unreserved acceptance of every one of the provisions included in this
          Legal Notice. If you do not agree with this document, please do not
          use any platform, product, publication, or portal owned by NET 24|7.
          NET 24|7 reserves the right to modify the Privacy Policies at any time
          and without prior notification; consequently, we recommend the USER
          read this Legal Notice each time they use this website. Said
          modifications will be effective from the moment they are made
          available to the public on the site
          http://www.net24-7.com/privacypolicy.php. The USER is considered aware
          of and bound by the changes in our Privacy Policy from the moment they
          enter the site http://www.net24-7.com or any other Internet site or
          application owned by NET 24|7 or operated by any NET 24|7 subsidiary.
        </p>

        <br></br>

        <ul className="content-legal22">
          <li>
            <text className="content-legal23">
              We are committed to collecting and using your personal information
              only with your knowledge and consent. Typically, this will be when
              you use our services, make inquiries, register or request
              information or other services, submit a request, or when you
              respond to our communications.
            </text>
          </li>{" "}
          <br></br>
          <li>
            <text className="content-legal23">
              By accepting this Legal Privacy Notice, the USER authorizes NET
              24|7 to collect, store, conserve, use, delete, update and share
              the data generated by the use of NET 24|7 Products and Services to
              be analyzed in a demographic, economic, biometric, commercial and
              location order, for the benefit of the USER, own or of third
              parties with whom NET 24|7 has entered into agreements for sending
              and receiving information.
            </text>
          </li>{" "}
          <br></br>
          <li>
            <text className="content-legal23">
              If you choose to provide us with personal information, it will be
              used in support of the intended purposes established at the time
              it was collected, and subject to the preferences you indicate.
            </text>
          </li>{" "}
          <br></br>
          <li>
            <text className="content-legal23">
              It may be the case that it is in the interest of NET 24|7 to
              collect non-personal information about your visit to our website
              based on your browsing activities. This information may include
              the pages you browse, and the services viewed, among others. This
              helps us to better manage and develop our sites and to provide you
              with more pleasant and personalized service and experience in the
              future.
            </text>
          </li>{" "}
          <br></br>
          <li>
            <text className="content-legal23">
              We may use your information to perform some functions related to
              the products and services to which the USER is attached, for
              example, to: Process your orders and manage and administer your
              accounts and products, send or provide you with any service or
              information that you requested, respond to your requirements or
              queries, verify your identity when necessary. Carry out marketing
              research on our products or services or carefully selected third
              parties, depending on the pattern of use that USERS make of our
              services; this may include contacting the USER with information
              about new developments, offers, services, and special promotions
              by mail, telephone, or any other digital or automated means.
            </text>
          </li>{" "}

        </ul>

        <br></br>
        <p className="content-legal22">
          NET 24|7 will scrupulously respect the confidentiality of the personal
          data provided by USERS of products, services, and publications, by
          observing this Legal Notice and current applicable regulations, in
          such a way that NET 24|7 will not deliver the USER's personal
          information to third parties unless it is required to deliver a
          product or provide a service requested by the USER. However, the
          aforementioned and given the nature of the Internet, NET 24|7 does not
          guarantee that all use of the website, products and services provided
          through them is risk-free, so NET 24|7 does not will have any
          responsibility, including damages, losses, or any other that may be
          caused by the misuse of third parties of the information generated,
          received, transmitted, or filed on the pages.
        </p>

        {/* <p className="content-legal22">
          Following the provisions of this document, the client declares to know
          and accept the Privacy Policies detailed in this Legal Notice.
        </p>

        <p className="content-legal22">
          The information in this document is considered confidential between
          the parties with the Copyright 2020 net 24|7.
        </p> */}

   
        {/* <img src={Privacy1} className="img-fluid" alt="table-1" />
        <img src={Privacy2} className="img-fluid" alt="table-1" /> */}
      </div>
      <TableOne/>
      <TableTwo/>
      <TableThree/>
      <TableFour/>
      <Footer2 position={"relative"} />
    </>
  );
};

export default License;
