import React from "react";

import Lottie from "react-lottie";
import Section from "../Grid/Section/Section";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./AnimationScroll.scss";

const Row = ({ children, className,alignY, ...rest }) => {
  return (
    <div
      className={`section-animation col ${className} ${alignY && "y-" + alignY} ${
        rest.className
      }`}
    >
      {children}
    </div>
  );
};

const AnimationScroll = ({ children, resource, ...rest }) => {
  const [active, setActive] = React.useState(0);
  const colRight = React.useRef();
  let arrSections = React.Children.map(children, (child) => {
    return child;
  });

  React.useEffect(() => {
    const activImage = () => {
      const secction = parseInt(
        (window.pageYOffset + 100) / window.innerHeight
      );

      if (secction < 6) {
        //validacion solo porque tenemos solo 5 secciones (esto no esta dinamico)
        setActive(secction);
      }
    };
    // clean up code

    if (window.innerWidth > 700) {
      //solo se aplica la animacion para pantallas mayores a 700px
      window.removeEventListener("scroll", activImage);
      window.addEventListener("scroll", activImage, { passive: true });
      return () => window.removeEventListener("scroll", activImage);
    }
  }, []);

  if (window.innerWidth < 768) {
    return (
      <>
        {resource?.map((data, i) => {
          let recurso = "";
          switch (data.type) {
            case "video":
              recurso = (
                <video
                  key={`col-fixed-${i}`}
                  className="resource__responsive"
                  autoPlay
                  loop
                  muted
                >
                  <source src={data.src}></source>
                </video>
              );
              break;
            case "lottie":
              recurso = (
                <div>
                  <div className="lottie">
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: data.src,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      isPaused={false}
                      // height={864}
                      width={window.innerWidth}
                    />
                  </div>
                </div>
              );
              break;
            default:
              recurso = (
                <LazyLoadImage
                  key={`animation-img-${i}`}
                  className="resource__responsive images"
                  src={data.src}
                  alt="animation-right"
                />
              );
              break;
          }

          return (
            <>
              <Section className={rest.className} id="eduard">
                {recurso}
                {arrSections[i]}
              </Section>
            </>
          );
        })}
      </>
    );
  }

  return (
    <>
      <div
        className={`wrapper-animation ${rest.className ? rest.className : ""}`}
      >
        {children}
        <div
          ref={colRight}
          className={`col-fixed resource-animation  ${
            active === resource.length && "end-animation"
          }`}
        >
          {resource?.map((data, i) => {
            switch (data.type) {
              case "video":
                return (
                  <video
                    key={`col-fixed-${i}`}
                    className={`resource-animation ${
                      active === i ? "show" : "hidden"
                    }`}
                    autoPlay
                    loop
                    muted
                  >
                    <source src={data.src}></source>
                  </video>
                );
                break;

              case "lottie":
                return (
                  <div
                    className={`resource-animation ${
                      active === i ? "show" : "hidden"
                    }`}
                  >
                    <div className="lottie">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: data.src,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        isPaused={false}
                        height={864}
                        width={864}
                      />
                    </div>
                  </div>
                );
                break;
              default:
                return (
                  <LazyLoadImage
                    key={`animation-img-${i}`}
                    src={data.src}
                    alt="animation-right"
                    className={`resource-animation ${
                      active === i ? "show" : "hidden"
                    }`}
                  />
                );
                break;
            }
          })}
        </div>
      </div>
    </>
  );
};

AnimationScroll.Row = Row;
export default AnimationScroll;
