import Location from "../../../assets/icons/location.svg";
import contacless from "../../../assets/images/Fqa/contactless.svg";

const DataWao = [
  {
    title: "¿Qué es Wao?",
    content: (
      <p>
        Wao es una plataforma tecnológica basada en la nube, que está diseñada
        en un formato de Banca Digital y puede ser utilizada por personas y
        empresas como una alternativa de Bancaria.
      </p>
    ),
  },
  {
    title:
      "Si Wao es un producto de Banca Digital, ¿Qué empresa administra Wao?",
    content: (
      <p>
        Wao fue diseñado y desarrollado por <b>net 24|7</b>. Una Fintech
        americana que ofrece servicios tecnológicos a sus clientes que les
        permite disfrutar de herramientas financieras innovadoras y seguras, con
        la promesa de cambiar la forma en que los usuarios manejan sus finanzas.
      </p>
    ),
  },
  {
    title: "¿net 24|7 es un banco?",
    content: (
      <p>
        No, <b>net 24|7</b> no es un banco. Nuestros servicios bancarios son
        proporcionados por socios (Bancos y Fintechs) que nos permiten servir
        como una Alternativa Bancaria para nuestros usuarios. Sin embargo, de
        acuerdo con la regulación estadounidense (FinCEN) somos considerados una
        Institución Financiera.
      </p>
    ),
  },
  {
    title: "¿Siendo Wao una Cuenta Digital, está seguro mi dinero?",
    content: (
      <p>
        Sí, la seguridad está, en el centro de todo lo que hacemos. Nuestra
        plataforma se actualiza y mantiene constantemente para superar los
        estándares de la industria, por lo que puede estar seguro, que está en
        buenas manos. Además, cumplimos con las más amplias y estrictas
        regulaciones en materia de transmisión de dinero, regidas por la ley
        estadounidense.
      </p>
    ),
  },
  {
    title: "¿Qué es una cuenta Wao?",
    content: (
      <p>
        Wao es una cuenta digital financiera capaz de atender a personas y
        empresas por igual en casi cualquier parte del mundo, brindándoles
        acceso a funciones financieras y de pago avanzadas. Hay 2 tipos de
        cuentas Wao:
        <b className="blue"> Personal</b> y <b className="blue"> Business.</b>
      </p>
    ),
  },
  {
    title: "Tipos de cuenta Wao",
    content: (
      <p>
        <b className="black">WaoOne</b> está dirigido a cualquier persona que
        necesite flexibilidad financiera, brindándoles la ventaja de enviar y
        recibir dinero de forma rápida y segura desde casi cualquier parte del
        mundo. Además, WaoOne te ofrece mantener tu dinero seguro y protegido de
        la inflación y la devaluación porque tu Cuenta Digital está en la moneda
        de tu elección.
        <br />
        <br />
        <b className="black">WaoBiz</b> es nuestra cuenta digital creada para
        corporaciones y empresas registradas. WaoBiz tiene todo lo que puedes
        pedir en una cuenta bancaria digital para movilizar tus fondos y recibir
        pagos, integrando tu plataforma de pagos electrónicos con nuestras
        herramientas de última generación en mecanismos de pagos.
      </p>
    ),
  },
  {
    title: "¿Por qué debería abrir una Cuenta Digital Wao?",
    content: (
      <p>
        Creemos que hay muchos beneficios al convertirse en un usuario de Wao,
        estos son solo algunos:
        <br />
        <br />
        <ul>
          <li>
            En solo minutos puede abrir de forma remota una cuenta real en los
            Estados Unidos, sin necesidad de ser ciudadano estadounidense,
            residente o empresa registrada en los EE. UU.{" "}
          </li>
          <li>
            Puede enviar y recibir dinero de cuentas bancarias de EE. UU. (pagos
            ACH) <span className="red">*</span>
          </li>
          <li>
            Puede enviar y recibir transferencias bancarias desde cualquier
            banco del mundo <span className="red">*</span>
          </li>
          <li>
            Puedes recibir pagos de tus familiares, amigos o clientes a través
            de tarjetas de crédito y débito <span className="red">*</span>
          </li>
          <li>
            Tu dinero está disponible, todo el tiempo, lo que le permite un
            acceso rápido y fácil cuando lo necesite.{" "}
          </li>
        </ul>
        <p style={{ fontStyle: "italic" }} className="note">
          <span className="red">*</span>
          Ciertas condiciones aplican. No todas las funcione están disponibles
          en todos los países.
        </p>
      </p>
    ),
  },
  {
    title:
      "¿La cuenta digital de Wao está disponible para individuos y empresas en cualquier país?",
    content: (
      <p>
        Como empresa americana, <b>net 24|7</b> y sus filiales deben cumplir con
        las leyes de EE. UU., incluidas las sanciones comerciales administradas y
        aplicadas por la Oficina de Control de Activos Extranjeros (OFAC).
        Además, <b>net 24|7</b> y sus socios prohíben abrir cuentas a personas o
        empresas en determinados países, a fin de reducir el riesgo, el fraude y
        las actividades ilegales. Para obtener una lista completa de áreas
        geográficas prohibidas o no elegibles, comuníquese con uno de nuestros
        ejecutivos a través de nuestros canales de soporte.
        <br />
        <br />
        <img src={Location} alt="locations" />
      </p>
    ),
  },
  {
    title:
      "Mi país es elegible, pero ¿Por qué algunos productos o servicios no están disponibles para mí?",
    content: (
      <p>
        Colaboramos con muchos socios para poder ofrecerles los mejores
        servicios. Es por eso que ciertos productos y servicios requieren que
        usted también cumpla con sus requisitos. Las razones más comunes por las
        que no sería elegible para ciertos productos son:
        <br />
        <br />
        <ul>
          <li>El producto o servicio aún no está disponible en su país.</li>
          <li>
            Documentos de identidad no soportados (por ejemplo, ciertos
            pasaportes debido a la falta de elementos de seguridad).
          </li>
        </ul>
      </p>
    ),
  },
  {
    title:
      "¿Por qué la diferencia entre los servicios que brindan en cada país?",
    content: (
      <p>
        Las necesidades bancarias de personas y empresas son muy diferentes en
        función de las realidades del país en el que desarrollan sus
        actividades. El objetivo de Wao es poder adaptarse a tantos usos como
        sea posible sin perder la esencia de cuán flexible, conveniente y segura
        debe ser una Cuenta Digital.
      </p>
    ),
  },
];
const DataWaoOne = [
  {
    title: "¿Cómo abro una cuenta digital de WaoOne?",
    content: (
      <p>
        Para abrir una cuenta de <b>WaoOne</b>, debe descargar nuestra
        aplicación móvil.
      </p>
    ),
  },
  {
    title: "¿Necesito tener un teléfono inteligente?",
    content: (
      <p>
        Sí, necesitará un teléfono inteligente para aprovechar al máximo la
        cuenta de <b>WaoOne</b> y acceder a todas sus funciones. Siéntase libre
        de descargar <b>WaoApp</b> en <b className="blue"> Apple Store</b> o{" "}
        <b className="blue"> Google Play</b>
      </p>
    ),
  },
  {
    title: "¿Quién puede abrir una cuenta Digital WaoOne?",
    content: (
      <p>
        Puede abrir una cuenta de <b>WaoOne</b>, si es un individuo que cumple
        con los siguientes requisitos:
        <br />
        <br />
        <ul>
          <li>Ser residente de un país atendido por Wao.</li>
          <li>Tener al menos 18 años.</li>
          <li>Tener un teléfono inteligente compatible.</li>
          <li>
            Poder confirmar su identidad a través de nuestro Proceso de
            Verificación Biométrica.
          </li>
          <li>
            Ser aprobado a través de nuestro proceso de verificación AML
            (Anti-Money Laundering)
          </li>
        </ul>
      </p>
    ),
  },
  {
    title: "¿Por qué necesito proporcionar esta información?",
    content: (
      <p>
        Somos una Institución Financiera autorizada y regulada; hay requisitos
        de EE. UU. que debemos cumplir. Uno de ellos es Know Your Customer
        (KYC). Para cumplir con esta obligación, necesitamos que proporcione
        suficiente información cuando solicite una cuenta para confirmar que es
        quien dice ser y que podemos ofrecerle nuestros servicios. También le
        pediremos periódicamente que confirme que sus datos están actualizados y
        que proporcione documentación si han cambiado.
        <br></br>
        <br></br>
        Una parte esencial de este proceso es asegurarse de que sepamos dónde
        reside. Usted deberá proporcionar documentos para demostrar su país de
        residencia. Estos son algunos ejemplos de documentación que se considera
        como prueba de domicilio:
        <br></br>
        <br></br>
        <ul>
          <li>
            {" "}
            Factura de servicios públicos (electricidad, agua o teléfono fijo)
            emitida en los últimos 90 días
          </li>
          <li>Licencia de conducir válida</li>
          <li>Estado de cuenta bancario emitido en los últimos 90 días</li>
          <li>Credencial de elector o NIF</li>
        </ul>
    
        Preferimos que haga la verificación cuando haga su solicitud. Aun así,
        tiene 30 días a partir de la aprobación de su cuenta para cargar la
        información y convertirse en un Usuario Verificado.
      </p>
    ),
  },
  {
    title: "¿Necesito un número de teléfono de EE. UU.?",
    content: (
      <p>
        Usted no necesita un número de teléfono de EE. UU. para solicitar una
        Cuenta Digital Wao. Puede utilizar cualquier número de teléfono móvil de
        su país como su número de contacto de teléfono principal.
      </p>
    ),
  },
  {
    title:
      "¿Cuánto tiempo se tarda en aprobar mi solicitud de Cuenta Digital de Wao?",
    content: (
      <p>
        Hemos hecho que abrir una cuenta con nosotros sea lo más fácil posible.
        Sin embargo, todavía tenemos que completar todas las verificaciones
        reglamentarias y de procedimiento requeridos por el cumplimiento. Si
        envía todos sus datos correctamente y cumple con nuestros requisitos, su
        solicitud de cuenta se aprobará automáticamente al mismo tiempo que la
        realiza.
        <br />
        <br />
        Si necesitamos más información de respaldo, aceptaremos su solicitud y
        luego nos comunicaremos con usted dentro de las 48 horas (días hábiles)
        para explicarle lo que necesitamos. Cuanto más rápido nos responda, con
        más prontitud podremos proporcionarle una decisión.
        <br />
        <br />
        Si no hay manera de que podamos abrir una cuenta para usted,
        lamentablemente, tendremos que rechazar su solicitud. La razón más común
        para esto es que se encuentra en un país en el que nuestro cumplimiento,
        licencias o regulaciones nos prohíben brindar servicios.
      </p>
    ),
  },
  {
    title: "¿Cómo puedo saber si la cuenta de WaoOne es adecuada para mí?",
    content: (
      <p>
        Sabiendo que <strong>WaoOne</strong> es una cuenta donde el titular es
        una persona, es fundamental considerar qué uso le darás a la cuenta.
        <br />
        <br />
        <strong>WaoOne</strong> está destinado a personas que solo usan su
        cuenta digital para sus necesidades bancarias personales y realizan
        transacciones entre familiares, amigos y conocidos.
        <br />
        <br />
        No recomendamos usar su cuenta digital de <strong>WaoOne</strong> para
        fines comerciales, principalmente porque corre el riesgo de mezclar
        fondos, no realizar un seguimiento preciso de los gastos deducibles y en
        algunos casos ponerlo en conflicto con el regulador fiscal en su país de
        residencia. Por esta razón, si desea utilizar su cuenta digital con
        fines comerciales, le recomendamos que utilice una cuenta digital de{" "}
        <strong>WaoBiz</strong> para asegurarse de estar en la mejor posición
        para declarar sus impuestos y mantenerse en buenas condiciones con el
        regulador fiscal de su país de residencia.
      </p>
    ),
  },
  {
    title: "¿Cómo puedo manejar mi cuenta WaoOne Digital?",
    content: (
      <p>
        Es fácil y conveniente cambiar de la banca tradicional a nuestro
        servicio de Cuenta Digital. Usted podrá tener acceso seguro y
        conveniente a su cuenta y servicios directamente desde su tableta o
        teléfono móvil con <strong> WaoApp</strong>.
        <br />
        <br />
        Simplemente, abra la aplicación e inicie sesión con sus credenciales. Una
        vez que inicie sesión, verá los productos Wao disponibles para usted.
        Desde allí, podrá ver el saldo y las transacciones asociadas con sus
        productos, enviar y recibir fondos, ver estados de cuenta en línea y
        mucho más.
        <br />
        <br />
        Aunque todos los servicios de Wao son convenientes, uno de ellos se
        destaca por sí solo, la <strong> WaoCard</strong>. Esta tarjeta le
        permite usar su dinero para compras en la tienda o en línea en cualquier
        parte del mundo.
        <br />
        <br />
        Para tu comodidad, podrás obtener una <strong>WaoCard</strong> en
        formato físico o virtual (o ambos).
      </p>
    ),
  },
  {
    title: "¿Cómo depósito dinero en mi Cuenta Digital de WaoOne?",
    content: (
      <p>
        Al igual que con cualquier cuenta bancaria, la forma más sencilla de
        agregar fondos a su cuenta de Wao es a través de una transacción
        bancaria.
        <br />
        <br />
        Puede hacerlo a través de ACH (bancos de EE. UU.), Wire (cualquier banco
        en el mundo) o incluso a través de Zelle®. Por supuesto, cualquier otro
        usuario de Wao también puede enviarte fondos en segundos.
        <br />
        <br />
        Otra forma popular de agregar dinero a su cuenta WaoAccount es enviar
        pagos con tarjetas de crédito o débito a través de un{" "}
        <strong> WaoLink</strong>.
        <br />
        <br />
        También podrá realizar depósitos en efectivo en su cuenta de Wao en
        establecimientos comerciales participantes de nuestra red de socios.
        <br />
        <br />
        <p style={{ fontStyle: "italic" }} className="note">
          <span className="red">*</span>
          Ciertas condiciones aplican. No todas las funcione están disponibles
          en todos los países.
        </p>
      </p>
    ),
  },
  {
    title: "¿Cómo puedo solicitar mi WaoCard?",
    content: (
      <p>
        Después de abrir y depositar fondos a su cuenta de WaoOne, puede
        solicitar una <strong>WaoCard</strong> física o virtual.<br></br>
        <br></br>
        <ul>
          <li>
            <strong>Physical Card</strong>: Tan pronto como solicite su tarjeta,
            comenzaremos a personalizar su nueva WaoCard. El tiempo para recibir
            la tarjeta depende de tu país de residencia pero suele estar en tus
            manos de 2 a 7 días hábiles después de realizada la solicitud.
          </li>
      
          <li>
            <strong>Virtual Card</strong>: Puede crear y usar la tarjeta virtual
            en segundos. Solo sigue las instrucciones en la WaoApp.
          </li>
        </ul>
        <p style={{ fontStyle: "italic" }} className="note">
          <span className="red">*</span>
          Ciertas condiciones aplican. No todas las funcione están disponibles
          en todos los países.
        </p>
      </p>
    ),
  },
  {
    title: "¿Qué es WaoLink?",
    content: (
      <p>
        <strong>WaoLink</strong> es una herramienta versátil que permite a los
        usuarios de Wao crear enlaces (links) de pago que se pueden enviar por
        correo electrónico, aplicación de mensajería o SMS a su familia o amigos
        para que puedan enviarle un pago.
      </p>
    ),
  },
  {
    title: "¿Cómo funciona un WaoLink?",
    content: (
      <p>
        Es la opción más sencilla para recibir dinero en línea. Cualquier
        usuario de <strong>WaoOne</strong> puede crear un WaoLink con solo unos
        pocos clics a través de <strong> WaoApp</strong>.<br></br>
        <br></br>
        Cuando creas un link de pago, nuestra plataforma genera automáticamente
        una URL única para tu pago que solo tienes que copiar y pegar para
        enviarla a quien quieras.
        <br></br>
        <br></br>
        Tan pronto como su familiar o amigo realice el pago, podrás ver
        inmediatamente los detalles del depósito en tu cuenta Wao.
      </p>
    ),
  },
  {
    title: "¿A quién puedo enviar dinero desde mi cuenta de Wao Digital? ",
    content: (
      <p>
        Puede utilizar nuestras funciones bancarias para enviar dinero a
        cualquier cuenta bancaria en EE. UU. o en el extranjero
        <br></br>
        <br></br>
        Simplemente inicie sesión en su WaoApp y pulse la opción “Enviar”. Luego
        se le pedirá que ingrese la información del beneficiario del pago, o
        puede elegir uno de sus contactos si ya los creó anteriormente.
        <br></br>
        <br></br>
        <p style={{ fontStyle: "italic" }} className="note">
          <span className="red">*</span>
          Ciertas condiciones aplican. No todas las funcione están disponibles
          en todos los países.
        </p>
      </p>
    ),
  },
];
const DataWaoBiz = [
  {
    title: "¿Quién debería usar la cuenta digital WaoBiz?",
    content: (
      <p>
        <strong>WaoBiz </strong> se creó específicamente para satisfacer las
        necesidades comerciales de las empresas. Wao es un producto en constante
        evolución, de forma que pueda garantizar que las empresas estén siempre
        actualizadas con soluciones que resolverán las necesidades bancarias y
        de procesamiento de pagos que su negocio utiliza día a día.
        <br />
        <br />
        Olvídese de la devaluación y los tipos de cambio volátiles; eso es cosa
        del pasado; con <strong>WaoBiz</strong>, tendrá a su alcance una
        alternativa bancaria en la moneda de su conveniencia.
      </p>
    ),
  },
  {
    title:
      "¿Quién puede abrir una cuenta digital WaoBiz y qué documentos necesita? ",
    content: (
      <p>
        Cualquier empresa formalmente constituida en su país de origen está
        lista para llevar su gestión de procesamiento de pagos y banca digital
        al siguiente nivel.
        <br></br>
        <br></br>
        Debido a regulaciones legales, será necesario confirmar la información
        legal sobre la empresa y sus accionistas cuando realice la solicitud,
        sin embargo, todo el proceso se realiza de forma automatizada y
        autogestionado por usted.
        <br></br>
        <br></br>
        Tenga a mano la siguiente información de la empresa que deberá cargar en
        la plataforma:
        <br></br>
        <br></br>
        <ul>
          <li>Documentos de registro legal de la empresa.</li>
          <li>
            Identificación fiscal de la empresa (Tax ID / EIN / RUC /
            equivalente en su país)
          </li>
        </ul>
        Es posible que se le solicite información adicional si nuestro Equipo de
        Cumplimiento lo considera necesario. Además, lo mantendremos informado
        sobre el progreso del proceso a través del correo electrónico que
        proporcionó durante la solicitud.
      </p>
    ),
  },
  {
    title: "¿Por qué necesito proporcionar esta información? ",
    content: (
      <p>
        Somos una Institución Financiera autorizada y regulada; hay requisitos
        de EE. UU. que debemos cumplir. Uno de ellos es Know Your Customer
        (KYC). Para cumplir con esta obligación, necesitamos que proporcione
        suficiente información cuando solicite una cuenta para confirmar que es
        quien dice ser y que podemos ofrecerle nuestros servicios. También le
        pediremos periódicamente que confirme que sus datos están actualizados y
        que proporcione documentación si han cambiado.
      </p>
    ),
  },
  {
    title: "¿Cómo abro una cuenta digital WaoBiz? ",
    content: (
      <p>
        Para abrir una cuenta de WaoBiz, debe pasar por nuestro proceso de
        onboarding web automatizado en{" "}
        <a href="http://www.wao.online" target="blank">
          www.wao.online
        </a>
      </p>
    ),
  },
  {
    title: "¿Qué tipo de negocios pueden abrir una Cuenta Digital WaoBiz? ",
    content: (
      <p>
        <strong>
          Como empresa estadounidense, net 24|7 y sus filiales deben cumplir con
          las leyes y regulaciones federales. Por ejemplo, las empresas que
          ofrecen servicios o productos ilegales no pueden abrir una cuenta de
          WaoBiz Digital. Además, ciertos negocios de alto riesgo también pueden
          estar prohibidos, como los de las siguientes industrias:
        </strong>
        <br></br>
        <br></br>
        Prostitución, pornografía, venta o distribución de contenido para
        adultos, incluidos, entre otros, servicios de matrimonio o citas en
        línea, servicios y bienes pornográficos, actividades relacionadas con el
        entretenimiento para adultos o servicios de acompañantes, partidos
        políticos, casinos y salas de juego, excepto con licencia, EE. UU.
        instituciones que no aceptan efectivo; juegos de azar o competencias con
        dinero en efectivo, servicios de crédito, hipotecas, reducción de
        deudas, ejecuciones hipotecarias, servicios de protección de deudas,
        servicios de reducción de tasas de interés de tarjetas de crédito,
        prestamistas de día de pago, cualquier esquema Ponzi o venta piramidal,
        telemercadeo saliente, productos para blanquear los dientes, pastillas
        para adelgazar y productos que ofrecen propiedades saludables que no han
        sido aprobadas o verificadas por el organismo regulador local y/o
        nacional aplicable, servicios que ofrecen subsidios, incentivos,
        recompensas monetarias del gobierno, venta de cigarrillos y licores sin
        una licencia emitida por las autoridades lugares que corresponden,
        servicios relacionados con esoterismo y horóscopos, servicios de subasta
        por Internet, tiempos compartidos, cambio/garantía de cheques y
        establecimientos de casas de cambio, venta de drogas controladas sin la
        debida licencia, cualquier producto o servicio que infrinja o facilite
        directamente la infracción de marcas registradas, licencia de patentes,
        derechos de autor, secretos comerciales de derechos de propiedad o
        privacidad de productos/servicios d, venta o reventa de un servicio sin
        beneficio adicional para el comprador; sitios que, a nuestro exclusivo
        criterio, determinemos que son injustos, engañosos o depredadores para
        los consumidores, venta de "vistas", "me gusta", comentarios, seguidores
        y otras formas de actividad en las redes sociales, productos o servicios
        que transmiten o venden películas, Programas de televisión, videos
        musicales u otro contenido multimedia sin derechos de propiedad.
        Actividades de carácter delictivo, especialmente aquellas presuntamente
        vinculadas al narcotráfico, terrorismo, trata de personas o crimen
        organizado, o la creación, facilitación, venta o distribución de
        cualquier material que promueva la violencia o el odio.
        <br></br>
        <br></br>
        En general, net 24|7 prohíbe cualquier tipo de transacción que viole las
        leyes locales, nacionales o internacionales, y que pueda dañar su
        reputación; por ello, todo usuario que realice transacciones de estas
        características no será admitido o será expulsado.
      </p>
    ),
  },

  {
    title:
      "¿Cuánto tiempo puede tardar la aprobación de una solicitud de cuenta WaoBiz? ",
    content: (
      <p>
        Hemos logrado que el proceso de abrir una cuenta sea lo más fácil
        posible. Sin embargo, todavía tenemos que cumplir con todos los
        controles reglamentarios y de procedimiento requeridos. Si envía toda la
        información solicitada y cumple con los requisitos, su solicitud será
        aprobada en cuestión de horas.
        <br></br>
        <br></br>
        Si requerimos más información de respaldo, aceptaremos temporalmente su
        solicitud. Luego, lo contactaremos dentro de las 48 horas (días hábiles)
        para explicarle qué información adicional se necesita. Mientras más
        rápido responda, con más prontitud podremos darle una respuesta.
        <br></br>
        <br></br>
        Si no hay forma de abrirle una cuenta, rechazaremos su solicitud. La
        razón más común de esto es una combinación de factores de riesgo, como
        el tipo de negocio, el país de residencia, o la imposibilidad de
        atenderlo con nuestras licencias.
      </p>
    ),
  },
  {
    title: "¿Cómo se administra una cuenta digital WaoBiz? ",
    content: (
      <p>
        La mayoría de las empresas en el mundo ya han adoptado la banca digital
        de alguna manera. Seguramente su empresa ya tiene acceso a cuentas
        bancarias regulares a través de los sitios web del banco.
        <br></br>
        <br></br>
        <strong>WaoOnline</strong> es nuestro sitio de administración en tiempo
        real, que le permite a su empresa administrar todos los aspectos de la
        banca digital y el procesamiento de pagos de su empresa de manera
        unificada. Representa una evolución del modelo bancario tradicional,
        convirtiéndose en una solución eficaz para ampliar los beneficios de la
        tecnología y la transformación digital.
        <br></br>
        <br></br>
        Después de completar el proceso de <b>onboarding</b>, obtendrá las
        credenciales para iniciar sesión en <strong> WaoOnline</strong> y
        comenzar a disfrutar de nuestra experiencia financiera digital.
      </p>
    ),
  },
  {
    title: "¿Cómo deposito dinero en mi cuenta digital WaoBiz? ",
    content: (
      <p>
        Al igual que con cualquier cuenta bancaria, la forma más sencilla de
        agregar fondos a su cuenta de Wao es a través de una transacción
        bancaria.
        <br></br>
        <br></br>
        Puede hacerlo a través de ACH (bancos de EE. UU.), Wire (cualquier banco
        en el mundo) o incluso a través de Zelle®. Por supuesto, cualquier otro
        usuario de Wao también puede enviarte fondos en segundos.
        <br></br>
        <br></br>
        Como usuario de <b>WaoBiz</b>, su empresa también tiene acceso a todas
        las capacidades de procesamiento de pagos que podemos ofrecer.
        <br></br>
        <br></br>
        También podrá realizar depósitos en efectivo en su cuenta de Wao en
        establecimientos comerciales participantes de nuestra red de socios.
        <br></br>
        <br></br>
        <p style={{ fontStyle: "italic" }} className="note">
          <span className="red">*</span>
          Ciertas condiciones aplican. No todas las funcione están disponibles
          en todos los países.
        </p>
      </p>
    ),
  },
  {
    title:
      "¿Cuáles son las funcionalidades de pago que WaoBiz me permite usar? ",
    content: (
      <p>
        WaoBiz proporciona todas las herramientas que necesita para aceptar
        pagos en línea de clientes de todo el mundo.
        <br></br>
        <br></br>
        <ul>
          <li>
            <strong>API:</strong> Integre fácilmente su proceso de pago con nuestra potente
            tecnología API. Elija la integración adecuada para usted; WaoBiz
            ofrece opciones de integración flexibles sin comprometer la
            experiencia de pago.
          </li>
          <li>
          <strong>Payments button:</strong> Es una interfaz de pago en la nube, con la que
            puede recibir pagos en línea en cualquier sitio web o aplicación
            móvil y ofrecer a sus clientes las ventajas del comercio electrónico
            instantáneo.
          </li>
          <li>
          <strong>Virtual Terminal:</strong> Puede convertir su computadora en una terminal de
            procesamiento de tarjetas de crédito. Nuestra solución en WaoOnline
            le permite recibir pagos con solo unos pocos clics.
          </li>
          <li>
          <strong>Electronic Invoincing:</strong> Ahorre tiempo y sus clientes le paguen más
            rápido. Crea y envía una factura en segundos, sin necesidad de
            escribir una línea de código.
          </li>
          <li>
          <strong>Capture devices: </strong> Amplía las capacidades de Wao con dispositivos que
            ayuden a tu empresa a capturar datos de tus clientes de forma más
            rápida y segura.
          </li>
        </ul>
     
        <p style={{ fontStyle: "italic" }} className="note">
          <span className="red">*</span>
          Ciertas condiciones aplican. No todas las funcione están disponibles
          en todos los países.
        </p>
      </p>
    ),
  },
  {
    title:
      "¿Cuál es el proceso para integrar las funciones de pago de WaoBiz en mi empresa? ",
    content: (
      <p>
        Comenzar es fácil. Después de abrir su cuenta digital de{" "}
        <strong>WaoBiz</strong>, lo ayudaremos a elegir la integración adecuada
        para su negocio; ofrecemos:
        <br></br>
        <br></br>
        <ol>
          <li>
            Kickoff Discovery para entender las características de su negocio
          </li>
          <li>Acceso a documentación e instrucciones detalladas</li>
          <li>SandBox de pruebas seguras</li>
          <li>Equipo de soporte disponible durante su desarrollo</li>
          <li>Carta de certificación una vez realizadas todas las pruebas</li>
          <li>Soporte extendido en el lanzamiento de producción.</li>
        </ol>
      </p>
    ),
  },
  {
    title: "¿Qué funcionalidades proporciona la integración de WaoAPI?",
    content: (
      <p>
        <ol>
          <li>
            Integración para aceptar pagos con tarjetas de crédito y débito en
            línea.
          </li>
          <li>
            Componentes de seguridad para transacciones con tarjetas de crédito
            y débito.
          </li>
          <li>Fáciles procesos de anulación de transacciones procesadas.</li>
          <li>Opciones de integración para usar o confirmar pagos Zelle®.</li>
          <li>Capacidades de implementación de WaoLink.</li>
          <li>Reportes personalizados.</li>
          <li>Datos de webhook personalizados.</li>
          <li>Tarifa e impuestos de servicio personalizado.</li>
          <li>Integraciones especiales (personalizables para cada empresa).</li>
        </ol>
      </p>
    ),
  },
];
const DataWaoCard = [
  {

    title: "¿Qué le permite WaoCard?",
    content: (
      <p>
        <b> WaoCard</b> le permite utilizar el dinero que tiene disponible en la Cuenta Wao tanto en comercios como a través de pagos en línea. No puede gastar más dinero que la cantidad que se muestra en el saldo de su cuenta.
      </p>
    ),
  },
  {
    title: "¿Qué tipo de tarjetas emiten?",
    content: (
      <p>
        <b>WaoCard</b> es una tarjeta de interfaz dual con la última tecnología
        en capacidades de pago. También tiene la opción de solicitar tarjetas
        virtuales.
      </p>
    ),
  },
  {
    title: "¿Qué es una tarjeta de interfaz dual?",
    content: (
      <p>
        Es una tarjeta que puedes utilizar de dos formas distintas a la hora de
        pagar en comercios. Primero, paga usando la interfaz sin contacto donde
        ves este símbolo
        <img src={contacless} alt="symbol" /> Luego, simplemente toque la
        tarjeta en el punto de venta. O, si el símbolo
        <img src={contacless} alt="symbol" /> no está presente, utilice la
        interfaz de contacto (chip) para insertar la tarjeta en el punto de
        venta.
      </p>
    ),
  },
  {
    title: "¿Cómo activo mi WaoCard?",
    content: (
      <p>
        Tan pronto como reciba la tarjeta, siga las instrucciones en{" "}
        <b className="blue">WaoApp </b> o <b className="blue">WaoOnline</b>, en
        la opción "Tarjetas" ubicada en el menú principal.
      </p>
    ),
  },
  {
    title: "¿Cuál es la diferencia entre congelar y bloquear mi WaoCard?",
    content: (
      <p>
        Utilice la opción de congelar si desea desactivar su tarjeta
        temporalmente, esto sirve para agregar una capa de seguridad adicional
        sobre su tarjeta. Puedes descongelar su tarjeta en cualquier momento en
        tu <b className="blue">WaoApp </b> o <b className="blue">WaoOnline</b>.
        El Bloqueo debe ser usado en caso de pérdida, robo o si detectas una
        transacción fraudulenta. Si bloquea su tarjeta, se emitirá una nueva
        tarjeta y se enviará a su dirección preferida.
      </p>
    ),
  },
  {
    title: "¿Cómo configuro un PIN para mi nueva WaoCard?",
    content: (
      <p>
        Después de activar su tarjeta, proceda a establecer un PIN para
        transacciones en cajeros automáticos siguiendo las instrucciones en
        <b className="blue"> WaoApp </b> o <b className="blue">WaoOnline</b>.
      </p>
    ),
  },

  {
    title: "¿Puedes usar tu WaoCard en otros países?",
    content: (
      <p>
        Sí, su tarjeta puede ser usada en cualquier país o sitio donde Mastercard sea aceptada. Solo tenga en cuenta que en algunos casos pueden aplicar tarifas de uso internacional.
      </p>
    ),
  },
  {
    title:
      "¿Qué hago si no reconozco una transacción en mi cuenta Wao Digital?",
    content: (
      <p>
        Inmediatamente congele su tarjeta y cree un ticket de soporte en la
        opción "Soporte" en el menú principal de <b className="blue">WaoApp </b>
        o <b className="blue">WaoOnline</b>. Si está seguro de que la
        transacción que no reconoce es fraudulenta, bloquee su tarjeta
        inmediatamente.
      </p>
    ),
  },
  {
    title: "¿Cuáles son mis límites de gasto con la WaoCard?",
    content: (
      <p>
        Podrá gastar hasta USD 1.000 por día y hasta USD 25.000 por mes. En caso
        de que necesite aumentar sus límites, contáctenos en
        <a className="blue" href="mailto:support@wao.net"> support@wao.net </a>.
      </p>
    ),
  },
];

const DataWaoOneSecurity = [
  {
    title:
      "¿Cómo puedo ingresar a mi cuenta Wao si perdiera mi dispositivo móvil?",
    content: (
      <p>
        Perder tu teléfono móvil siempre es un inconveniente y una situación
        estresante. Sin embargo, tiene una forma alternativa de acceder a su
        cuenta de Wao en línea. Vaya al sitio web de su Wao (
        <b className="blue">www.wao.net</b>). siempre que desee acceder a su
        cuenta, todo lo que tiene que hacer es abrir su navegador web y visitar
        el sitio web de Wao. Después de que se cargue la página, busque la
        pestaña o el botón que dice "<b className="blue">Iniciar sesión</b>”.
        Use la misma credencial utilizada en su dispositivo móvil para iniciar
        sesión.
      </p>
    ),
  },
  {
    title: "¿Cómo puedo desbloquear mi cuenta?",
    content: (
      <p>
        Si su cuenta se bloquea después de ingresar la contraseña incorrecta o
        responder preguntas de seguridad incorrectamente, siga estos sencillos
        pasos:
        <br />
        <br />
        <ul>
          <li>
            Visite <b className="blue"> www.wao.net </b>y seleccione Iniciar
            sesión en la esquina superior derecha.
          </li>
          <li>Ingrese su ID personal y toque Iniciar sesión </li>
          <li>Haga clic en Olvidé mi contraseña. </li>
          <li>
            Siga los pasos en pantalla para completar su restablecimiento e
            iniciar sesión.
          </li>
        </ul>
        Si ha intentado iniciar sesión y aún está bloqueado, comuníquese con
        nosotros al
        <a href="tel:+18033531153" className="blue">
          {" "}
          +1 (803) 353-1153
        </a>
      </p>
    ),
  },
  {
    title: "¿Cómo puedo cancelar mi cuenta?",
    content: (
      <p>
        Si desea cancelar su cuenta de Wao, contáctenos por correo electrónico a{" "}
        <a href="mailto:support@wao.net" className="blue">
          support@wao.net
        </a>
      </p>
    ),
  },
  {
    title: "¿Cuál es su política de privacidad?",
    content: (
      <p>
        Puede ver nuestra política de privacidad detallada en la URL{" "}
        <a href="/privacy" target="blank">
          privacy policy
        </a>
      </p>
    ),
  },
  {
    title: "¿Cómo identifico y evito posibles estafas? ",
    content: (
      <p>
        <b>Know the red flags.</b> Los tipos más comunes de estafas se dirigirán
        a usted a través de correos electrónicos falsos, mensajes de texto,
        mensajes de voz, llamadas, cartas o incluso alguien que se presenta
        inesperadamente en la puerta de su casa. Los estafadores usan diferentes
        tácticas para hacer que las víctimas caigan en sus esquemas. En algunos
        casos, pueden ser amigables, comprensivos y parecer dispuestos a ayudar.
        En otros, usan tácticas de miedo para persuadir a la víctima.
        <br />
        <br />
        Conozca los mejores métodos para evitar ser estafado:
        <br />
        <br />
        <ul>
          <li>
            <strong>No responda:</strong> Si no está 100 % seguro del origen de
            la llamada, el correo electrónico o el mensaje de texto, cuelgue el
            teléfono, no haga clic en el enlace del correo electrónico y no
            responda al mensaje de texto.
          </li>
          <br />
          <li>
            <strong>
              No confíes en el identificador de llamadas, ni respondas llamadas
              telefónicas de números desconocidos: </strong>
              Si reconoces el identificador de llamadas, pero la llamada parece
            sospechosa, cuelga el teléfono. Los números de teléfono se pueden
            falsificar fácilmente para que parezcan de una persona que llama
            legítimamente.
          </li>
          <br />
          <li>
            <strong>No entregues tu Información: </strong>
            Nunca proporcione ninguna información de identificación personal a
            menos que esté absolutamente seguro de que la persona y el motivo
            son legítimos. Wao nunca le pedirá que nos envíe
            información personal, como un número de cuenta, número de seguro
            social o identificación fiscal por mensaje de texto, correo
            electrónico o en línea
          </li>
          <br />
          <li>
            <strong>Investigue y valide:</strong> Si la persona u organización
            parece sospechosa, asegúrese de que la solicitud realizada sea
            legítima llamando a la organización a través de un número oficial de
            su sitio web o consultando con un familiar o amigo de confianza.
          </li>
        </ul>

        Si cree que puede haber sido víctima de una estafa, contáctenos de
        inmediato al
        <a href="tel:+18033531153" className="blue">
          {" "}
          +1 (803) 353-1153
        </a>
      </p>
    ),
  },
  {
    title: "¿Qué es phishing?",
    content: (
      <p>
        El phishing es el intento fraudulento de obtener información
        confidencial, como nombres de usuario, contraseñas y detalles de
        cuentas, generalmente a través de un correo electrónico, un mensaje de
        texto o incluso una llamada telefónica. En estos mensajes pueden hacerse
        pasar por una empresa, una organización benéfica o una agencia
        gubernamental y, a menudo, constituyen una solicitud urgente para
        convencerlo de que inicie sesión en un sitio falso, abra un archivo
        adjunto de correo electrónico que contenga malware o responda con
        información personal o de cuenta. La información que proporcione puede
        usarse para cometer robo de identidad o acceder a su cuenta para robar
        dinero.
      </p>
    ),
  },
  {
    title:
      "¿Qué debo hacer si recibo un correo electrónico, un mensaje de texto SMS o una llamada telefónica sospechosos con respecto a mi cuenta de Wao?",
    content: (
      <p>
        Si recibe un correo electrónico o mensaje de texto sospechoso, no
        responda, haga clic en ningún enlace ni abra archivos adjuntos. No
        inicie sesión en su cuenta desde un enlace en un mensaje sospechoso.
        Para iniciar sesión, use WaoApp o escriba{" "}
        <b className="blue"> www.wao.net </b> en su navegador.
        <br />
        <br />
        Si hizo clic en un enlace, abrió un archivo adjunto o proporcionó
        información personal o de la cuenta, llámenos de inmediato al
        <a href="tel:+18033531153" className="blue">
          +1 (803) 353-1153
        </a>
        <br />
        <br />
        Si no respondió, reenvíe el correo electrónico o mensaje de texto
        sospechoso a{" "}
        <a href="mailto:support@wao.net" className="blue">
          support@wao.net
        </a>{" "}
        y después elimínelo. Recibirá una respuesta automática. Revisaremos su
        mensaje de inmediato y tomaremos las medidas necesarias.
      </p>
    ),
  },
  {
    title: "¿Qué debo hacer si creo que mi identidad ha sido comprometida? ",
    content: (
      <p>
        Para minimizar el daño, aquí hay 10 pasos que debe seguir una vez que se
        dé cuenta de que puede ser víctima de un robo de identidad.
        <br />
        <br />
        <ol>
          <li>
            Presente un reclamo con su seguro de robo de identidad, si
            corresponde.
          </li>
          <li>
            Notifique a las empresas que sus datos de identidad fueron robados.
          </li>
          <li>
            Reporte el Robo de Identidad. Informe el robo de identidad (ID) a la
            Federal Trade Commission (FTC) en línea en IdentityTheft.gov o por
            teléfono al{" "}
            <a href="tel:+18774384338" className="blue">
              +1-877-438-4338
            </a>
            . La FTC recopilará los detalles de su situación.
          </li>
          <li>Póngase en contacto con su departamento de policía local.</li>
          <li>
            Coloque una alerta de fraude en las empresas de reporte de crédito.
          </li>
          <li> Congele su crédito.</li>
          <li> Regístrese en un servicio de monitoreo de crédito.</li>
          <li> Refuerce la seguridad en sus cuentas.</li>
          <li>
            Revise sus informes de crédito en busca de cuentas que no reconoce.
          </li>
          <li>
            Revise la tarjeta de crédito y los extractos bancarios en busca de
            cargos no autorizados.
          </li>
        </ol>
      </p>
    ),
  },
];

const ES_FAQS = {
  DataWao,
  DataWaoOne,
  DataWaoBiz,
  DataWaoCard,
  DataWaoOneSecurity,
};
export default ES_FAQS;
