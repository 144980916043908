import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    country_id: "",
    ip:"",
    country_name:""
};

export const CountryID = createSlice({
  name: "countryid",
  initialState,
  reducers: {
    setCountryID: (state, action) => {

      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

// Metodo global para actualizar el state
export const {setCountryID } = CountryID.actions;

export default CountryID.reducer;