import React from "react";
import AnimatedCursor from "react-animated-cursor";
import "./Business.scss";
import Img1 from "../../assets/images/business/Wao-Empresas1.webp";

import Img3 from "../../assets/images/business/seccion3Empresas.webp";
import Img4 from "../../assets/images/business/seccion4Empresas.webp";
import Img5 from "../../assets/images/business/seccion5Empresas.webp";
import Img6 from "../../assets/images/business/seccion6Empresas.webp";
import check from "../../assets/icons/business/fact_check.png";
import CheckIcon from "../../assets/icons/checkIcon";

import waoLogoApp from "../../assets/images/waoLogoOnline.svg";
import waoLogoCard from "../../assets/images/waoLogoCard.svg";
import waoLogoLink from "../../assets/images/waoLogoLink.svg";
import been from "../../assets/icons/business/beenhere.png";

import stars from "../../assets/icons/business/stars.png";
import { LanguageContext } from "Context";

import { ButtonBubble } from "../../components";
import { FooterTemplate } from "../../Containers";
import { Link, useHistory } from "react-router-dom";

import Carousel from "react-elastic-carousel";
import EnLang from "./lang/En";
import EsLang from "./lang/Es";
import Whatsapp from "components/whatsapp/whatsapp";
import CookieConsent from "react-cookie-consent";

const ResponseBusiness = () => {
  let history = useHistory();

  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});

  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  window.scrollTo(0,0);
  return (
    <div className="container-responsive">
      <div className="section-mobile">
        <div className="section-image">
          <img src={Img1} alt="ImagePicture-section" />
        </div>
        <div className="default-title ">{langChoosed.section1?.text8}</div>

        <div className="default-subTitle ">{langChoosed.section1?.text9}</div>
        <div className="default-list ">
          <div className="item">
            <div className="image">
              <CheckIcon />
            </div>
            <div className="text">{langChoosed.section1?.text10}</div>
          </div>
          <div className="item">
            <div className="image">
              <CheckIcon />
            </div>
            <div className="text">{langChoosed.section1?.text11}</div>
          </div>
          <div className="item">
            <div className="image">
              <CheckIcon />
            </div>
            <div className="text">{langChoosed.section1?.text12}</div>
          </div>
        </div>

        <ButtonBubble
          className="small"
          // onClick={() => handleRedirect2("/register-business")}
        >
          <a className="text-business" href="/register-business">
            {langChoosed.section1?.text7}
          </a>
        </ButtonBubble>
      </div>
      <div className="carousel-wrapper">
        <Carousel>
          <div className="cuadro-uno">
            <div className="title-icon">
              <div className="number">
                <p className="number-text">{langChoosed.section2?.text12}</p>
              </div>
              <div className="icon">
                <img src={check} alt="" />
              </div>
            </div>
            <div className="text">
              <div className="subtitle">
                <p>{langChoosed.section2?.text6}</p>
              </div>
              <div className="text-one">
                <p>{langChoosed.section2?.text9}</p>
              </div>
            </div>
          </div>
          <div className="cuadro-uno">
            <div className="title-icon">
              <div className="number">
                <p className="number-text">{langChoosed.section2?.text13}</p>
              </div>
              <div className="icon">
                <img src={been} alt="" />
              </div>
            </div>
            <div className="text">
              <div className="subtitle">
                <p>{langChoosed.section2?.text7}</p>
              </div>
              <div className="text-one">
                <p>{langChoosed.section2?.text10}</p>
              </div>
            </div>
          </div>
          <div className="cuadro-uno">
            {" "}
            <div className="title-icon">
              <div className="number">
                <p className="number-text">{langChoosed.section2?.text14}</p>
              </div>
              <div className="icon">
                <img src={stars} alt="" />
              </div>
            </div>
            <div className="text">
              <div className="subtitle">
                <p>{langChoosed.section2?.text8}</p>
              </div>
              <div className="text-one">
                <p>{langChoosed.section2?.text11}</p>
              </div>
            </div>
          </div>
        </Carousel>
        <div className="button-movil">
          {" "}
          <ButtonBubble
            className={" small"}
            // onClick={() => handleRedirectWithModal("/register-empresa")}
          >
            <a className="text-business" href="/register-business">
              {langChoosed.section1?.text7}
            </a>
          </ButtonBubble>
        </div>
      </div>
      <div className="section-mobile">
        <div className="section-image">
          <img src={Img3} alt="ImagePicture-section" />
        </div>
        <div className="title-logo">
          <img src={waoLogoApp} alt="waoLogoApp" />
        </div>
        {/*  <RenderText Texts={langChoosed.section3} /> */}
        <div className="default-paragraph ">{langChoosed.section3?.text1}</div>
        <div className="default-paragraph ">{langChoosed.section3?.text2}</div>
        <div className="default-span">{langChoosed.section3?.text3}</div>
      </div>
      <div className="section-mobile">
        <div className="section-image">
          <img src={Img4} alt="ImagePicture-section" />
        </div>
        <div className="title-logo">
          <img src={waoLogoCard} alt="waoLogoCard" />
        </div>
        {/* <RenderText Texts={langChoosed.section4} /> */}
        <div className="default-paragraph ">{langChoosed.section4?.text1}</div>
        <div className="default-paragraph ">{langChoosed.section4?.text2}</div>
        <div className="default-paragraph ">{langChoosed.section4?.text3}</div>
        <div className="default-span ">{langChoosed.section4?.text4}</div>
      </div>
      <div className="section-mobile">
        <div className="section-image">
          <img src={Img5} alt="ImagePicture-section" />
        </div>
        <div className="title-logo">
          <img src={waoLogoLink} alt="waoLogoLink" />
        </div>
        {/* <RenderText Texts={langChoosed.section5} /> */}
        <div className="default-paragraph ">{langChoosed.section5?.text1}</div>
        <div className="default-paragraph ">{langChoosed.section5?.text2}</div>
        <div className="default-paragraph ">{langChoosed.section5?.text3}</div>
        <div className="default-span ">{langChoosed.section5?.text4}</div>
      </div>
      <div className="section-mobile">
        <div className="section-image" style={{ marginBottom: "35px" }}>
          <img src={Img6} alt="ImagePicture-section" />
        </div>
        <div className="default-title">
          {langChoosed.section6?.title1}
          <span> {langChoosed.section6?.title2}</span>
        </div>

        <div className="default-paragraph">{langChoosed.section6?.text1}</div>
        <div className="default-paragraph">{langChoosed.section6?.text2}</div>
      </div>

      <FooterTemplate className="business fooTemp__container">
        <div className="foo__content">
          <div className="title-register">
            <div className="free">
              <span>{langChoosed.section8?.text2}</span>
            </div>
            <div className="register">
              <span>{langChoosed.section8?.text1}</span>
            </div>
          </div>
          <div className="parrafo-register">
            <div className="text-register">
              {langChoosed.section8?.text3 + langChoosed.section8?.text4}
            </div>
          </div>
          <div className="button-register">
            <ButtonBubble
              className={"light-business small"}
              onClick={() => history.push("/register-business")}
            >
              <a className="text-register" href="/register-business">
                {langChoosed.section8?.text5}
              </a>
            </ButtonBubble>
          </div>
        </div>
        {/* <div className={"images"}>
          <img src={Imagen7} alt="home" className={"image-home"} />
        </div> */}
      </FooterTemplate>
      <Whatsapp />
      <AnimatedCursor
        innerSize={16}
        outerSize={12}
        color="255, 198, 41"
        outerAlpha={0.5}
        innerScale={0.9}
        outerScale={5}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
      <CookieConsent
        buttonText="Accept "
        style={{ background: "#204694" }}
        buttonStyle={{ fontSize: "16px", color: "#121a2c" }}
      >
        This website uses cookies to enhance the user experience.
        <Link onClick={(event) => (window.location.href = "/cookie")}>
          Privacy Cookie
        </Link>
      </CookieConsent>
    </div>
  );
};

export default ResponseBusiness;
