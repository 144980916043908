import React, { useState } from "react";
import "../WhoV3.scss";
import "./Collapse.scss";
import Iconplus from "../../../assets/icons/iconplus.svg";
import Iconminus from "../../../assets/icons/iconminus.svg";

const Collapse = ({ title, collapsed, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <div className="accordion-terms">
      <header className="header" onClick={() => setIsCollapsed(!isCollapsed)}>
        <p className={`title ${isCollapsed ? "" : "active"}`} >{title}</p>
        {isCollapsed ? (
          <img src={Iconplus} alt="" onClick={() => setIsCollapsed(!isCollapsed)} />
        ) : (
          <img src={Iconminus} alt="" onClick={() => setIsCollapsed(!isCollapsed)}/>
        )}
        
      </header>
      <div
        className={`collapse__content notification ${
          isCollapsed ? "collapsed" : "expanded"
        }`}
        aria-expanded={isCollapsed}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
