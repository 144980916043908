import React from 'react'
import SurveyO from '../Organismos/surveyO'

const SurveyP = () => {
  return (
    <div>
               <SurveyO/>
    </div>
  )
}

export default SurveyP