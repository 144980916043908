import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homev3 from "pages/homev3/homev3";
import Who from "pages/who/WhoV3";
import Faq from "pages/Faq/Faq";
import { NavBar } from "../Containers";
import {
  ActiveItemProvider,
  LanguageProvider,
  UnavailableModalProvider,
} from "Context";
import "./route.scss";
import Business from "pages/Business/Business";
import People from "pages/People/People";
import Cookie from "pages/Cookies/Cookie";
import License from "pages/Legal/Licences";
import Terms from "pages/Legal/Terms";
import Disclaimer from "pages/Legal/Disclaimer";
import Privacy from "pages/Legal/Privacy";
import Survey from "pages/Survey/survey";
import RegisterForm from "pages/RegisterForm/RegisterForm";

import Loading from "pages/Loading/Loading";

const Routes = () => (
  <>
    <UnavailableModalProvider>
      <ActiveItemProvider>
        <LanguageProvider>
          <Router>
            <NavBar />
            <Switch>
              <Route path="/" exact>
                <div className="wrapper-app">
                  <Homev3 />
                </div>
              </Route>

              <Route path="/About" exact>
                <div className="wrapper-app">
                  <Who />
                </div>
              </Route>

              <Route path="/Cookie" exact>
                <div className="wrapper-app">
                  <Cookie />
                </div>
              </Route>

              <Route path="/people" exact>
                <div className="wrapper-app">
                  <People />
                </div>
                {/* <Footer /> */}
              </Route>

              <Route path="/business" exact>
                <div className="wrapper-app">
                  <Business />
                </div>
              </Route>
              <Route path="/faq" exact>
                <div className="wrapper-app">
                  <Faq />
                </div>
              </Route>

    
              <Route path="/license" exact>
                <License />
              </Route>
              <Route path="/terms" exact>
                <Terms />
              </Route>
              <Route path="/disclaimer" exact>
                <Disclaimer />
              </Route>
              <Route path="/privacy" exact>
                <Privacy />
              </Route>
           
              <Route path="/register-person" component={RegisterForm} />
              <Route path="/encuesta" component={Survey} />
              <Route path="/register-business" component={Loading} />
              <Route path="/images-test" exact>
                <div className="wrapper-app">
                  <imageTest />
                </div>
              </Route>
            </Switch>
          </Router>
        </LanguageProvider>
      </ActiveItemProvider>
    </UnavailableModalProvider>
  </>
);

export default Routes;
