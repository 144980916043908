import React from "react";

export const UnavailableModalContext = React.createContext();

export const UnavailableModalProvider = (props) => {
  const [uModalVisibility, setUModalVisibility] = React.useState(false);

  return (
    <UnavailableModalContext.Provider value={[uModalVisibility, setUModalVisibility]}>
      {props.children}
    </UnavailableModalContext.Provider>
  );
};
