import React from "react";
import "./table.scss";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { LanguageContext } from "Context";
import EnLang from "../../Lang/En";
import EsLang from "../../Lang/Es";
const TableFour = () => {
  const [lang] = React.useContext(LanguageContext);
  const [langChoosed, setLangChoosed] = React.useState({});
  React.useEffect(() => {
    if (lang === "En") {
      setLangChoosed(EnLang);
    } else {
      setLangChoosed(EsLang);
    }
  }, [lang]);
  return (
    <div className="table-global">
        <div className="title-global">{langChoosed?.head?.head0}</div>
      <div className="div-head">
        <div className="div-head-one">{langChoosed?.head?.head1}</div>
        <div> {langChoosed?.head?.head2}</div>
      </div>
      <Table className="table-one-wrapper">
        <TableHead>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody className="table-body">
          <TableRow className="row-gray">
            <TableCell className="title">{langChoosed?.table?.text1}</TableCell>
            <TableCell className="text">{langChoosed?.table?.text4}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="title">{langChoosed?.table?.text2}</TableCell>
            <TableCell className="text">
              {langChoosed?.table?.text5}
              <ul>
                <li>{langChoosed?.table?.text6} </li>
              </ul>
              <ul>
                <li>{langChoosed?.table?.text7} </li>
              </ul>
              {langChoosed?.table?.text8}
              {langChoosed?.table?.text9}
            </TableCell>
          </TableRow>
          <TableRow className="row-gray">
            <TableCell className="title">{langChoosed?.table?.text3}</TableCell>
            <TableCell className="text">
              {langChoosed?.table?.text10}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default TableFour;
