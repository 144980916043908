import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import Routes from "routes/routes";

import "theme/reset.scss";
import "theme/global.scss";
import Meta from "components/Metatags/meta";
import { HelmetProvider } from "react-helmet-async";

// import 'theme/global.scss';
function FacebookPixel() {
  React.useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("511382687716573");
        ReactPixel.pageView();

        Routes.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  });

  return null;
}

const helmetContext = {};
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <Meta />
      <Routes />
      <FacebookPixel />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
